/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromGameRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Platform?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Standalone?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    DeviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameRequest
     */
    Locale?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromGameRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromGameRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Token" in value;
    isInstance = isInstance && "Game" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    return FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'Game': json['Game'],
        'Platform': !exists(json, 'Platform') ? undefined : json['Platform'],
        'Standalone': !exists(json, 'Standalone') ? undefined : json['Standalone'],
        'DeviceId': !exists(json, 'DeviceId') ? undefined : json['DeviceId'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromGameRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromGameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Token': value.Token,
        'Game': value.Game,
        'Platform': value.Platform,
        'Standalone': value.Standalone,
        'DeviceId': value.DeviceId,
        'Locale': value.Locale,
    };
}

