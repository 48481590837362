/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform } from './FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform';
import {
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSON,
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSONTyped,
    FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformToJSON,
} from './FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    CreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    UpdatedAt: Date;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    Platform: FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    BuildNumber: number;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    BuildVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    BuildSemanticVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease
     */
    CommitHash?: string | null;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "CreatedAt" in value;
    isInstance = isInstance && "UpdatedAt" in value;
    isInstance = isInstance && "Platform" in value;
    isInstance = isInstance && "BuildNumber" in value;

    return isInstance;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CreatedAt': (new Date(json['createdAt'])),
        'UpdatedAt': (new Date(json['updatedAt'])),
        'Platform': FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSON(json['platform']),
        'BuildNumber': json['buildNumber'],
        'BuildVersion': !exists(json, 'buildVersion') ? undefined : json['buildVersion'],
        'BuildSemanticVersion': !exists(json, 'buildSemanticVersion') ? undefined : json['buildSemanticVersion'],
        'CommitHash': !exists(json, 'commitHash') ? undefined : json['commitHash'],
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.CreatedAt.toISOString()),
        'updatedAt': (value.UpdatedAt.toISOString()),
        'platform': FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformToJSON(value.Platform),
        'buildNumber': value.BuildNumber,
        'buildVersion': value.BuildVersion,
        'buildSemanticVersion': value.BuildSemanticVersion,
        'commitHash': value.CommitHash,
    };
}

