/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo,
    instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoFromJSONTyped,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoToJSON,
} from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo';

/**
 * @type FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo
 * 
 * @export
 */
export type FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo = FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo;

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo {
    return FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoToJSON(value?: FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo(value)) {
        return FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfoToJSON(value as FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultCardInfo);
    }

    return {};
}

