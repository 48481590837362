/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions;

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuthToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsUserAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions);
    }

    return {};
}

