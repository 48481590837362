/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions;

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoYandexGamesLoginOptions);
    }

    return {};
}

