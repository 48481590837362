/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings,
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettingsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherBugReportSettings);
    }

    return {};
}

