import React from "react";

export const MenuBurger = React.forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
	function MenuBurger(props, ref) {
		return (
			<svg ref={ref} fill="currentColor" {...props}>
				<use xlinkHref="#icon-menu_burger"></use>
			</svg>
		);
	}
);
