/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PayWithRecurrentResponse
 */
export interface FxIdWebFeaturesStoreBank131PayWithRecurrentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreBank131PayWithRecurrentResponse
     */
    Success: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PayWithRecurrentResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PayWithRecurrentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Success" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreBank131PayWithRecurrentResponseFromJSON(json: any): FxIdWebFeaturesStoreBank131PayWithRecurrentResponse {
    return FxIdWebFeaturesStoreBank131PayWithRecurrentResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PayWithRecurrentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PayWithRecurrentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Success': json['Success'],
    };
}

export function FxIdWebFeaturesStoreBank131PayWithRecurrentResponseToJSON(value?: FxIdWebFeaturesStoreBank131PayWithRecurrentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Success': value.Success,
    };
}

