/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreYandexGamesPurchaseRequest
 */
export interface FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreYandexGamesPurchaseRequest
     */
    YandexGetPurchasesRequest: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreYandexGamesPurchaseRequest
     */
    YandexSignedData: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreYandexGamesPurchaseRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreYandexGamesPurchaseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "YandexGetPurchasesRequest" in value;
    isInstance = isInstance && "YandexSignedData" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSON(json: any): FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    return FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreYandexGamesPurchaseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'YandexGetPurchasesRequest': json['YandexGetPurchasesRequest'],
        'YandexSignedData': json['YandexSignedData'],
    };
}

export function FxIdWebFeaturesStoreYandexGamesPurchaseRequestToJSON(value?: FxIdWebFeaturesStoreYandexGamesPurchaseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'YandexGetPurchasesRequest': value.YandexGetPurchasesRequest,
        'YandexSignedData': value.YandexSignedData,
    };
}

