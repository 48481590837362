/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions;

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontaktePlayLoginOptions);
    }

    return {};
}

