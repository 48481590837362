/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesInfoNewsNews } from './FxIdWebFeaturesInfoNewsNews';
import {
    FxIdWebFeaturesInfoNewsNewsFromJSON,
    FxIdWebFeaturesInfoNewsNewsFromJSONTyped,
    FxIdWebFeaturesInfoNewsNewsToJSON,
} from './FxIdWebFeaturesInfoNewsNews';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesInfoNewsResponse
 */
export interface FxIdWebFeaturesInfoNewsResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesInfoNewsNews>}
     * @memberof FxIdWebFeaturesInfoNewsResponse
     */
    News: Array<FxIdWebFeaturesInfoNewsNews>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInfoNewsResponse interface.
 */
export function instanceOfFxIdWebFeaturesInfoNewsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "News" in value;

    return isInstance;
}

export function FxIdWebFeaturesInfoNewsResponseFromJSON(json: any): FxIdWebFeaturesInfoNewsResponse {
    return FxIdWebFeaturesInfoNewsResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInfoNewsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'News': ((json['News'] as Array<any>).map(FxIdWebFeaturesInfoNewsNewsFromJSON)),
    };
}

export function FxIdWebFeaturesInfoNewsResponseToJSON(value?: FxIdWebFeaturesInfoNewsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'News': ((value.News as Array<any>).map(FxIdWebFeaturesInfoNewsNewsToJSON)),
    };
}

