/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData
     */
    InvoiceUrl: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "InvoiceUrl" in value;

    return isInstance;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'InvoiceUrl': json['InvoiceUrl'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'InvoiceUrl': value.InvoiceUrl,
    };
}

