/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesStoreStoreDataResponseProductWithPrice } from './FxIdWebFeaturesStoreStoreDataResponseProductWithPrice';
import {
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSON,
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSONTyped,
    FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSON,
} from './FxIdWebFeaturesStoreStoreDataResponseProductWithPrice';
import type { FxIdWebFeaturesStoreStoreDataResponseStoreMeta } from './FxIdWebFeaturesStoreStoreDataResponseStoreMeta';
import {
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSON,
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSONTyped,
    FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSON,
} from './FxIdWebFeaturesStoreStoreDataResponseStoreMeta';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreDataResponse
 */
export interface FxIdWebFeaturesStoreStoreDataResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesStoreStoreDataResponseProductWithPrice>}
     * @memberof FxIdWebFeaturesStoreStoreDataResponse
     */
    Products: Array<FxIdWebFeaturesStoreStoreDataResponseProductWithPrice>;
    /**
     * 
     * @type {FxIdWebFeaturesStoreStoreDataResponseStoreMeta}
     * @memberof FxIdWebFeaturesStoreStoreDataResponse
     */
    Meta: FxIdWebFeaturesStoreStoreDataResponseStoreMeta;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreDataResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreDataResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Products" in value;
    isInstance = isInstance && "Meta" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreStoreDataResponseFromJSON(json: any): FxIdWebFeaturesStoreStoreDataResponse {
    return FxIdWebFeaturesStoreStoreDataResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Products': ((json['Products'] as Array<any>).map(FxIdWebFeaturesStoreStoreDataResponseProductWithPriceFromJSON)),
        'Meta': FxIdWebFeaturesStoreStoreDataResponseStoreMetaFromJSON(json['Meta']),
    };
}

export function FxIdWebFeaturesStoreStoreDataResponseToJSON(value?: FxIdWebFeaturesStoreStoreDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Products': ((value.Products as Array<any>).map(FxIdWebFeaturesStoreStoreDataResponseProductWithPriceToJSON)),
        'Meta': FxIdWebFeaturesStoreStoreDataResponseStoreMetaToJSON(value.Meta),
    };
}

