/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsAdvertisementYandexOptions,
    instanceOfFxIdDomainSettingsAdvertisementYandexOptions,
    FxIdDomainSettingsAdvertisementYandexOptionsFromJSON,
    FxIdDomainSettingsAdvertisementYandexOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementYandexOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementYandexOptions';

/**
 * @type FxIdDomainSettingsAdvertisementOptionsYandex
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementOptionsYandex = FxIdDomainSettingsAdvertisementYandexOptions;

export function FxIdDomainSettingsAdvertisementOptionsYandexFromJSON(json: any): FxIdDomainSettingsAdvertisementOptionsYandex {
    return FxIdDomainSettingsAdvertisementOptionsYandexFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementOptionsYandexFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementOptionsYandex {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsAdvertisementYandexOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsAdvertisementOptionsYandexToJSON(value?: FxIdDomainSettingsAdvertisementOptionsYandex | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementYandexOptions(value)) {
        return FxIdDomainSettingsAdvertisementYandexOptionsToJSON(value as FxIdDomainSettingsAdvertisementYandexOptions);
    }

    return {};
}

