/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuth } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuth';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuth';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides } from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsBackground } from './FxIdDomainSettingsPublicWebClientConfigOptionsBackground';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsBackground';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem } from './FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFirebase } from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebase';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebase';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents } from './FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncher } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncher';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncher';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue } from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsUnity } from './FxIdDomainSettingsPublicWebClientConfigOptionsUnity';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsUnityToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsUnity';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptions {
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Diag?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsDiagItem> | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncher}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Launcher?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncher | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    CardImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    FullLogoUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    SplashImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    BrandLogoUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsBackground}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Background?: FxIdDomainSettingsPublicWebClientConfigOptionsBackground | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    SmallLogoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    HeaderLogo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    IconUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    ExternalPlayUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    PromoEnabled: boolean;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    AvailabilityOverrides?: FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    SocialSectionAvailable?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    DisableStoreLink?: boolean | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsFirebase}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Firebase?: FxIdDomainSettingsPublicWebClientConfigOptionsFirebase | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuth}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Auth?: FxIdDomainSettingsPublicWebClientConfigOptionsAuth | null;
    /**
     * 
     * @type {{ [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; }}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    LegalContacts?: { [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; } | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    ForcedAdapter?: string | null;
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Adapters?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig> | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    FxEvents?: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Advertisement?: FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsUnity}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptions
     */
    Unity?: FxIdDomainSettingsPublicWebClientConfigOptionsUnity | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "CardImageUrl" in value;
    isInstance = isInstance && "SmallLogoUrl" in value;
    isInstance = isInstance && "IconUrl" in value;
    isInstance = isInstance && "PromoEnabled" in value;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Diag': !exists(json, 'Diag') ? undefined : (json['Diag'] === null ? null : (json['Diag'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemFromJSON)),
        'Launcher': !exists(json, 'Launcher') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherFromJSON(json['Launcher']),
        'CardImageUrl': json['CardImageUrl'],
        'FullLogoUrl': !exists(json, 'FullLogoUrl') ? undefined : json['FullLogoUrl'],
        'SplashImageUrl': !exists(json, 'SplashImageUrl') ? undefined : json['SplashImageUrl'],
        'BrandLogoUrl': !exists(json, 'BrandLogoUrl') ? undefined : json['BrandLogoUrl'],
        'Background': !exists(json, 'Background') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSON(json['Background']),
        'SmallLogoUrl': json['SmallLogoUrl'],
        'HeaderLogo': !exists(json, 'HeaderLogo') ? undefined : json['HeaderLogo'],
        'IconUrl': json['IconUrl'],
        'ExternalPlayUrl': !exists(json, 'ExternalPlayUrl') ? undefined : json['ExternalPlayUrl'],
        'PromoEnabled': json['PromoEnabled'],
        'AvailabilityOverrides': !exists(json, 'AvailabilityOverrides') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSON(json['AvailabilityOverrides']),
        'SocialSectionAvailable': !exists(json, 'SocialSectionAvailable') ? undefined : json['SocialSectionAvailable'],
        'DisableStoreLink': !exists(json, 'DisableStoreLink') ? undefined : json['DisableStoreLink'],
        'Firebase': !exists(json, 'Firebase') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSON(json['Firebase']),
        'Auth': !exists(json, 'Auth') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthFromJSON(json['Auth']),
        'LegalContacts': !exists(json, 'LegalContacts') ? undefined : (json['LegalContacts'] === null ? null : mapValues(json['LegalContacts'], FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON)),
        'ForcedAdapter': !exists(json, 'ForcedAdapter') ? undefined : json['ForcedAdapter'],
        'Adapters': !exists(json, 'Adapters') ? undefined : (json['Adapters'] === null ? null : (json['Adapters'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON)),
        'FxEvents': !exists(json, 'FxEvents') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSON(json['FxEvents']),
        'Advertisement': !exists(json, 'Advertisement') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSON(json['Advertisement']),
        'Unity': !exists(json, 'Unity') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSON(json['Unity']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Diag': value.Diag === undefined ? undefined : (value.Diag === null ? null : (value.Diag as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsDiagItemToJSON)),
        'Launcher': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherToJSON(value.Launcher),
        'CardImageUrl': value.CardImageUrl,
        'FullLogoUrl': value.FullLogoUrl,
        'SplashImageUrl': value.SplashImageUrl,
        'BrandLogoUrl': value.BrandLogoUrl,
        'Background': FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSON(value.Background),
        'SmallLogoUrl': value.SmallLogoUrl,
        'HeaderLogo': value.HeaderLogo,
        'IconUrl': value.IconUrl,
        'ExternalPlayUrl': value.ExternalPlayUrl,
        'PromoEnabled': value.PromoEnabled,
        'AvailabilityOverrides': FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSON(value.AvailabilityOverrides),
        'SocialSectionAvailable': value.SocialSectionAvailable,
        'DisableStoreLink': value.DisableStoreLink,
        'Firebase': FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSON(value.Firebase),
        'Auth': FxIdDomainSettingsPublicWebClientConfigOptionsAuthToJSON(value.Auth),
        'LegalContacts': value.LegalContacts === undefined ? undefined : (value.LegalContacts === null ? null : mapValues(value.LegalContacts, FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON)),
        'ForcedAdapter': value.ForcedAdapter,
        'Adapters': value.Adapters === undefined ? undefined : (value.Adapters === null ? null : (value.Adapters as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON)),
        'FxEvents': FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsToJSON(value.FxEvents),
        'Advertisement': FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementToJSON(value.Advertisement),
        'Unity': FxIdDomainSettingsPublicWebClientConfigOptionsUnityToJSON(value.Unity),
    };
}

