/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateWithExternalIdState } from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import {
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateWithExternalIdState}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse
     */
    State: FxIdApplicationHandlersAuthAuthenticateWithExternalIdState;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse
     */
    Tokens?: FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse
     */
    Hint: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse
     */
    Email?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse
     */
    TransferOptions?: FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptions | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "State" in value;
    isInstance = isInstance && "Hint" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON(json['State']),
        'Tokens': !exists(json, 'Tokens') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSON(json['Tokens']),
        'Hint': json['Hint'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
        'TransferOptions': !exists(json, 'TransferOptions') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsFromJSON(json['TransferOptions']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON(value.State),
        'Tokens': FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensToJSON(value.Tokens),
        'Hint': value.Hint,
        'Email': value.Email,
        'TransferOptions': FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTransferOptionsToJSON(value.TransferOptions),
    };
}

