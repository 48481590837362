/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreContinueRedirectWithPiRequest
 */
export interface FxIdWebFeaturesStoreContinueRedirectWithPiRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreContinueRedirectWithPiRequest
     */
    Token: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreContinueRedirectWithPiRequest
     */
    ContinueToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreContinueRedirectWithPiRequest
     */
    PIEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreContinueRedirectWithPiRequest
     */
    PIPhoneNumber?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreContinueRedirectWithPiRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreContinueRedirectWithPiRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Token" in value;
    isInstance = isInstance && "ContinueToken" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreContinueRedirectWithPiRequestFromJSON(json: any): FxIdWebFeaturesStoreContinueRedirectWithPiRequest {
    return FxIdWebFeaturesStoreContinueRedirectWithPiRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreContinueRedirectWithPiRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreContinueRedirectWithPiRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'ContinueToken': json['ContinueToken'],
        'PIEmail': !exists(json, 'PIEmail') ? undefined : json['PIEmail'],
        'PIPhoneNumber': !exists(json, 'PIPhoneNumber') ? undefined : json['PIPhoneNumber'],
    };
}

export function FxIdWebFeaturesStoreContinueRedirectWithPiRequestToJSON(value?: FxIdWebFeaturesStoreContinueRedirectWithPiRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Token': value.Token,
        'ContinueToken': value.ContinueToken,
        'PIEmail': value.PIEmail,
        'PIPhoneNumber': value.PIPhoneNumber,
    };
}

