/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsAdvertisementOptions,
    instanceOfFxIdDomainSettingsAdvertisementOptions,
    FxIdDomainSettingsAdvertisementOptionsFromJSON,
    FxIdDomainSettingsAdvertisementOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement = FxIdDomainSettingsAdvertisementOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsAdvertisementOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisementToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAdvertisement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementOptions(value)) {
        return FxIdDomainSettingsAdvertisementOptionsToJSON(value as FxIdDomainSettingsAdvertisementOptions);
    }

    return {};
}

