/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClass,
    instanceOfFxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClass,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClassFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClassFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClassToJSON,
} from './FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClass';

/**
 * @type FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData = FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClass;

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClassFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClass(value)) {
        return FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClassToJSON(value as FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataClass);
    }

    return {};
}

