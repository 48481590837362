/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthData,
    instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthData,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthDataFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthDataFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthDataToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthData';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin = FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthData;

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin {
    return FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthData(value)) {
        return FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthDataToJSON(value as FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticatorsFacebookFacebookAuthData);
    }

    return {};
}

