/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGames
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGames = FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions;

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGamesFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGames {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGamesFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGamesToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookInstantGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialFacebookInstantGamesOptions);
    }

    return {};
}

