/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesPlayPlatformDataOptionsAndroid } from './FxIdWebFeaturesPlayPlatformDataOptionsAndroid';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsAndroidToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsAndroid';
import type { FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames } from './FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames';
import type { FxIdWebFeaturesPlayPlatformDataOptionsFxId } from './FxIdWebFeaturesPlayPlatformDataOptionsFxId';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsFxIdToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsFxId';
import type { FxIdWebFeaturesPlayPlatformDataOptionsIos } from './FxIdWebFeaturesPlayPlatformDataOptionsIos';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsIosFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsIosFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsIosToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsIos';
import type { FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki } from './FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki';
import type { FxIdWebFeaturesPlayPlatformDataOptionsSteam } from './FxIdWebFeaturesPlayPlatformDataOptionsSteam';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsSteamFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsSteamFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsSteamToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsSteam';
import type { FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom } from './FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom';
import type { FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay } from './FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay';
import type { FxIdWebFeaturesPlayPlatformDataOptionsWeb } from './FxIdWebFeaturesPlayPlatformDataOptionsWeb';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWebToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWeb';
import type { FxIdWebFeaturesPlayPlatformDataOptionsWindows } from './FxIdWebFeaturesPlayPlatformDataOptionsWindows';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWindows';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptions {
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    FacebookGames?: FxIdWebFeaturesPlayPlatformDataOptionsFacebookGames | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsFxId}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    FxId?: FxIdWebFeaturesPlayPlatformDataOptionsFxId | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    VkontaktePlay?: FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    VkontakteCom?: FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Odnoklassniki?: FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassniki | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsAndroid}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Android?: FxIdWebFeaturesPlayPlatformDataOptionsAndroid | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsIos}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Ios?: FxIdWebFeaturesPlayPlatformDataOptionsIos | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsWindows}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Windows?: FxIdWebFeaturesPlayPlatformDataOptionsWindows | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsWeb}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Web?: FxIdWebFeaturesPlayPlatformDataOptionsWeb | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptionsSteam}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptions
     */
    Steam?: FxIdWebFeaturesPlayPlatformDataOptionsSteam | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'FacebookGames': !exists(json, 'FacebookGames') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesFromJSON(json['FacebookGames']),
        'FxId': !exists(json, 'FxId') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSON(json['FxId']),
        'VkontaktePlay': !exists(json, 'VkontaktePlay') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSON(json['VkontaktePlay']),
        'VkontakteCom': !exists(json, 'VkontakteCom') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSON(json['VkontakteCom']),
        'Odnoklassniki': !exists(json, 'Odnoklassniki') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiFromJSON(json['Odnoklassniki']),
        'Android': !exists(json, 'Android') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsAndroidFromJSON(json['Android']),
        'Ios': !exists(json, 'Ios') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsIosFromJSON(json['Ios']),
        'Windows': !exists(json, 'Windows') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsWindowsFromJSON(json['Windows']),
        'Web': !exists(json, 'Web') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSON(json['Web']),
        'Steam': !exists(json, 'Steam') ? undefined : FxIdWebFeaturesPlayPlatformDataOptionsSteamFromJSON(json['Steam']),
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FacebookGames': FxIdWebFeaturesPlayPlatformDataOptionsFacebookGamesToJSON(value.FacebookGames),
        'FxId': FxIdWebFeaturesPlayPlatformDataOptionsFxIdToJSON(value.FxId),
        'VkontaktePlay': FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayToJSON(value.VkontaktePlay),
        'VkontakteCom': FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSON(value.VkontakteCom),
        'Odnoklassniki': FxIdWebFeaturesPlayPlatformDataOptionsOdnoklassnikiToJSON(value.Odnoklassniki),
        'Android': FxIdWebFeaturesPlayPlatformDataOptionsAndroidToJSON(value.Android),
        'Ios': FxIdWebFeaturesPlayPlatformDataOptionsIosToJSON(value.Ios),
        'Windows': FxIdWebFeaturesPlayPlatformDataOptionsWindowsToJSON(value.Windows),
        'Web': FxIdWebFeaturesPlayPlatformDataOptionsWebToJSON(value.Web),
        'Steam': FxIdWebFeaturesPlayPlatformDataOptionsSteamToJSON(value.Steam),
    };
}

