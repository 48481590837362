// TODO: Заменить на настоящий АПИ, когда он будет готов
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import OpenApiClient from "../OpenApiClient";
import { FxIdWebFeaturesFriendsGetFriendsResponseFriendData } from "../gen";

export const useFriendsQuery = (
	options?: Omit<
		UseQueryOptions<
			FxIdWebFeaturesFriendsGetFriendsResponseFriendData[],
			unknown,
			FxIdWebFeaturesFriendsGetFriendsResponseFriendData[],
			readonly ["friends"]
		>,
		"queryFn" | "initialData" | "queryKey"
	>
) =>
	useQuery(
		["friends"] as const,
		async () => (await OpenApiClient.Friends.fxIdWebFeaturesFriendsGetFriendsEndpoint({ FriendsIds: [] })).Friends,
		options
	);
