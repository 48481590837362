/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
     */
    DownloadUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
     */
    SoftUpdateVersion?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig
     */
    HardUpdateVersion?: number | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DownloadUrl': !exists(json, 'DownloadUrl') ? undefined : json['DownloadUrl'],
        'SoftUpdateVersion': !exists(json, 'SoftUpdateVersion') ? undefined : json['SoftUpdateVersion'],
        'HardUpdateVersion': !exists(json, 'HardUpdateVersion') ? undefined : json['HardUpdateVersion'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfigToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherUpdateConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DownloadUrl': value.DownloadUrl,
        'SoftUpdateVersion': value.SoftUpdateVersion,
        'HardUpdateVersion': value.HardUpdateVersion,
    };
}

