/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteEmailInviteResponse
 */
export interface FxIdWebFeaturesInviteEmailInviteResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesInviteEmailInviteResponse
     */
    Success: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteResponse
     */
    Error: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteResponse
     */
    Token?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInviteEmailInviteResponse interface.
 */
export function instanceOfFxIdWebFeaturesInviteEmailInviteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Success" in value;
    isInstance = isInstance && "Error" in value;

    return isInstance;
}

export function FxIdWebFeaturesInviteEmailInviteResponseFromJSON(json: any): FxIdWebFeaturesInviteEmailInviteResponse {
    return FxIdWebFeaturesInviteEmailInviteResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailInviteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteEmailInviteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Error': json['Error'],
        'Token': !exists(json, 'Token') ? undefined : json['Token'],
    };
}

export function FxIdWebFeaturesInviteEmailInviteResponseToJSON(value?: FxIdWebFeaturesInviteEmailInviteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Success': value.Success,
        'Error': value.Error,
        'Token': value.Token,
    };
}

