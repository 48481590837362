/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference } from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference';
import {
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
 */
export interface FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    Alpha?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    Antialias?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    Depth?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    FailIfMajorPerformanceCaveat?: boolean | null;
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    PowerPreference: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreference;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    PremultipliedAlpha?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    PreserveDrawingBuffer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    Stencil?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    Desynchronized?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions
     */
    XrCompatible?: boolean | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PowerPreference" in value;

    return isInstance;
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions {
    return FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Alpha': !exists(json, 'Alpha') ? undefined : json['Alpha'],
        'Antialias': !exists(json, 'Antialias') ? undefined : json['Antialias'],
        'Depth': !exists(json, 'Depth') ? undefined : json['Depth'],
        'FailIfMajorPerformanceCaveat': !exists(json, 'FailIfMajorPerformanceCaveat') ? undefined : json['FailIfMajorPerformanceCaveat'],
        'PowerPreference': FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceFromJSON(json['PowerPreference']),
        'PremultipliedAlpha': !exists(json, 'PremultipliedAlpha') ? undefined : json['PremultipliedAlpha'],
        'PreserveDrawingBuffer': !exists(json, 'PreserveDrawingBuffer') ? undefined : json['PreserveDrawingBuffer'],
        'Stencil': !exists(json, 'Stencil') ? undefined : json['Stencil'],
        'Desynchronized': !exists(json, 'Desynchronized') ? undefined : json['Desynchronized'],
        'XrCompatible': !exists(json, 'XrCompatible') ? undefined : json['XrCompatible'],
    };
}

export function FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsToJSON(value?: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Alpha': value.Alpha,
        'Antialias': value.Antialias,
        'Depth': value.Depth,
        'FailIfMajorPerformanceCaveat': value.FailIfMajorPerformanceCaveat,
        'PowerPreference': FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsPowerPreferenceToJSON(value.PowerPreference),
        'PremultipliedAlpha': value.PremultipliedAlpha,
        'PreserveDrawingBuffer': value.PreserveDrawingBuffer,
        'Stencil': value.Stencil,
        'Desynchronized': value.Desynchronized,
        'XrCompatible': value.XrCompatible,
    };
}

