/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileUpdateProfileSettingsRequest
 */
export interface FxIdWebFeaturesProfileUpdateProfileSettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileUpdateProfileSettingsRequest
     */
    DUMMY: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileUpdateProfileSettingsRequest
     */
    MarketingEnabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileUpdateProfileSettingsRequest
     */
    Language?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileUpdateProfileSettingsRequest interface.
 */
export function instanceOfFxIdWebFeaturesProfileUpdateProfileSettingsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "DUMMY" in value;

    return isInstance;
}

export function FxIdWebFeaturesProfileUpdateProfileSettingsRequestFromJSON(json: any): FxIdWebFeaturesProfileUpdateProfileSettingsRequest {
    return FxIdWebFeaturesProfileUpdateProfileSettingsRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileUpdateProfileSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileUpdateProfileSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DUMMY': json['__DUMMY'],
        'MarketingEnabled': !exists(json, 'MarketingEnabled') ? undefined : json['MarketingEnabled'],
        'Language': !exists(json, 'Language') ? undefined : json['Language'],
    };
}

export function FxIdWebFeaturesProfileUpdateProfileSettingsRequestToJSON(value?: FxIdWebFeaturesProfileUpdateProfileSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '__DUMMY': value.DUMMY,
        'MarketingEnabled': value.MarketingEnabled,
        'Language': value.Language,
    };
}

