/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions,
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes = FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions;

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes {
    return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimesToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsRuntimes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions);
    }

    return {};
}

