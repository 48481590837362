/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreAvailabilityResponse
 */
export interface FxIdWebFeaturesStoreStoreAvailabilityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreStoreAvailabilityResponse
     */
    Available: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesStoreStoreAvailabilityResponse
     */
    Games: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreAvailabilityResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreAvailabilityResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Available" in value;
    isInstance = isInstance && "Games" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSON(json: any): FxIdWebFeaturesStoreStoreAvailabilityResponse {
    return FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreAvailabilityResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Available': json['Available'],
        'Games': json['Games'],
    };
}

export function FxIdWebFeaturesStoreStoreAvailabilityResponseToJSON(value?: FxIdWebFeaturesStoreStoreAvailabilityResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Available': value.Available,
        'Games': value.Games,
    };
}

