/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationStorePriceData } from './FxIdApplicationStorePriceData';
import {
    FxIdApplicationStorePriceDataFromJSON,
    FxIdApplicationStorePriceDataFromJSONTyped,
    FxIdApplicationStorePriceDataToJSON,
} from './FxIdApplicationStorePriceData';
import type { FxIdDomainStoreEnumsOrderState } from './FxIdDomainStoreEnumsOrderState';
import {
    FxIdDomainStoreEnumsOrderStateFromJSON,
    FxIdDomainStoreEnumsOrderStateFromJSONTyped,
    FxIdDomainStoreEnumsOrderStateToJSON,
} from './FxIdDomainStoreEnumsOrderState';
import type { FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData } from './FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData';
import {
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSONTyped,
    FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSON,
} from './FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreGetPaymentStatusResponse
 */
export interface FxIdWebFeaturesStoreGetPaymentStatusResponse {
    /**
     * 
     * @type {FxIdDomainStoreEnumsOrderState}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    Status: FxIdDomainStoreEnumsOrderState;
    /**
     * 
     * @type {FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    OrderProducts: FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData;
    /**
     * 
     * @type {FxIdApplicationStorePriceData}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    TotalPrice: FxIdApplicationStorePriceData;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponse
     */
    Game: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreGetPaymentStatusResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreGetPaymentStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Status" in value;
    isInstance = isInstance && "OrderProducts" in value;
    isInstance = isInstance && "TotalPrice" in value;
    isInstance = isInstance && "Game" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusResponse {
    return FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreGetPaymentStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Status': FxIdDomainStoreEnumsOrderStateFromJSON(json['Status']),
        'OrderProducts': FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSON(json['OrderProducts']),
        'TotalPrice': FxIdApplicationStorePriceDataFromJSON(json['TotalPrice']),
        'Game': json['Game'],
    };
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseToJSON(value?: FxIdWebFeaturesStoreGetPaymentStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Status': FxIdDomainStoreEnumsOrderStateToJSON(value.Status),
        'OrderProducts': FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSON(value.OrderProducts),
        'TotalPrice': FxIdApplicationStorePriceDataToJSON(value.TotalPrice),
        'Game': value.Game,
    };
}

