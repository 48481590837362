/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData
     */
    PaymentUri: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PaymentUri" in value;

    return isInstance;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PaymentUri': json['PaymentUri'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PaymentUri': value.PaymentUri,
    };
}

