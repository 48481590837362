/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult } from './FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult';
import {
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSONTyped,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSON,
} from './FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse
 */
export interface FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse
     */
    Success: boolean;
    /**
     * 
     * @type {FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult}
     * @memberof FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse
     */
    Result?: FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResult | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse interface.
 */
export function instanceOfFxIdWebFeaturesProfileFetchFxIdGameConnectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Success" in value;

    return isInstance;
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSON(json: any): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    return FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Result': !exists(json, 'Result') ? undefined : FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultFromJSON(json['Result']),
    };
}

export function FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseToJSON(value?: FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Success': value.Success,
        'Result': FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseResultToJSON(value.Result),
    };
}

