import i18next, { Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { appStore } from "./Stores/AppStore";

import de from "./Locales/de/translation.json";
import en from "./Locales/en/translation.json";
import es from "./Locales/es/translation.json";
import fa from "./Locales/fa/translation.json";
import fr from "./Locales/fr/translation.json";
import it from "./Locales/it/translation.json";
import ja from "./Locales/ja/translation.json";
import ko from "./Locales/ko/translation.json";
import ptbr from "./Locales/ptbr/translation.json";
import ru from "./Locales/ru/translation.json";
import tr from "./Locales/tr/translation.json";
import zh from "./Locales/zh/translation.json";
import uk from "./Locales/uk/translation.json";
import pl from "./Locales/pl/translation.json";
import OpenApiClient from "./Api/OpenApiClient";
import { getQueriedProfileData, revalidateProfileDataQuery } from "./Api/Queries";
import { authTokenExists } from "./Api/Auth";
import { authStore } from "./Stores";
// const hi = require("./Locales/hi/translation.json");

import * as dayjs from "dayjs";

import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fa";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/pt-br";
import "dayjs/locale/ru";
import "dayjs/locale/tr";
import "dayjs/locale/zh";
import "dayjs/locale/hi";
import "dayjs/locale/uk";
import "dayjs/locale/pl";

export const SUPPORTED_LANGUAGES = new Map([
	[new Intl.Locale("en"), en as any],
	[new Intl.Locale("de"), de as any],
	[new Intl.Locale("es"), es as any],
	[new Intl.Locale("fa"), fa as any],
	[new Intl.Locale("fr"), fr as any],
	[new Intl.Locale("it"), it as any],
	[new Intl.Locale("ja"), ja as any],
	[new Intl.Locale("ko"), ko as any],
	[new Intl.Locale("pt-br"), ptbr as any],
	[new Intl.Locale("ru"), ru as any],
	[new Intl.Locale("tr"), tr as any],
	[new Intl.Locale("zh"), zh as any],
	[new Intl.Locale("uk"), uk as any],
	[new Intl.Locale("pl"), pl as any]
]);

export const SUPPORTED_LANGUAGE_CODES = [...SUPPORTED_LANGUAGES.keys()].map((locale) => locale.language);

const resources = [...SUPPORTED_LANGUAGES].reduce<Resource>((acc, cur) => {
	const [locale, translation] = cur;
	acc[locale.baseName] = { translation };
	return acc;
}, {});

void i18next
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		// lng: "en",
		fallbackLng: "en",
		debug: process.env.NODE_ENV === "development",
		load: "languageOnly",
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
			// defaultVariables: {
			// 	companyBrand: appStore.getState().brandName
			// }
		},
		react: {
			transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i", "p"]
		},
		resources
	});

export const defaultLocale = "en";

// Синхронизируем язык i18next и авторизации при инициализации приложения
authStore.getState().updateAuthLanguage(i18next.resolvedLanguage ?? defaultLocale);

i18next.on("languageChanged", async (newLang) => {
	dayjs.locale(newLang);
	authStore.getState().updateAuthLanguage(newLang);
	if (!authTokenExists()) {
		log.warn("User not logged in. Skipping saving language change");
		return;
	}
	const profileData = await getQueriedProfileData();
	if (profileData.Locale !== newLang) {
		await OpenApiClient.Profile.fxIdWebFeaturesProfileUpdateProfileSettingsEndpoint({
			Language: newLang,
			DUMMY: false
		});
		void revalidateProfileDataQuery();
	}
});

export default i18next;
