/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsTwitchAuthOptions
 */
export interface FxIdDomainSettingsTwitchAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsTwitchAuthOptions
     */
    ClientId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsTwitchAuthOptions
     */
    RedirectUri: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsTwitchAuthOptions interface.
 */
export function instanceOfFxIdDomainSettingsTwitchAuthOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ClientId" in value;
    isInstance = isInstance && "RedirectUri" in value;

    return isInstance;
}

export function FxIdDomainSettingsTwitchAuthOptionsFromJSON(json: any): FxIdDomainSettingsTwitchAuthOptions {
    return FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsTwitchAuthOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ClientId': json['ClientId'],
        'RedirectUri': json['RedirectUri'],
    };
}

export function FxIdDomainSettingsTwitchAuthOptionsToJSON(value?: FxIdDomainSettingsTwitchAuthOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ClientId': value.ClientId,
        'RedirectUri': value.RedirectUri,
    };
}

