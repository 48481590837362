import React from "react";
import { Box, Button, Text } from "@mantine/core";
import { ContextModalProps, openContextModal } from "@mantine/modals";
import { useTranslation } from "react-i18next";

export const InfoModal = ({ context, id, innerProps }: ContextModalProps<{ modalBody: string }>) => {
	const { t } = useTranslation();

	return (
		<>
			<Text size="sm">{innerProps.modalBody}</Text>
			<Button fullWidth mt="md" onClick={() => context.closeModal(id)}>
				{t("button_close")}
			</Button>
		</>
	);
};

export const openInfoModal = (message: string) =>
	openContextModal({
		modal: "info",
		innerProps: {
			modalBody: message
		},
		withCloseButton: false
	});
