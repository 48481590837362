import "./index.css";

import i18next from "i18next";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";

import imgMainBottom from "./main_bottom.png";

const Footer = () => {
	const { t } = useTranslation();

	const tosUrl = "/Docs/terms-of-service";
	const privacyUrl = "/Docs/privacy-policy";

	return (
		<footer className="Footer">
			<Row
				className="Footer-Row"
				style={{
					backgroundImage: `url(${imgMainBottom})`
				}}
				xs={2}
			>
				<Col style={{ maxWidth: "400px" }}>
					<Row>
						<Button variant="link" href={privacyUrl} target="_blank">
							{t("pp")}
						</Button>
					</Row>
					<Row>
						<Button variant="link" href={tosUrl} target="_blank">
							{t("tos")}
						</Button>
					</Row>
					{
						<>
							<Row>
								<Button variant="link" href="/Docs/gc/fxid/contacts">
									{t("legal_contacts")}
								</Button>
							</Row>
						</>
					}
				</Col>
			</Row>
		</footer>
	);
};

export default Footer;
