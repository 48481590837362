/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue } from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue';
import type { FxIdDomainSettingsUrlOpenMethod } from './FxIdDomainSettingsUrlOpenMethod';
import {
    FxIdDomainSettingsUrlOpenMethodFromJSON,
    FxIdDomainSettingsUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsUrlOpenMethodToJSON,
} from './FxIdDomainSettingsUrlOpenMethod';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    IframeType: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    ExternalTosUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    TosUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    ExternalPpUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    PpUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    ContactsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    HeaderLogo?: string | null;
    /**
     * 
     * @type {{ [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; }}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig
     */
    LegalContacts?: { [key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue; } | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "IframeType" in value;
    isInstance = isInstance && "TosUrlOpenMethod" in value;
    isInstance = isInstance && "PpUrlOpenMethod" in value;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IframeType': json['IframeType'],
        'ExternalTosUrl': !exists(json, 'ExternalTosUrl') ? undefined : json['ExternalTosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['TosUrlOpenMethod']),
        'ExternalPpUrl': !exists(json, 'ExternalPpUrl') ? undefined : json['ExternalPpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['PpUrlOpenMethod']),
        'ContactsUrl': !exists(json, 'ContactsUrl') ? undefined : json['ContactsUrl'],
        'HeaderLogo': !exists(json, 'HeaderLogo') ? undefined : json['HeaderLogo'],
        'LegalContacts': !exists(json, 'LegalContacts') ? undefined : (json['LegalContacts'] === null ? null : mapValues(json['LegalContacts'], FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueFromJSON)),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IframeType': value.IframeType,
        'ExternalTosUrl': value.ExternalTosUrl,
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value.TosUrlOpenMethod),
        'ExternalPpUrl': value.ExternalPpUrl,
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value.PpUrlOpenMethod),
        'ContactsUrl': value.ContactsUrl,
        'HeaderLogo': value.HeaderLogo,
        'LegalContacts': value.LegalContacts === undefined ? undefined : (value.LegalContacts === null ? null : mapValues(value.LegalContacts, FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValueToJSON)),
    };
}

