/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationStoreOrderProductData } from './FxIdApplicationStoreOrderProductData';
import {
    FxIdApplicationStoreOrderProductDataFromJSON,
    FxIdApplicationStoreOrderProductDataFromJSONTyped,
    FxIdApplicationStoreOrderProductDataToJSON,
} from './FxIdApplicationStoreOrderProductData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData
 */
export interface FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    /**
     * 
     * @type {FxIdApplicationStoreOrderProductData}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData
     */
    MainProduct: FxIdApplicationStoreOrderProductData;
    /**
     * 
     * @type {Array<FxIdApplicationStoreOrderProductData>}
     * @memberof FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData
     */
    AdditionalProducts?: Array<FxIdApplicationStoreOrderProductData> | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData interface.
 */
export function instanceOfFxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "MainProduct" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSON(json: any): FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    return FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'MainProduct': FxIdApplicationStoreOrderProductDataFromJSON(json['MainProduct']),
        'AdditionalProducts': !exists(json, 'AdditionalProducts') ? undefined : (json['AdditionalProducts'] === null ? null : (json['AdditionalProducts'] as Array<any>).map(FxIdApplicationStoreOrderProductDataFromJSON)),
    };
}

export function FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsDataToJSON(value?: FxIdWebFeaturesStoreGetPaymentStatusResponseOrderProductsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MainProduct': FxIdApplicationStoreOrderProductDataToJSON(value.MainProduct),
        'AdditionalProducts': value.AdditionalProducts === undefined ? undefined : (value.AdditionalProducts === null ? null : (value.AdditionalProducts as Array<any>).map(FxIdApplicationStoreOrderProductDataToJSON)),
    };
}

