/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsDimensions,
    instanceOfFxIdDomainSettingsDimensions,
    FxIdDomainSettingsDimensionsFromJSON,
    FxIdDomainSettingsDimensionsFromJSONTyped,
    FxIdDomainSettingsDimensionsToJSON,
} from './FxIdDomainSettingsDimensions';

/**
 * @type FxIdDomainSettingsPresentationOptionsMinRatio
 * 
 * @export
 */
export type FxIdDomainSettingsPresentationOptionsMinRatio = FxIdDomainSettingsDimensions;

export function FxIdDomainSettingsPresentationOptionsMinRatioFromJSON(json: any): FxIdDomainSettingsPresentationOptionsMinRatio {
    return FxIdDomainSettingsPresentationOptionsMinRatioFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsMinRatioFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPresentationOptionsMinRatio {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsDimensionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPresentationOptionsMinRatioToJSON(value?: FxIdDomainSettingsPresentationOptionsMinRatio | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsDimensions(value)) {
        return FxIdDomainSettingsDimensionsToJSON(value as FxIdDomainSettingsDimensions);
    }

    return {};
}

