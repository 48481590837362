/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLogos
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLogos
     */
    CompanyLogo: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLogos
     */
    BrandLogo: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLogos
     */
    WebPlayerLogo: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLogos interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLogos(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "CompanyLogo" in value;
    isInstance = isInstance && "BrandLogo" in value;
    isInstance = isInstance && "WebPlayerLogo" in value;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLogos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CompanyLogo': json['CompanyLogo'],
        'BrandLogo': json['BrandLogo'],
        'WebPlayerLogo': json['WebPlayerLogo'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLogosToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLogos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CompanyLogo': value.CompanyLogo,
        'BrandLogo': value.BrandLogo,
        'WebPlayerLogo': value.WebPlayerLogo,
    };
}

