/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsIosOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsIosOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsIosOptions
     */
    AppStoreUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsIosOptions
     */
    AppStoreId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsIosOptions
     */
    AppLinkUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsIosOptions
     */
    BundleId: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsIosOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsIosOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AppStoreUrl" in value;
    isInstance = isInstance && "AppStoreId" in value;
    isInstance = isInstance && "AppLinkUrl" in value;
    isInstance = isInstance && "BundleId" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsIosOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsIosOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsIosOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsIosOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsIosOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AppStoreUrl': json['AppStoreUrl'],
        'AppStoreId': json['AppStoreId'],
        'AppLinkUrl': json['AppLinkUrl'],
        'BundleId': json['BundleId'],
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsIosOptionsToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsIosOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AppStoreUrl': value.AppStoreUrl,
        'AppStoreId': value.AppStoreId,
        'AppLinkUrl': value.AppLinkUrl,
        'BundleId': value.BundleId,
    };
}

