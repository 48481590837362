/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens
 */
export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens
     */
    RefreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens
     */
    GameAccessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens
     */
    AccessToken?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "RefreshToken" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'RefreshToken': json['RefreshToken'],
        'GameAccessToken': !exists(json, 'GameAccessToken') ? undefined : json['GameAccessToken'],
        'AccessToken': !exists(json, 'AccessToken') ? undefined : json['AccessToken'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokensToJSON(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdTokens | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'RefreshToken': value.RefreshToken,
        'GameAccessToken': value.GameAccessToken,
        'AccessToken': value.AccessToken,
    };
}

