/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainModelsDBDBInviteInviteStatusErrorCode {
    Created = 0,
    Success = 1,
    FailedGameOfThisTypeAlreadyConnectedToYourAccount = 1000,
    FailedThisGameAccountAlreadyConnectedToAnotherAccount = 1001,
    FailedTimeout = 1002,
    FailedGameOfThisTypeNotConnectedToYourAccount = 1003,
    FailedForUnknownReason = 1999,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSON(json: any): FxIdDomainModelsDBDBInviteInviteStatusErrorCode {
    return FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSONTyped(json, false);
}

export function FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainModelsDBDBInviteInviteStatusErrorCode {
    return json as FxIdDomainModelsDBDBInviteInviteStatusErrorCode;
}

export function FxIdDomainModelsDBDBInviteInviteStatusErrorCodeToJSON(value?: FxIdDomainModelsDBDBInviteInviteStatusErrorCode | null): any {
    return value as any;
}

