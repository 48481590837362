/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev } from './FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev';
import {
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions
 */
export interface FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions {
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions
     */
    DevToDev?: FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDev | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPublicDataBaseStatisticsOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions {
    return FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DevToDev': !exists(json, 'DevToDev') ? undefined : FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevFromJSON(json['DevToDev']),
    };
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsToJSON(value?: FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DevToDev': FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsDevToDevToJSON(value.DevToDev),
    };
}

