/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPublicDataBaseStoreOptions
 */
export interface FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStoreOptions
     */
    XsollaEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStoreOptions
     */
    YookassaEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBaseStoreOptions
     */
    StripeEnabled: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPublicDataBaseStoreOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPublicDataBaseStoreOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "XsollaEnabled" in value;
    isInstance = isInstance && "YookassaEnabled" in value;
    isInstance = isInstance && "StripeEnabled" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    return FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseStoreOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'XsollaEnabled': json['XsollaEnabled'],
        'YookassaEnabled': json['YookassaEnabled'],
        'StripeEnabled': json['StripeEnabled'],
    };
}

export function FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSON(value?: FxIdWebFeaturesPlayPublicDataBaseStoreOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'XsollaEnabled': value.XsollaEnabled,
        'YookassaEnabled': value.YookassaEnabled,
        'StripeEnabled': value.StripeEnabled,
    };
}

