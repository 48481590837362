/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfiguration } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfiguration';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfigurationFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfigurationFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfigurationToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfiguration';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfiguration}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
     */
    LauncherConfiguration?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfiguration | null;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
     */
    Release?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease | null;
    /**
     * 
     * @type {Array<FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact>}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult
     */
    Artifacts?: Array<FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact> | null;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'LauncherConfiguration': !exists(json, 'launcherConfiguration') ? undefined : FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfigurationFromJSON(json['launcherConfiguration']),
        'Release': !exists(json, 'release') ? undefined : FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseFromJSON(json['release']),
        'Artifacts': !exists(json, 'artifacts') ? undefined : (json['artifacts'] === null ? null : (json['artifacts'] as Array<any>).map(FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSON)),
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'launcherConfiguration': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultLauncherConfigurationToJSON(value.LauncherConfiguration),
        'release': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseToJSON(value.Release),
        'artifacts': value.Artifacts === undefined ? undefined : (value.Artifacts === null ? null : (value.Artifacts as Array<any>).map(FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSON)),
    };
}

