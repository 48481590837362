/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayListResponseBuild
 */
export interface FxIdWebFeaturesPlayListResponseBuild {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Id: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    GameId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    BuildType: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Sha: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Description: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    ChangeSet: string;
    /**
     * 
     * @type {Date}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    CreatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    BuildNumber: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    Metadata: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayListResponseBuild
     */
    InstallUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayListResponseBuild interface.
 */
export function instanceOfFxIdWebFeaturesPlayListResponseBuild(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Id" in value;
    isInstance = isInstance && "GameId" in value;
    isInstance = isInstance && "BuildType" in value;
    isInstance = isInstance && "Sha" in value;
    isInstance = isInstance && "Description" in value;
    isInstance = isInstance && "ChangeSet" in value;
    isInstance = isInstance && "CreatedAt" in value;
    isInstance = isInstance && "BuildNumber" in value;
    isInstance = isInstance && "Metadata" in value;
    isInstance = isInstance && "InstallUrl" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayListResponseBuildFromJSON(json: any): FxIdWebFeaturesPlayListResponseBuild {
    return FxIdWebFeaturesPlayListResponseBuildFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayListResponseBuildFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayListResponseBuild {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Id': json['Id'],
        'GameId': json['GameId'],
        'BuildType': json['BuildType'],
        'Sha': json['Sha'],
        'Description': json['Description'],
        'ChangeSet': json['ChangeSet'],
        'CreatedAt': (new Date(json['CreatedAt'])),
        'BuildNumber': json['BuildNumber'],
        'Metadata': json['Metadata'],
        'InstallUrl': json['InstallUrl'],
    };
}

export function FxIdWebFeaturesPlayListResponseBuildToJSON(value?: FxIdWebFeaturesPlayListResponseBuild | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.Id,
        'GameId': value.GameId,
        'BuildType': value.BuildType,
        'Sha': value.Sha,
        'Description': value.Description,
        'ChangeSet': value.ChangeSet,
        'CreatedAt': (value.CreatedAt.toISOString()),
        'BuildNumber': value.BuildNumber,
        'Metadata': value.Metadata,
        'InstallUrl': value.InstallUrl,
    };
}

