/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions
     */
    SignedRequest: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions
     */
    Locale?: string | null;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "SignedRequest" in value;

    return isInstance;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SignedRequest': json['SignedRequest'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptionsToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoFacebookGamesLoginOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SignedRequest': value.SignedRequest,
        'Locale': value.Locale,
    };
}

