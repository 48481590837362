/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo,
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo = FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo);
    }

    return {};
}

