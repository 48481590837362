import React from "react";
import { Center, Container, Image } from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { CDN_PREFIX } from "../../Config";
import gcQrCode from "../../Images/qr-codes/gc.png";
import dnlQrCode from "../../Images/qr-codes/dnl.png";

const GAME_QR_CODES = new Map([
	["gc", `${CDN_PREFIX}${gcQrCode}`],
	["dnl", `${CDN_PREFIX}${dnlQrCode}`],
	[null, undefined]
]);

export const DiscordQr = () => {
	const [searchParams] = useSearchParams();
	const qr = GAME_QR_CODES.get(searchParams.get("game"));

	if (!qr) return null;
	return (
		<Container sx={{ width: "100vmin" }}>
			<Center>
				<Image src={qr} />
			</Center>
		</Container>
	);
};
