/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail } from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    LegalName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    OgrnNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    InnNumber?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact
     */
    Email?: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmail | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLegalContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "LegalName" in value;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'LegalName': json['LegalName'],
        'OgrnNumber': !exists(json, 'OgrnNumber') ? undefined : json['OgrnNumber'],
        'InnNumber': !exists(json, 'InnNumber') ? undefined : json['InnNumber'],
        'Email': !exists(json, 'Email') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailFromJSON(json['Email']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'LegalName': value.LegalName,
        'OgrnNumber': value.OgrnNumber,
        'InnNumber': value.InnNumber,
        'Email': FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactEmailToJSON(value.Email),
    };
}

