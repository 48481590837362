/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBInviteInviteStatusErrorCode } from './FxIdDomainModelsDBDBInviteInviteStatusErrorCode';
import {
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSON,
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSONTyped,
    FxIdDomainModelsDBDBInviteInviteStatusErrorCodeToJSON,
} from './FxIdDomainModelsDBDBInviteInviteStatusErrorCode';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesInviteEmailInviteStatusResponse
 */
export interface FxIdWebFeaturesInviteEmailInviteStatusResponse {
    /**
     * 
     * @type {FxIdDomainModelsDBDBInviteInviteStatusErrorCode}
     * @memberof FxIdWebFeaturesInviteEmailInviteStatusResponse
     */
    StatusCode: FxIdDomainModelsDBDBInviteInviteStatusErrorCode;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInviteEmailInviteStatusResponse
     */
    LoginToken?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInviteEmailInviteStatusResponse interface.
 */
export function instanceOfFxIdWebFeaturesInviteEmailInviteStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "StatusCode" in value;

    return isInstance;
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSON(json: any): FxIdWebFeaturesInviteEmailInviteStatusResponse {
    return FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInviteEmailInviteStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'StatusCode': FxIdDomainModelsDBDBInviteInviteStatusErrorCodeFromJSON(json['StatusCode']),
        'LoginToken': !exists(json, 'LoginToken') ? undefined : json['LoginToken'],
    };
}

export function FxIdWebFeaturesInviteEmailInviteStatusResponseToJSON(value?: FxIdWebFeaturesInviteEmailInviteStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'StatusCode': FxIdDomainModelsDBDBInviteInviteStatusErrorCodeToJSON(value.StatusCode),
        'LoginToken': value.LoginToken,
    };
}

