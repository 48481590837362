import {
	type FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue,
	FxIdDomainSettingsUrlOpenMethod,
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesPlayPublicDataBase
} from "../Api/gen";
import i18n from "../i18n";

function getValueByPath<T>(obj: any, path: string): T | undefined {
	return path.split(".").reduce((acc, part) => acc && acc[part], obj) as T;
}

export function getValueFromConfigWithOverrides<T>(
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig,
	portalConfigCompanyBrandingPath: string,
	gameConfigPath: string,
	adapter?: string,
	gameConfig?: FxIdWebFeaturesPlayPublicDataBase
): T | undefined {
	let result: T | undefined;

	// Step 1: Extract value from portalConfig.PortalPublicWebConfig.CompanyBranding using portalConfigCompanyBrandingPath
	const portalBrandingValue = getValueByPath<T>(
		portalConfig.PortalPublicWebConfig.CompanyBranding,
		portalConfigCompanyBrandingPath
	);
	if (portalBrandingValue) result = portalBrandingValue;

	// Step 2: Check portalConfig.PortalPublicWebConfig.CompanyBranding.Adapters using custom key (IframeType)
	const portalAdapterOverride = adapter
		? portalConfig.PortalPublicWebConfig.CompanyBranding.Adapters?.find((el) => el.IframeType === adapter)
		: undefined;
	if (portalAdapterOverride) {
		const adapterValue = getValueByPath<T>(portalAdapterOverride, gameConfigPath);
		if (adapterValue) result = adapterValue;
	}

	// Step 3: Check gameConfig.PublicWebClientConfig for the value using gameConfigPath if gameConfig is provided
	if (gameConfig) {
		const gamePublicConfigValue = getValueByPath<T>(gameConfig.PublicWebClientConfig, gameConfigPath);
		if (gamePublicConfigValue) result = gamePublicConfigValue;

		// Step 4: Check gameConfig.PublicWebClientConfig.Adapters using custom key (IframeType) if gameConfig is provided
		const gameAdapterOverride = adapter
			? gameConfig.PublicWebClientConfig.Adapters?.find((el) => el.IframeType === adapter)
			: undefined;
		if (gameAdapterOverride) {
			const adapterValue = getValueByPath<T>(gameAdapterOverride, gameConfigPath);
			if (adapterValue) result = adapterValue;
		}
	}

	return result;
}

export function getDocsAndLegal(
	portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig,
	gameConfig?: FxIdWebFeaturesPlayPublicDataBase,
	adapter?: string
): {
	tos?: {
		url: string;
		openMethod: FxIdDomainSettingsUrlOpenMethod;
	};
	pp?: {
		url: string;
		openMethod: FxIdDomainSettingsUrlOpenMethod;
	};
	legalContacts?: {
		[key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue;
	};
	localeLegalContacts?: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue;
} {
	const legalContacts = getValueFromConfigWithOverrides<{
		[key: string]: FxIdDomainSettingsPublicWebClientConfigOptionsLegalContactsValue;
	}>(portalConfig, "__no_such_path__", "LegalContacts", adapter, gameConfig);

	const locale = i18n.resolvedLanguage;
	const localeLegalContacts = legalContacts == null || locale == null ? undefined : legalContacts[locale];

	const currentPpUrl =
		getValueFromConfigWithOverrides<string>(portalConfig, "ExternalPpUrl", "ExternalPpUrl", adapter, gameConfig) ??
		gameConfig?.PpUrl;

	const currentPpUrlMethod =
		getValueFromConfigWithOverrides<FxIdDomainSettingsUrlOpenMethod>(
			portalConfig,
			"PpUrlOpenMethod",
			"PpUrlOpenMethod",
			adapter,
			gameConfig
		) ?? gameConfig?.PpUrlOpenMethod;

	let pp:
		| {
				url: string;
				openMethod: FxIdDomainSettingsUrlOpenMethod;
		  }
		| undefined = undefined;

	if (currentPpUrl != null) {
		pp = {
			url: currentPpUrl,
			openMethod: currentPpUrlMethod ?? FxIdDomainSettingsUrlOpenMethod.Unspecified
		};
	}

	const currentTosUrl =
		getValueFromConfigWithOverrides<string>(
			portalConfig,
			"ExternalTosUrl",
			"ExternalTosUrl",
			adapter,
			gameConfig
		) ?? gameConfig?.TosUrl;

	const currentTosUrlOpenMethod =
		getValueFromConfigWithOverrides<FxIdDomainSettingsUrlOpenMethod>(
			portalConfig,
			"TosUrlOpenMethod",
			"TosUrlOpenMethod",
			adapter,
			gameConfig
		) ?? gameConfig?.TosUrlOpenMethod;

	let tos:
		| {
				url: string;
				openMethod: FxIdDomainSettingsUrlOpenMethod;
		  }
		| undefined = undefined;

	if (currentTosUrl != null) {
		tos = {
			url: currentTosUrl,
			openMethod: currentTosUrlOpenMethod ?? FxIdDomainSettingsUrlOpenMethod.Unspecified
		};
	}

	return { tos, pp, legalContacts, localeLegalContacts };
}
