/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    CreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    UpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    StartMenuName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    LaunchFile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    InstallerFile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    LaunchParams?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    ConfigurationBucket?: any | null;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    Torrent?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent | null;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    HttpDownload?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    ManifestUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration
     */
    ManifestSha256?: string | null;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "CreatedAt" in value;
    isInstance = isInstance && "UpdatedAt" in value;

    return isInstance;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CreatedAt': (new Date(json['createdAt'])),
        'UpdatedAt': (new Date(json['updatedAt'])),
        'Name': !exists(json, 'name') ? undefined : json['name'],
        'StartMenuName': !exists(json, 'startMenuName') ? undefined : json['startMenuName'],
        'LaunchFile': !exists(json, 'launchFile') ? undefined : json['launchFile'],
        'InstallerFile': !exists(json, 'installerFile') ? undefined : json['installerFile'],
        'LaunchParams': !exists(json, 'launchParams') ? undefined : json['launchParams'],
        'ConfigurationBucket': !exists(json, 'configurationBucket') ? undefined : json['configurationBucket'],
        'Torrent': !exists(json, 'torrent') ? undefined : FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSON(json['torrent']),
        'HttpDownload': !exists(json, 'httpDownload') ? undefined : FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadFromJSON(json['httpDownload']),
        'ManifestUrl': !exists(json, 'manifestUrl') ? undefined : json['manifestUrl'],
        'ManifestSha256': !exists(json, 'manifestSha256') ? undefined : json['manifestSha256'],
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.CreatedAt.toISOString()),
        'updatedAt': (value.UpdatedAt.toISOString()),
        'name': value.Name,
        'startMenuName': value.StartMenuName,
        'launchFile': value.LaunchFile,
        'installerFile': value.InstallerFile,
        'launchParams': value.LaunchParams,
        'configurationBucket': value.ConfigurationBucket,
        'torrent': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentToJSON(value.Torrent),
        'httpDownload': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadToJSON(value.HttpDownload),
        'manifestUrl': value.ManifestUrl,
        'manifestSha256': value.ManifestSha256,
    };
}

