/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesFriendsGetFriendsResponseFriendData
 */
export interface FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    FriendAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    GameId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    Game: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    InThisGame: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    LastUpdatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponseFriendData
     */
    Status?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFriendsGetFriendsResponseFriendData interface.
 */
export function instanceOfFxIdWebFeaturesFriendsGetFriendsResponseFriendData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "FriendAccountId" in value;
    isInstance = isInstance && "GameId" in value;
    isInstance = isInstance && "Game" in value;
    isInstance = isInstance && "InThisGame" in value;
    isInstance = isInstance && "LastUpdatedAt" in value;

    return isInstance;
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSON(json: any): FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    return FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFriendsGetFriendsResponseFriendData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'FriendAccountId': json['FriendAccountId'],
        'GameId': json['GameId'],
        'Game': json['Game'],
        'InThisGame': json['InThisGame'],
        'LastUpdatedAt': (new Date(json['LastUpdatedAt'])),
        'Name': !exists(json, 'Name') ? undefined : json['Name'],
        'Status': !exists(json, 'Status') ? undefined : json['Status'],
    };
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFriendDataToJSON(value?: FxIdWebFeaturesFriendsGetFriendsResponseFriendData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FriendAccountId': value.FriendAccountId,
        'GameId': value.GameId,
        'Game': value.Game,
        'InThisGame': value.InThisGame,
        'LastUpdatedAt': (value.LastUpdatedAt.toISOString()),
        'Name': value.Name,
        'Status': value.Status,
    };
}

