import { IFxIdSdkAdapterStatEventRequest } from "./FxIdSdkBaseAdapter";

export const FxIdStatsUnspecified = "Unspecified" as const;
export const FxIdStatsTutorialStart = "TutorialStart" as const;
export const FxIdStatsTutorialCompleted = "TutorialCompleted" as const;
export const FxIdStatsTutorialSkip = "TutorialSkip" as const;
export const FxIdStatsTutorialStep = "TutorialStep" as const;

export type FxIdStatsMessageType =
	| typeof FxIdStatsUnspecified
	| typeof FxIdStatsTutorialStart
	| typeof FxIdStatsTutorialSkip
	| typeof FxIdStatsTutorialStep
	| typeof FxIdStatsTutorialCompleted;

export interface FxIdStatsMessage {
	type: FxIdStatsMessageType;
	event: IFxIdSdkAdapterStatEventRequest;
}

export class FxIdStats {
	public ProcessStat(stat: IFxIdSdkAdapterStatEventRequest) {
		const result: FxIdStatsMessage = { type: FxIdStatsUnspecified, event: stat };
		if (stat.section === "tutorial" && stat.type === "step" && stat.name === "start") {
			result.type = FxIdStatsTutorialStart;
		} else if (stat.section === "tutorial" && stat.type === "step" && stat.name === "completed") {
			result.type = FxIdStatsTutorialCompleted;
		} else if (stat.section === "tutorial" && stat.type === "step" && stat.name === "skip") {
			result.type = FxIdStatsTutorialSkip;
		} else if (stat.section === "tutorial" && stat.type === "step" && stat.name === "step") {
			result.type = FxIdStatsTutorialStep;
		}

		return result;
	}
}
