/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
     */
    Discord?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
     */
    Twitter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials
     */
    Facebook?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Discord': !exists(json, 'Discord') ? undefined : json['Discord'],
        'Twitter': !exists(json, 'Twitter') ? undefined : json['Twitter'],
        'Facebook': !exists(json, 'Facebook') ? undefined : json['Facebook'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Discord': value.Discord,
        'Twitter': value.Twitter,
        'Facebook': value.Facebook,
    };
}

