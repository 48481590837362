/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions
     */
    WebFlow: boolean;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "WebFlow" in value;

    return isInstance;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'WebFlow': json['WebFlow'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptionsToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'WebFlow': value.WebFlow,
    };
}

