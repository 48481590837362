import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { FxIdWebFeaturesAuthAuthenticateFromTokenResponse } from "../gen";
import OpenApiClient from "../OpenApiClient";

export const useLoginWithTempTokenMutation = (
	options?: Omit<UseMutationOptions<FxIdWebFeaturesAuthAuthenticateFromTokenResponse, unknown, string>, "mutationFn">
) => {
	return useMutation((token: string) => {
		return OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromTokenEndpoint({ TempToken: token });
	}, options);
};
