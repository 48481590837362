import React, { useEffect } from "react";

import { Navigate, Outlet, Route, Routes, useMatch } from "react-router-dom";

const WebPlayRouter = () => {
	useEffect(() => {
		document.body.classList.add("web-play");

		return () => {
			document.body.classList.remove("web-play");
		};
	}, []);

	return <Outlet />;
};

export default WebPlayRouter;
