/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType } from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';
import {
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON,
} from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromSocialResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    Token: string;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    AccountId: number;
    /**
     * 
     * @type {FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    AuthenticatedSocialIdentifierType: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromSocialResponse
     */
    AuthenticatedSocialIdentifier: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromSocialResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromSocialResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Token" in value;
    isInstance = isInstance && "AccountId" in value;
    isInstance = isInstance && "AuthenticatedSocialIdentifierType" in value;
    isInstance = isInstance && "AuthenticatedSocialIdentifier" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    return FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromSocialResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'AccountId': json['AccountId'],
        'AuthenticatedSocialIdentifierType': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON(json['AuthenticatedSocialIdentifierType']),
        'AuthenticatedSocialIdentifier': json['AuthenticatedSocialIdentifier'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromSocialResponseToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromSocialResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Token': value.Token,
        'AccountId': value.AccountId,
        'AuthenticatedSocialIdentifierType': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON(value.AuthenticatedSocialIdentifierType),
        'AuthenticatedSocialIdentifier': value.AuthenticatedSocialIdentifier,
    };
}

