import { FxIdWebFeaturesPlayPublicDataBase, instanceOfFxIdWebFeaturesPlayPublicDataBase } from "../../Api/gen";

export const getPublicConfigFromMeta = (
	meta?: Record<string, unknown>
): FxIdWebFeaturesPlayPublicDataBase | undefined => {
	if (
		typeof meta === "object" &&
		meta !== null &&
		"publicConfig" in meta &&
		typeof meta.publicConfig === "object" &&
		meta.publicConfig !== null &&
		instanceOfFxIdWebFeaturesPlayPublicDataBase(meta.publicConfig)
	)
		return meta.publicConfig as FxIdWebFeaturesPlayPublicDataBase;
};
