/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris } from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris';
import {
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisFromJSONTyped,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisToJSON,
} from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris';
import type { FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info } from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info';
import {
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoFromJSONTyped,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoToJSON,
} from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info';
import type { FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo } from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo';
import {
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoFromJSONTyped,
    FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoToJSON,
} from './FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo';
import type { FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress } from './FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress';
import {
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressFromJSONTyped,
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressToJSON,
} from './FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress';
import type { FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo } from './FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo';
import {
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoFromJSON,
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoFromJSONTyped,
    FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoToJSON,
} from './FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PaymentFormDataResponse
 */
export interface FxIdWebFeaturesStoreBank131PaymentFormDataResponse {
    /**
     * 
     * @type {FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataResponse
     */
    Bank131PaymentFormResultBank131Info: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131Info;
    /**
     * 
     * @type {FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataResponse
     */
    Bank131PaymentFormResultProductInfo: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfo;
    /**
     * 
     * @type {FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataResponse
     */
    Bank131PaymentFormRedirectUris: FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUris;
    /**
     * 
     * @type {FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataResponse
     */
    Address?: FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddress | null;
    /**
     * 
     * @type {FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo}
     * @memberof FxIdWebFeaturesStoreBank131PaymentFormDataResponse
     */
    Bank131PaymentFormResultCardInfo?: FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfo | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PaymentFormDataResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PaymentFormDataResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Bank131PaymentFormResultBank131Info" in value;
    isInstance = isInstance && "Bank131PaymentFormResultProductInfo" in value;
    isInstance = isInstance && "Bank131PaymentFormRedirectUris" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseFromJSON(json: any): FxIdWebFeaturesStoreBank131PaymentFormDataResponse {
    return FxIdWebFeaturesStoreBank131PaymentFormDataResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PaymentFormDataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Bank131PaymentFormResultBank131Info': FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoFromJSON(json['Bank131PaymentFormResultBank131Info']),
        'Bank131PaymentFormResultProductInfo': FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoFromJSON(json['Bank131PaymentFormResultProductInfo']),
        'Bank131PaymentFormRedirectUris': FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisFromJSON(json['Bank131PaymentFormRedirectUris']),
        'Address': !exists(json, 'Address') ? undefined : FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressFromJSON(json['Address']),
        'Bank131PaymentFormResultCardInfo': !exists(json, 'Bank131PaymentFormResultCardInfo') ? undefined : FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoFromJSON(json['Bank131PaymentFormResultCardInfo']),
    };
}

export function FxIdWebFeaturesStoreBank131PaymentFormDataResponseToJSON(value?: FxIdWebFeaturesStoreBank131PaymentFormDataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Bank131PaymentFormResultBank131Info': FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultBank131InfoToJSON(value.Bank131PaymentFormResultBank131Info),
        'Bank131PaymentFormResultProductInfo': FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormResultProductInfoToJSON(value.Bank131PaymentFormResultProductInfo),
        'Bank131PaymentFormRedirectUris': FxIdWebFeaturesStoreBank131PaymentFormDataBank131PaymentFormRedirectUrisToJSON(value.Bank131PaymentFormRedirectUris),
        'Address': FxIdWebFeaturesStoreBank131PaymentFormDataResponseAddressToJSON(value.Address),
        'Bank131PaymentFormResultCardInfo': FxIdWebFeaturesStoreBank131PaymentFormDataResponseBank131PaymentFormResultCardInfoToJSON(value.Bank131PaymentFormResultCardInfo),
    };
}

