/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPortalPublicWebConfigOptions } from './FxIdDomainSettingsPortalPublicWebConfigOptions';
import {
    FxIdDomainSettingsPortalPublicWebConfigOptionsFromJSON,
    FxIdDomainSettingsPortalPublicWebConfigOptionsFromJSONTyped,
    FxIdDomainSettingsPortalPublicWebConfigOptionsToJSON,
} from './FxIdDomainSettingsPortalPublicWebConfigOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesChooseGamePortalConfig
 */
export interface FxIdWebFeaturesGamesChooseGamePortalConfig {
    /**
     * 
     * @type {FxIdDomainSettingsPortalPublicWebConfigOptions}
     * @memberof FxIdWebFeaturesGamesChooseGamePortalConfig
     */
    PortalPublicWebConfig: FxIdDomainSettingsPortalPublicWebConfigOptions;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesChooseGamePortalConfig interface.
 */
export function instanceOfFxIdWebFeaturesGamesChooseGamePortalConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PortalPublicWebConfig" in value;

    return isInstance;
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigFromJSON(json: any): FxIdWebFeaturesGamesChooseGamePortalConfig {
    return FxIdWebFeaturesGamesChooseGamePortalConfigFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGamePortalConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PortalPublicWebConfig': FxIdDomainSettingsPortalPublicWebConfigOptionsFromJSON(json['PortalPublicWebConfig']),
    };
}

export function FxIdWebFeaturesGamesChooseGamePortalConfigToJSON(value?: FxIdWebFeaturesGamesChooseGamePortalConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PortalPublicWebConfig': FxIdDomainSettingsPortalPublicWebConfigOptionsToJSON(value.PortalPublicWebConfig),
    };
}

