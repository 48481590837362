/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsUnityOptionsDistributionComponent } from './FxIdDomainSettingsUnityOptionsDistributionComponent';
import {
    FxIdDomainSettingsUnityOptionsDistributionComponentFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionComponentFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionComponentToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionComponent';
import type { FxIdDomainSettingsUnityOptionsDistributionForceCompression } from './FxIdDomainSettingsUnityOptionsDistributionForceCompression';
import {
    FxIdDomainSettingsUnityOptionsDistributionForceCompressionFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionForceCompressionFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionForceCompressionToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionForceCompression';
import type { FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions } from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions';
import {
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsToJSON,
} from './FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptionsDistribution
 */
export interface FxIdDomainSettingsUnityOptionsDistribution {
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionForceCompression}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    ForceCompression: FxIdDomainSettingsUnityOptionsDistributionForceCompression;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    LoaderFileName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    DataFileName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    CodeFileName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    MobileDataFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    FrameworkFileName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    SymbolsFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    MemoryFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    StreamingAssetsUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    StreamingAssetsMobileUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    WebGlContextOptions: FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptions;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    RequestPointerLocking: boolean;
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistributionComponent}
     * @memberof FxIdDomainSettingsUnityOptionsDistribution
     */
    Component?: FxIdDomainSettingsUnityOptionsDistributionComponent | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptionsDistribution interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptionsDistribution(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ForceCompression" in value;
    isInstance = isInstance && "LoaderFileName" in value;
    isInstance = isInstance && "DataFileName" in value;
    isInstance = isInstance && "CodeFileName" in value;
    isInstance = isInstance && "FrameworkFileName" in value;
    isInstance = isInstance && "WebGlContextOptions" in value;
    isInstance = isInstance && "RequestPointerLocking" in value;

    return isInstance;
}

export function FxIdDomainSettingsUnityOptionsDistributionFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistribution {
    return FxIdDomainSettingsUnityOptionsDistributionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistribution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ForceCompression': FxIdDomainSettingsUnityOptionsDistributionForceCompressionFromJSON(json['ForceCompression']),
        'LoaderFileName': json['LoaderFileName'],
        'DataFileName': json['DataFileName'],
        'CodeFileName': json['CodeFileName'],
        'MobileDataFileName': !exists(json, 'MobileDataFileName') ? undefined : json['MobileDataFileName'],
        'FrameworkFileName': json['FrameworkFileName'],
        'SymbolsFileName': !exists(json, 'SymbolsFileName') ? undefined : json['SymbolsFileName'],
        'MemoryFileName': !exists(json, 'MemoryFileName') ? undefined : json['MemoryFileName'],
        'StreamingAssetsUrl': !exists(json, 'StreamingAssetsUrl') ? undefined : json['StreamingAssetsUrl'],
        'StreamingAssetsMobileUrl': !exists(json, 'StreamingAssetsMobileUrl') ? undefined : json['StreamingAssetsMobileUrl'],
        'WebGlContextOptions': FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsFromJSON(json['WebGlContextOptions']),
        'RequestPointerLocking': json['RequestPointerLocking'],
        'Component': !exists(json, 'Component') ? undefined : FxIdDomainSettingsUnityOptionsDistributionComponentFromJSON(json['Component']),
    };
}

export function FxIdDomainSettingsUnityOptionsDistributionToJSON(value?: FxIdDomainSettingsUnityOptionsDistribution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ForceCompression': FxIdDomainSettingsUnityOptionsDistributionForceCompressionToJSON(value.ForceCompression),
        'LoaderFileName': value.LoaderFileName,
        'DataFileName': value.DataFileName,
        'CodeFileName': value.CodeFileName,
        'MobileDataFileName': value.MobileDataFileName,
        'FrameworkFileName': value.FrameworkFileName,
        'SymbolsFileName': value.SymbolsFileName,
        'MemoryFileName': value.MemoryFileName,
        'StreamingAssetsUrl': value.StreamingAssetsUrl,
        'StreamingAssetsMobileUrl': value.StreamingAssetsMobileUrl,
        'WebGlContextOptions': FxIdDomainSettingsUnityOptionsDistributionWebGlContextOptionsToJSON(value.WebGlContextOptions),
        'RequestPointerLocking': value.RequestPointerLocking,
        'Component': FxIdDomainSettingsUnityOptionsDistributionComponentToJSON(value.Component),
    };
}

