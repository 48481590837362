/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptions;

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoKongregateLoginOptions);
    }

    return {};
}

