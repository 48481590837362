/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainEntitiesNewsPriority {
    Unspecified = 0,
    Low = 1,
    Update = 2,
    Info = 3,
    News = 4,
    Alert = 5,
    Critical = 6,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdDomainEntitiesNewsPriorityFromJSON(json: any): FxIdDomainEntitiesNewsPriority {
    return FxIdDomainEntitiesNewsPriorityFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesNewsPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesNewsPriority {
    return json as FxIdDomainEntitiesNewsPriority;
}

export function FxIdDomainEntitiesNewsPriorityToJSON(value?: FxIdDomainEntitiesNewsPriority | null): any {
    return value as any;
}

