/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform {
    Unspecified = 'Unspecified',
    Custom = 'Custom',
    Android = 'Android',
    AndroidX86 = 'AndroidX86',
    Amazon = 'Amazon',
    DigiCaviar = 'DigiCaviar',
    Ios = 'Ios',
    WindowsX64 = 'WindowsX64',
    SteamX64 = 'SteamX64',
    RuStore = 'RuStore',
    AppBazar = 'AppBazar',
    MailRuX64 = 'MailRuX64',
    UnknownDefaultOpenApi = '11184809'
}


export function FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSON(json: any): FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform {
    return FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform {
    return json as FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform;
}

export function FtlSharedGeneratedApiFtlDomainEnumsReleasePlatformToJSON(value?: FtlSharedGeneratedApiFtlDomainEnumsReleasePlatform | null): any {
    return value as any;
}

