import * as jsonpath from "jsonpath";
import vkBridge from "@vkontakte/vk-bridge";
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents } from "@app/Api/gen";
import { RequestPropsMap } from "@vkontakte/vk-bridge/dist/types/src/types/data";
import { MethodGameStarted, MethodGameStopped, MethodLoadingReady } from "@app/SDK/FxIdMessage";

type Matcher = { [key: string]: unknown };

export interface VkComMapper {
	method: "VKWebAppConversionHit" | "VKWebAppTrackEvent" | unknown;
}

/**
 * https://dev.vk.com/ru/bridge/VKWebAppConversionHit
 */
export interface VkComMapperVKWebAppConversionHit extends VkComMapper {
	pixel_code: string;
	conversion_event: string;
	conversion_value: string;
}

export interface FBInstantMapper {
	[key: string]: string | number | undefined;
}

export interface FbInstantLogEventMapper extends FBInstantMapper {
	name: string;
	valueToSum?: number;
}

/**
 * https://dev.vk.com/ru/bridge/VKWebAppTrackEvent
 */
export interface VkComMapperVKWebAppTrackEvent extends VkComMapper {
	event_name: string;
	custom_user_id?: string;
}

export interface GTagMapper {
	[key: string]: string;
}

export interface FxIdSdkMapper {
	method: string;
}

const SendToVkCom = "vkcom" as const;
const SendToGTag = "gtag" as const;
const SendToFxIdSdk = "fxidsdk" as const;
const SendToFbInstantLogEvent = "fbinstantlog" as const;

export interface ConfigSection {
	sendTo: Array<typeof SendToVkCom | typeof SendToGTag | typeof SendToFxIdSdk>;
	mapper: { vkcom?: VkComMapper; gtag?: GTagMapper; fxidsdk?: FxIdSdkMapper };
	matcher: Matcher;
}

type Event = unknown;

export class FxIdEventProcessor {
	private readonly config: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents;

	constructor(config: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents) {
		this.config = config;
	}

	private matchesConfig(event: Event, matcher: Matcher): boolean {
		for (const key in matcher) {
			if (Object.prototype.hasOwnProperty.call(matcher, key)) {
				if (key.startsWith("$")) {
					const jsonPath = key;
					const expectedValue = matcher[key];
					const actualValue = jsonpath.query(event, jsonPath);

					if (actualValue.length === 0 || actualValue[0] !== expectedValue) {
						return false;
					}
				} else {
					const expectedValue = matcher[key];
					const actualValue = (event as never)[key];

					if (actualValue !== expectedValue) {
						return false;
					}
				}
			}
		}
		return true;
	}

	public findMatchingConfigSection(event: Event): string | null {
		for (const sectionKey in this.config.Sections) {
			if (Object.prototype.hasOwnProperty.call(this.config.Sections, sectionKey)) {
				const section = this.config.Sections[sectionKey];
				if (this.matchesConfig(event, section.Matcher)) {
					return sectionKey;
				}
			}
		}
		return null;
	}

	public async processEvent(event: Event) {
		const matchedSectionKey = this.findMatchingConfigSection(event);
		if (!matchedSectionKey) {
			if (import.meta.env.DEV) {
				log.warn("No matching config section found for the event.");
			}
			return;
		}

		const matchedSection = this.config.Sections[matchedSectionKey];

		await Promise.all(
			matchedSection.SendTo.map((destination) => {
				if (destination === SendToVkCom) {
					return this.processVkCom(event, matchedSection.Mapper[SendToVkCom]);
				} else if (destination === SendToGTag) {
					return this.processGTag(event, matchedSection.Mapper[SendToGTag]);
				} else if (destination === SendToFxIdSdk) {
					return this.processApi(event, matchedSection.Mapper[SendToFxIdSdk]);
				} else if (destination === SendToFbInstantLogEvent) {
					return this.processFbInstant(event, matchedSection.Mapper[SendToFxIdSdk]);
				}
			})
		);
	}

	private async processFbInstant(event: Event, mapper?: FBInstantMapper) {
		if (!mapper) {
			return;
		}

		if (FBInstant == null || FBInstant.logEvent == null) {
			return;
		}

		const fbInstantMapper = mapper as FbInstantLogEventMapper;

		const eventNamePath = fbInstantMapper.name;
		const eventName = jsonpath.query(event, eventNamePath)[0];
		const valueToSumPath = fbInstantMapper.valueToSum;
		const valueToSum: number | undefined =
			valueToSumPath == null ? undefined : parseFloat(jsonpath.query(event, valueToSumPath.toString())[0]);

		const fbInstantLogParameters: { [key: string]: string } = {};
		for (const key in mapper) {
			if (Object.prototype.hasOwnProperty.call(mapper, key)) {
				const valuePath = mapper[key];
				if (valuePath != null) {
					fbInstantLogParameters[key] = jsonpath.query(event, valuePath.toString())[0];
				}
			}
		}
		log.info("Sending to fbinstant %s (%s): %o", eventName, valueToSum, fbInstantLogParameters);
		FBInstant.logEvent(eventName, valueToSum, fbInstantLogParameters);
	}

	private async processVkCom(event: Event, mapper?: VkComMapper) {
		if (!mapper) {
			return;
		}

		if (vkBridge == null) {
			return;
		}

		if (mapper.method == "VKWebAppConversionHit") {
			const vkConvMapper = mapper as VkComMapperVKWebAppConversionHit;
			const pixelCode = vkConvMapper.pixel_code;
			const conversionEvent = vkConvMapper.conversion_event;
			const conversionValuePath = vkConvMapper.conversion_value;
			const conversionValue = parseFloat(jsonpath.query(event, conversionValuePath)[0]);
			const vkConversionEvent = {
				pixel_code: pixelCode,
				conversion_event: conversionEvent,
				conversion_value: conversionValue
			};
			log.info("Sending to vkcom: %o", vkConversionEvent);
			await vkBridge.send("VKWebAppConversionHit", vkConversionEvent);
			return;
		}

		if (mapper.method == "VKWebAppTrackEvent") {
			const vkConvMapper = mapper as VkComMapperVKWebAppTrackEvent;
			const vkConversionEvent = {
				event_name: vkConvMapper.event_name,
				custom_user_id: vkConvMapper.custom_user_id
			};
			log.info("Sending to vkcom: %o", vkConversionEvent);
			// NOTE: В версии "^2.15.1" нет VKWebAppTrackEvent но на сайте есть
			await vkBridge.send("VKWebAppTrackEvent" as keyof RequestPropsMap, vkConversionEvent as any);
			return;
		}

		if (!import.meta.env.PROD) {
			log.warn("Unsupported vk method for mapping: %s", mapper.method);
		}
	}

	private processGTag(event: Event, mapper?: GTagMapper): Promise<void> {
		if (window.dataLayer == null) {
			return Promise.resolve();
		}

		if (mapper) {
			const gtagEvent: { [key: string]: unknown } = {};
			for (const key in mapper) {
				if (Object.prototype.hasOwnProperty.call(mapper, key)) {
					const valuePath = mapper[key];
					gtagEvent[key] = jsonpath.query(event, valuePath)[0];
				}
			}
			log.info("Sending to gtag: %o", gtagEvent);

			// event = gtagEvent;
			window.dataLayer.push(gtagEvent);
		} else {
			log.info("Sending to gtag: %o", event);
			window.dataLayer.push(event);
		}

		return Promise.resolve();
	}

	private processApi(event: Event, mapper?: FxIdSdkMapper) {
		if (window.FxIdSdk == null) {
			return;
		}

		const sdk = window.FxIdSdk;

		if (!mapper) {
			return;
		}

		switch (mapper.method) {
			case MethodLoadingReady:
				void sdk.LoadingReady();
				break;
			case MethodGameStarted:
				void sdk.GameStarted();
				break;
			case MethodGameStopped:
				void sdk.GameStopped();
				break;
		}
	}
}
