/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
 */
export interface FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    AppId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    AuthKey: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    GameSid: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    Guest: boolean;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions
     */
    PlayerId: string;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions interface.
 */
export function instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AppId" in value;
    isInstance = isInstance && "AuthKey" in value;
    isInstance = isInstance && "GameSid" in value;
    isInstance = isInstance && "Guest" in value;
    isInstance = isInstance && "PlayerId" in value;

    return isInstance;
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AppId': json['AppId'],
        'AuthKey': json['AuthKey'],
        'GameSid': json['GameSid'],
        'Guest': json['Guest'],
        'PlayerId': json['PlayerId'],
    };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptionsToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoAbsoluteGamesLoginOptionsAbsoluteGamesLoginUserAuthOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AppId': value.AppId,
        'AuthKey': value.AuthKey,
        'GameSid': value.GameSid,
        'Guest': value.Guest,
        'PlayerId': value.PlayerId,
    };
}

