/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions,
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides = FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverrides | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptionsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsAvailabilityOverridesOptions);
    }

    return {};
}

