/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig,
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfigToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthConfig);
    }

    return {};
}

