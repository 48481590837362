/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsDimensions
 */
export interface FxIdDomainSettingsDimensions {
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainSettingsDimensions
     */
    Width: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainSettingsDimensions
     */
    Height: number;
}

/**
 * Check if a given object implements the FxIdDomainSettingsDimensions interface.
 */
export function instanceOfFxIdDomainSettingsDimensions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Width" in value;
    isInstance = isInstance && "Height" in value;

    return isInstance;
}

export function FxIdDomainSettingsDimensionsFromJSON(json: any): FxIdDomainSettingsDimensions {
    return FxIdDomainSettingsDimensionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsDimensionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsDimensions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Width': json['Width'],
        'Height': json['Height'],
    };
}

export function FxIdDomainSettingsDimensionsToJSON(value?: FxIdDomainSettingsDimensions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Width': value.Width,
        'Height': value.Height,
    };
}

