/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions,
    instanceOfFxIdWebFeaturesPlayPublicDataBaseStatisticsOptions,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsToJSON,
} from './FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions';

/**
 * @type FxIdWebFeaturesPlayPublicDataBaseStatistics
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPublicDataBaseStatistics = FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions;

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBaseStatistics {
    return FxIdWebFeaturesPlayPublicDataBaseStatisticsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBaseStatistics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPublicDataBaseStatisticsToJSON(value?: FxIdWebFeaturesPlayPublicDataBaseStatistics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPublicDataBaseStatisticsOptions(value)) {
        return FxIdWebFeaturesPlayPublicDataBaseStatisticsOptionsToJSON(value as FxIdWebFeaturesPlayPublicDataBaseStatisticsOptions);
    }

    return {};
}

