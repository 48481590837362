/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsFirebase } from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebase';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsFirebase';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLogos } from './FxIdDomainSettingsPublicWebClientConfigOptionsLogos';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLogosToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLogos';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsCompanyBrandingConfig
 */
export interface FxIdDomainSettingsCompanyBrandingConfig {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    CompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    BrandName: string;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLogos}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    Logos: FxIdDomainSettingsPublicWebClientConfigOptionsLogos;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    HeaderElementType: string;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsFirebase}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    FirebaseOptions?: FxIdDomainSettingsPublicWebClientConfigOptionsFirebase | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    ThemeType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    ExternalTosUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    ExternalPpUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     */
    Auth: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig;
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig>}
     * @memberof FxIdDomainSettingsCompanyBrandingConfig
     * @deprecated
     */
    Adapters?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfig> | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsCompanyBrandingConfig interface.
 */
export function instanceOfFxIdDomainSettingsCompanyBrandingConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "CompanyName" in value;
    isInstance = isInstance && "BrandName" in value;
    isInstance = isInstance && "Logos" in value;
    isInstance = isInstance && "HeaderElementType" in value;
    isInstance = isInstance && "Auth" in value;

    return isInstance;
}

export function FxIdDomainSettingsCompanyBrandingConfigFromJSON(json: any): FxIdDomainSettingsCompanyBrandingConfig {
    return FxIdDomainSettingsCompanyBrandingConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsCompanyBrandingConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsCompanyBrandingConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'CompanyName': json['CompanyName'],
        'BrandName': json['BrandName'],
        'Logos': FxIdDomainSettingsPublicWebClientConfigOptionsLogosFromJSON(json['Logos']),
        'HeaderElementType': json['HeaderElementType'],
        'FirebaseOptions': !exists(json, 'FirebaseOptions') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseFromJSON(json['FirebaseOptions']),
        'ThemeType': !exists(json, 'ThemeType') ? undefined : json['ThemeType'],
        'ExternalTosUrl': !exists(json, 'ExternalTosUrl') ? undefined : json['ExternalTosUrl'],
        'ExternalPpUrl': !exists(json, 'ExternalPpUrl') ? undefined : json['ExternalPpUrl'],
        'Auth': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON(json['Auth']),
        'Adapters': !exists(json, 'Adapters') ? undefined : (json['Adapters'] === null ? null : (json['Adapters'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigFromJSON)),
    };
}

export function FxIdDomainSettingsCompanyBrandingConfigToJSON(value?: FxIdDomainSettingsCompanyBrandingConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'CompanyName': value.CompanyName,
        'BrandName': value.BrandName,
        'Logos': FxIdDomainSettingsPublicWebClientConfigOptionsLogosToJSON(value.Logos),
        'HeaderElementType': value.HeaderElementType,
        'FirebaseOptions': FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseToJSON(value.FirebaseOptions),
        'ThemeType': value.ThemeType,
        'ExternalTosUrl': value.ExternalTosUrl,
        'ExternalPpUrl': value.ExternalPpUrl,
        'Auth': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON(value.Auth),
        'Adapters': value.Adapters === undefined ? undefined : (value.Adapters === null ? null : (value.Adapters as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsAdapterConfigToJSON)),
    };
}

