/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainStoreEnumsStoreProductType {
    None = 'None',
    Redmin = 'Redmin',
    Coin = 'Coin',
    Subscription = 'Subscription',
    UnknownDefaultOpenApi = '11184809'
}


export function FxIdDomainStoreEnumsStoreProductTypeFromJSON(json: any): FxIdDomainStoreEnumsStoreProductType {
    return FxIdDomainStoreEnumsStoreProductTypeFromJSONTyped(json, false);
}

export function FxIdDomainStoreEnumsStoreProductTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainStoreEnumsStoreProductType {
    return json as FxIdDomainStoreEnumsStoreProductType;
}

export function FxIdDomainStoreEnumsStoreProductTypeToJSON(value?: FxIdDomainStoreEnumsStoreProductType | null): any {
    return value as any;
}

