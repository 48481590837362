import { QueryFunctionContext, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { FxIdDomainStoreEnumsSupportedWebPublishingPlatform, FxIdWebFeaturesPlayPublicDataBase } from "../gen";
import OpenApiClient from "../OpenApiClient";
import { queryClient } from "../../App";

type GamePublicConfigQueryOptions = Omit<
	UseQueryOptions<FxIdWebFeaturesPlayPublicDataBase, unknown, FxIdWebFeaturesPlayPublicDataBase, QueryKey>,
	"queryKey" | "queryFn"
> & {
	gameId: string;
	platform?: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
};

type QueryKey = readonly [
	key: "publicConfig",
	gameId: string,
	platform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform | undefined
];

const getGamePublicConfig = (ctx: QueryFunctionContext) => {
	const queryKey = ctx.queryKey as QueryKey;
	const [, gameId, platform] = queryKey;
	return OpenApiClient.PublicGameConfigApi.fxIdWebFeaturesPlayGamePublicConfigEndpoint(
		gameId,
		platform ?? FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId
	);
};

export const useGamePublicConfigQuery = (options: GamePublicConfigQueryOptions) => {
	const { gameId, platform, ...queryOptions } = options;
	return useQuery(["publicConfig", gameId, platform] as const, getGamePublicConfig, queryOptions);
};

export const getQueriedGamePublicConfig = (params: Pick<GamePublicConfigQueryOptions, "gameId" | "platform">) => {
	return queryClient.ensureQueryData({
		queryKey: ["publicConfig", params.gameId, params.platform],
		queryFn: getGamePublicConfig
	});
};
