/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse
 */
export interface FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult}
     * @memberof FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse
     */
    Manifest: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResult;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse
     */
    AvailableBranches: Array<string>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse interface.
 */
export function instanceOfFxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Manifest" in value;
    isInstance = isInstance && "AvailableBranches" in value;

    return isInstance;
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSON(json: any): FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    return FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Manifest': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultFromJSON(json['Manifest']),
        'AvailableBranches': json['AvailableBranches'],
    };
}

export function FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponseToJSON(value?: FxIdWebFeaturesFtlIntegrationLauncherLauncherManifestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Manifest': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultToJSON(value.Manifest),
        'AvailableBranches': value.AvailableBranches,
    };
}

