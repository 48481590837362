/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdWebFeaturesProfileFetchFxIdGameConnectionRequest,
  FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse,
  FxIdWebFeaturesProfileGetResponse,
  FxIdWebFeaturesProfileUpdateMarketingEnabledRequest,
  FxIdWebFeaturesProfileUpdateProfileSettingsRequest,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionRequestFromJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionRequestToJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSON,
    FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseToJSON,
    FxIdWebFeaturesProfileGetResponseFromJSON,
    FxIdWebFeaturesProfileGetResponseToJSON,
    FxIdWebFeaturesProfileUpdateMarketingEnabledRequestFromJSON,
    FxIdWebFeaturesProfileUpdateMarketingEnabledRequestToJSON,
    FxIdWebFeaturesProfileUpdateProfileSettingsRequestFromJSON,
    FxIdWebFeaturesProfileUpdateProfileSettingsRequestToJSON,
} from '../models';

export interface FxIdWebFeaturesProfileFetchFxIdGameConnectionEndpointRequest {
    fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest: FxIdWebFeaturesProfileFetchFxIdGameConnectionRequest;
}

export interface FxIdWebFeaturesProfileUpdateMarketingEnabledEndpointRequest {
    fxIdWebFeaturesProfileUpdateMarketingEnabledRequest: FxIdWebFeaturesProfileUpdateMarketingEnabledRequest;
}

export interface FxIdWebFeaturesProfileUpdateProfileSettingsEndpointRequest {
    fxIdWebFeaturesProfileUpdateProfileSettingsRequest: FxIdWebFeaturesProfileUpdateProfileSettingsRequest;
}

/**
 * 
 */
export class ProfileApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesProfileFetchFxIdGameConnectionEndpointRaw(requestParameters: FxIdWebFeaturesProfileFetchFxIdGameConnectionEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse>> {
        if (requestParameters.fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest === null || requestParameters.fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest','Required parameter requestParameters.fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest was null or undefined when calling fxIdWebFeaturesProfileFetchFxIdGameConnectionEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/fetch_fxid_game_connection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesProfileFetchFxIdGameConnectionRequestToJSON(requestParameters.fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesProfileFetchFxIdGameConnectionResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesProfileFetchFxIdGameConnectionEndpoint(fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest: FxIdWebFeaturesProfileFetchFxIdGameConnectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesProfileFetchFxIdGameConnectionResponse> {
        const response = await this.fxIdWebFeaturesProfileFetchFxIdGameConnectionEndpointRaw({ fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest: fxIdWebFeaturesProfileFetchFxIdGameConnectionRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesProfileGetEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesProfileGetResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesProfileGetResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesProfileGetEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesProfileGetResponse> {
        const response = await this.fxIdWebFeaturesProfileGetEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesProfileUpdateMarketingEnabledEndpointRaw(requestParameters: FxIdWebFeaturesProfileUpdateMarketingEnabledEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.fxIdWebFeaturesProfileUpdateMarketingEnabledRequest === null || requestParameters.fxIdWebFeaturesProfileUpdateMarketingEnabledRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesProfileUpdateMarketingEnabledRequest','Required parameter requestParameters.fxIdWebFeaturesProfileUpdateMarketingEnabledRequest was null or undefined when calling fxIdWebFeaturesProfileUpdateMarketingEnabledEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/marketing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesProfileUpdateMarketingEnabledRequestToJSON(requestParameters.fxIdWebFeaturesProfileUpdateMarketingEnabledRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesProfileUpdateMarketingEnabledEndpoint(fxIdWebFeaturesProfileUpdateMarketingEnabledRequest: FxIdWebFeaturesProfileUpdateMarketingEnabledRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesProfileUpdateMarketingEnabledEndpointRaw({ fxIdWebFeaturesProfileUpdateMarketingEnabledRequest: fxIdWebFeaturesProfileUpdateMarketingEnabledRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesProfileUpdateProfileSettingsEndpointRaw(requestParameters: FxIdWebFeaturesProfileUpdateProfileSettingsEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.fxIdWebFeaturesProfileUpdateProfileSettingsRequest === null || requestParameters.fxIdWebFeaturesProfileUpdateProfileSettingsRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesProfileUpdateProfileSettingsRequest','Required parameter requestParameters.fxIdWebFeaturesProfileUpdateProfileSettingsRequest was null or undefined when calling fxIdWebFeaturesProfileUpdateProfileSettingsEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/profile/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesProfileUpdateProfileSettingsRequestToJSON(requestParameters.fxIdWebFeaturesProfileUpdateProfileSettingsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesProfileUpdateProfileSettingsEndpoint(fxIdWebFeaturesProfileUpdateProfileSettingsRequest: FxIdWebFeaturesProfileUpdateProfileSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesProfileUpdateProfileSettingsEndpointRaw({ fxIdWebFeaturesProfileUpdateProfileSettingsRequest: fxIdWebFeaturesProfileUpdateProfileSettingsRequest }, initOverrides);
        return await response.value();
    }

}
