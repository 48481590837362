/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData';
import {
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSON,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData
     */
    AwGameData?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AwGameData': !exists(json, 'AwGameData') ? undefined : FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataFromJSON(json['AwGameData']),
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataToJSON(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AwGameData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthGameDataAwGameDataToJSON(value.AwGameData),
    };
}

