/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
 */
export interface FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
     */
    RedirectUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
     */
    OrderPublicId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData
     */
    MerchantRedirectType?: string | null;
}

/**
 * Check if a given object implements the FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData interface.
 */
export function instanceOfFxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSON(json: any): FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    return FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSONTyped(json, false);
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'RedirectUri': !exists(json, 'redirect_uri') ? undefined : json['redirect_uri'],
        'OrderPublicId': !exists(json, 'order_public_id') ? undefined : json['order_public_id'],
        'MerchantRedirectType': !exists(json, 'merchant_redirect_type') ? undefined : json['merchant_redirect_type'],
    };
}

export function FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenDataToJSON(value?: FxIdApplicationSecurityFxIdTempTokenJwtFxIdTempTokenData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirect_uri': value.RedirectUri,
        'order_public_id': value.OrderPublicId,
        'merchant_redirect_type': value.MerchantRedirectType,
    };
}

