/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayGameUnityGameConfigOptions
 */
export interface FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameUnityGameConfigOptions
     */
    BaseUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayGameUnityGameConfigOptions
     */
    BaseBuildUrl: string;
    /**
     * 
     * @type {any}
     * @memberof FxIdWebFeaturesPlayGameUnityGameConfigOptions
     */
    UnityBuildJson?: any | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayGameUnityGameConfigOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayGameUnityGameConfigOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "BaseUrl" in value;
    isInstance = isInstance && "BaseBuildUrl" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSON(json: any): FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    return FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameUnityGameConfigOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'BaseUrl': json['BaseUrl'],
        'BaseBuildUrl': json['BaseBuildUrl'],
        'UnityBuildJson': !exists(json, 'UnityBuildJson') ? undefined : json['UnityBuildJson'],
    };
}

export function FxIdWebFeaturesPlayGameUnityGameConfigOptionsToJSON(value?: FxIdWebFeaturesPlayGameUnityGameConfigOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'BaseUrl': value.BaseUrl,
        'BaseBuildUrl': value.BaseBuildUrl,
        'UnityBuildJson': value.UnityBuildJson,
    };
}

