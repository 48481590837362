import { Card, CardProps, Group, Loader } from "@mantine/core";
import React from "react";

import styles from "./index.module.scss";

export const LoadingIndicator = (props: { cardProps?: Omit<CardProps, "children">; children?: React.ReactNode }) => {
	const { cardProps, children } = props;
	return (
		<Card p="sm" {...cardProps} className={`${styles["loading-container"]} ${cardProps?.className ?? ""}`}>
			<Group>
				<Loader />
				{children}
			</Group>
		</Card>
	);
};
