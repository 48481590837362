/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial } from './FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial';
import {
    FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSON,
    FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSONTyped,
    FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialToJSON,
} from './FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial';
import type { FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded } from './FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded';
import {
    FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedFromJSON,
    FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedFromJSONTyped,
    FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedToJSON,
} from './FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementFbInstantOptions
 */
export interface FxIdDomainSettingsAdvertisementFbInstantOptions {
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded}
     * @memberof FxIdDomainSettingsAdvertisementFbInstantOptions
     */
    Rewarded?: FxIdDomainSettingsAdvertisementFbInstantOptionsRewarded | null;
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial}
     * @memberof FxIdDomainSettingsAdvertisementFbInstantOptions
     */
    Interstitial?: FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementFbInstantOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementFbInstantOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementFbInstantOptions {
    return FxIdDomainSettingsAdvertisementFbInstantOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementFbInstantOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Rewarded': !exists(json, 'Rewarded') ? undefined : FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedFromJSON(json['Rewarded']),
        'Interstitial': !exists(json, 'Interstitial') ? undefined : FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSON(json['Interstitial']),
    };
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsToJSON(value?: FxIdDomainSettingsAdvertisementFbInstantOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Rewarded': FxIdDomainSettingsAdvertisementFbInstantOptionsRewardedToJSON(value.Rewarded),
        'Interstitial': FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialToJSON(value.Interstitial),
    };
}

