/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementYandexRewardedOptions
 */
export interface FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementYandexRewardedOptions
     */
    MobileBlockId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsAdvertisementYandexRewardedOptions
     */
    DesktopBlockId?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementYandexRewardedOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    return FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementYandexRewardedOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'MobileBlockId': !exists(json, 'MobileBlockId') ? undefined : json['MobileBlockId'],
        'DesktopBlockId': !exists(json, 'DesktopBlockId') ? undefined : json['DesktopBlockId'],
    };
}

export function FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSON(value?: FxIdDomainSettingsAdvertisementYandexRewardedOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MobileBlockId': value.MobileBlockId,
        'DesktopBlockId': value.DesktopBlockId,
    };
}

