import { Auth, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { getI18n } from "react-i18next";
import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { auth, firebaseInit, loginWithFirebaseUser } from "../../Api/Firebase";
import { FxIdWebFeaturesPlayPublicDataBase } from "../../Api/gen";
import OpenApiClient from "../../Api/OpenApiClient";
import * as Analytics from "../../Utils/Analytics";
import { FirebaseOptions } from "firebase/app";
import { result } from "lodash";
import { defaultLocale } from "@app/i18n";

const APP_ID_CONFIG = {
	FX: {
		// vk: import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "prod" ? 51548231 : 51548229
		vk: 51869032
	},
	RBW: {
		vk:
			import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "prod" ||
			import.meta.env.VITE_APP_ENV_OVERRIDE_NAME === "beta"
				? 7644205
				: 7644207
	}
};

type AuthOptions = {
	handleAuth: (options: AuthInitOptions) => void | Promise<void>;
	createInitOptions: (config?: FxIdWebFeaturesPlayPublicDataBase) => AuthInitOptions;
};

type Store = State & Actions;

type State = {
	auth?: Auth;
	authOptions: Map<string, AuthOptions>;
	emailLinkProvider: "fxid" | "firebase";
	isEmailAuthSuccessful: boolean;
};
type Actions = {
	updateAuthLanguage: (newLang: string) => void;
	initFirebase: (options: FirebaseOptions) => void;
};

export type AuthInitOptions = {
	marketingEnabled?: boolean;
	game: string;
	callback?: (success: boolean) => void;
} & ({ type: "Firebase" } | { type: "VK"; appId: number });

const getAuthOptions: (get: () => Store) => State["authOptions"] = (get) => {
	const providers: Array<[string, AuthOptions]> = [
		[
			"google",
			{
				handleAuth: async (options) => {
					const firebaseAuth = get().auth;
					if (options.type !== "Firebase" || !firebaseAuth)
						throw new Error("Incorrect init options passed to google authentication");
					const provider = new GoogleAuthProvider();
					provider.addScope("https://www.googleapis.com/auth/userinfo.email");
					const result = await signInWithPopup(firebaseAuth, provider);
					await loginWithFirebaseUser(options.game, result.user, options.marketingEnabled);
					void Analytics.site.login({ method: "google" });
				},
				createInitOptions: (config?: FxIdWebFeaturesPlayPublicDataBase) => ({
					type: "Firebase",
					game: config?.GameSystemName || ""
				})
			}
		],
		[
			"apple",
			{
				handleAuth: async (options) => {
					const firebaseAuth = get().auth;
					if (options.type !== "Firebase" || !firebaseAuth)
						throw new Error("Incorrect init options passed to apple authentication");
					const provider = new OAuthProvider("apple.com");
					provider.addScope("email");
					provider.setCustomParameters({
						locale: getI18n().resolvedLanguage ?? defaultLocale
					});
					const result = await signInWithPopup(firebaseAuth, provider);
					await loginWithFirebaseUser(options.game, result.user, options.marketingEnabled);
					void Analytics.site.login({ method: "apple" });
				},
				createInitOptions: (config?: FxIdWebFeaturesPlayPublicDataBase) => ({
					type: "Firebase",
					game: config?.GameSystemName || ""
				})
			}
		],
		[
			"facebook",
			{
				handleAuth: async (options) => {
					const firebaseAuth = get().auth;
					if (options.type !== "Firebase" || !firebaseAuth)
						throw new Error("Incorrect init options passed to facebook authentication");
					const provider = new FacebookAuthProvider();
					const result = await signInWithPopup(firebaseAuth, provider);
					await loginWithFirebaseUser(options.game, result.user, options.marketingEnabled);
					void Analytics.site.login({ method: "facebook" });
				},
				createInitOptions: (config?: FxIdWebFeaturesPlayPublicDataBase) => ({
					type: "Firebase",
					game: config?.GameSystemName || ""
				})
			}
		],
		[
			"vk",
			{
				handleAuth: (options) => {
					log.info("[VK] Starting to authenticate with VK");

					if (options.type !== "VK") {
						throw new Error("[VK] Incorrect init options passed to vk authentication");
					}
					if (!("VK" in window)) {
						throw new Error("[VK] VK API is not initialized!");
					}

					log.info(`[VK] Initializing VK API with app ID: ${options.appId}`);

					window.VK.init({ apiId: options.appId });

					log.info("[VK] Initialized");

					window.VK.Auth.login((data) => {
						log.info("[VK] Vk login data: %o", data);

						log.info("[VK] Login data recieved:", data);
						if (!("session" in data) || !data.session || !("user" in data.session)) {
							log.info("[VK] No user in response. Cannot continue login");
							throw new Error("VK responded with no user!");
						}
						OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiEndpoint({
							Game: options.game?.toString(),
							VkSessionUser: {
								FirstName: data.session.user.first_name,
								LastName: data.session.user.last_name,
								NickName: data.session.user.nickname
							}
						})
							.then((result) => {
								options.callback?.(true);
								void Analytics.site.login({ method: "vk" });
							})
							.catch((err) => {
								options.callback?.(false);
								log.error(err);
							});
					}, 0);
				},
				createInitOptions: (config?: FxIdWebFeaturesPlayPublicDataBase) => {
					const appId =
						import.meta.env.VITE_APP_TENANT_OVERRIDE === "RBW" ||
						import.meta.env.VITE_APP_TENANT_OVERRIDE === "GlobalCity"
							? APP_ID_CONFIG.RBW.vk
							: APP_ID_CONFIG.FX.vk;
					return {
						type: "VK",
						appId,
						game: config?.GameSystemName || ""
					};
				}
			}
		]
	];

	// return new Map(providers.filter(([name]) => allowedProviders.includes(name)));
	return new Map(providers);
};

const useAuthStore = create<Store>()(
	immer((set, get) => ({
		auth,
		authOptions: getAuthOptions(get),
		emailLinkProvider: "fxid",
		isEmailAuthSuccessful: false,
		updateAuthLanguage: (newLang: string) =>
			set((state) => {
				if (state.auth) state.auth.languageCode = newLang;
			}),
		initFirebase: (options: FirebaseOptions) => {
			const { auth } = firebaseInit(options);
			set((state) => {
				state.auth = auth;
			});
		}
	}))
);

export const useFirebaseAuth = () => useAuthStore((state) => state.auth);
export const useAuthOptions = () => useAuthStore((state) => state.authOptions);
export const useEmailLinkAuthProvider = () => useAuthStore((state) => state.emailLinkProvider);
export const useFirebaseInit = () => useAuthStore((state) => state.initFirebase);

// export function initVkApi() {
// 	const vkOptions = authStore.getState().authOptions.get("vk");
// 	if (!vkOptions) {
// 		// eslint-disable-next-line no-console
// 		log.error("VK API is unavailable!");
// 		return;
// 	}
// 	const initOptions = vkOptions.createInitOptions();
// 	if (!("appId" in initOptions)) return;
// 	window.VK.init({ apiId: initOptions.appId });
// 	registerVkApiListners();
// 	return window.VK;
// }

function registerVkApiListners() {
	window.VK.Observer.subscribe("auth.login", (...args) => log.info("[VK] Login successful", args));

	window.VK.Observer.subscribe("auth.logout", (...args) => log.info("[VK] Logout successful", args));

	window.VK.Observer.subscribe("auth.sessionChange", (...args) => log.info("[VK] Auth session change", args));

	window.VK.Observer.subscribe("auth.statusChange", (...args) => log.info("[VK] Auth status changed", args));
}

export const authStore = {
	getState: useAuthStore.getState,
	setState: useAuthStore.setState,
	subscribe: useAuthStore.subscribe
};
