/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsMyGamesPublicAuthOptions
 */
export interface FxIdDomainSettingsMyGamesPublicAuthOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsMyGamesPublicAuthOptions
     */
    ClientId: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsMyGamesPublicAuthOptions
     */
    GameCode: string;
}

/**
 * Check if a given object implements the FxIdDomainSettingsMyGamesPublicAuthOptions interface.
 */
export function instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ClientId" in value;
    isInstance = isInstance && "GameCode" in value;

    return isInstance;
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSON(json: any): FxIdDomainSettingsMyGamesPublicAuthOptions {
    return FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsMyGamesPublicAuthOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ClientId': json['ClientId'],
        'GameCode': json['GameCode'],
    };
}

export function FxIdDomainSettingsMyGamesPublicAuthOptionsToJSON(value?: FxIdDomainSettingsMyGamesPublicAuthOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ClientId': value.ClientId,
        'GameCode': value.GameCode,
    };
}

