/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
     */
    Uid: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
     */
    IpAddress: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions
     */
    Currency: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Uid" in value;
    isInstance = isInstance && "IpAddress" in value;
    isInstance = isInstance && "Currency" in value;

    return isInstance;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Uid': json['Uid'],
        'IpAddress': json['IpAddress'],
        'Currency': json['Currency'],
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptionsToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Uid': value.Uid,
        'IpAddress': value.IpAddress,
        'Currency': value.Currency,
    };
}

