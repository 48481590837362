// A wrapper for <Route> that redirects to the login
import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";

import { authTokenExists, getAuthToken } from "../Api/Auth";

interface Props {
	children: JSX.Element;
	path: string;
}

export const AuthRoute = ({ children, path }: Props) => {
	const isAuthed = authTokenExists();
	const message = "Please log in to view this page";

	if (isAuthed) {
		return children;
	}

	return (
		<Navigate
			to="/"
			state={{
				message,
				requestedPath: path
			}}
		/>
	);
};
