/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload';
import type { FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent } from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent';
import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent';

/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    SourceBuildNumber: number;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    DestinationBuildNumber: number;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    Torrent?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent | null;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    HttpDownload?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownload | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    ManifestUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    ManifestSha256?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    PatchSize: number;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    FullSize: number;
    /**
     * 
     * @type {number}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    MaxFileSize: number;
    /**
     * 
     * @type {Date}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    CreatedAt: Date;
    /**
     * 
     * @type {FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact
     */
    Type: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactType;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "SourceBuildNumber" in value;
    isInstance = isInstance && "DestinationBuildNumber" in value;
    isInstance = isInstance && "PatchSize" in value;
    isInstance = isInstance && "FullSize" in value;
    isInstance = isInstance && "MaxFileSize" in value;
    isInstance = isInstance && "CreatedAt" in value;
    isInstance = isInstance && "Type" in value;

    return isInstance;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SourceBuildNumber': json['sourceBuildNumber'],
        'DestinationBuildNumber': json['destinationBuildNumber'],
        'Torrent': !exists(json, 'torrent') ? undefined : FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSON(json['torrent']),
        'HttpDownload': !exists(json, 'httpDownload') ? undefined : FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadFromJSON(json['httpDownload']),
        'ManifestUrl': !exists(json, 'manifestUrl') ? undefined : json['manifestUrl'],
        'ManifestSha256': !exists(json, 'manifestSha256') ? undefined : json['manifestSha256'],
        'PatchSize': json['patchSize'],
        'FullSize': json['fullSize'],
        'MaxFileSize': json['maxFileSize'],
        'CreatedAt': (new Date(json['createdAt'])),
        'Type': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeFromJSON(json['type']),
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sourceBuildNumber': value.SourceBuildNumber,
        'destinationBuildNumber': value.DestinationBuildNumber,
        'torrent': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentToJSON(value.Torrent),
        'httpDownload': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationHttpDownloadToJSON(value.HttpDownload),
        'manifestUrl': value.ManifestUrl,
        'manifestSha256': value.ManifestSha256,
        'patchSize': value.PatchSize,
        'fullSize': value.FullSize,
        'maxFileSize': value.MaxFileSize,
        'createdAt': (value.CreatedAt.toISOString()),
        'type': FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultArtifactTypeToJSON(value.Type),
    };
}

