/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
     */
    Game?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest
     */
    Url: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Url" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'GameSystemName': !exists(json, 'GameSystemName') ? undefined : json['GameSystemName'],
        'Game': !exists(json, 'Game') ? undefined : json['Game'],
        'Url': json['Url'],
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequestToJSON(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'GameSystemName': value.GameSystemName,
        'Game': value.Game,
        'Url': value.Url,
    };
}

