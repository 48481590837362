/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsUnityOptionsDistribution } from './FxIdDomainSettingsUnityOptionsDistribution';
import {
    FxIdDomainSettingsUnityOptionsDistributionFromJSON,
    FxIdDomainSettingsUnityOptionsDistributionFromJSONTyped,
    FxIdDomainSettingsUnityOptionsDistributionToJSON,
} from './FxIdDomainSettingsUnityOptionsDistribution';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsUnityOptions
 */
export interface FxIdDomainSettingsUnityOptions {
    /**
     * 
     * @type {FxIdDomainSettingsUnityOptionsDistribution}
     * @memberof FxIdDomainSettingsUnityOptions
     */
    Distribution: FxIdDomainSettingsUnityOptionsDistribution;
}

/**
 * Check if a given object implements the FxIdDomainSettingsUnityOptions interface.
 */
export function instanceOfFxIdDomainSettingsUnityOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Distribution" in value;

    return isInstance;
}

export function FxIdDomainSettingsUnityOptionsFromJSON(json: any): FxIdDomainSettingsUnityOptions {
    return FxIdDomainSettingsUnityOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Distribution': FxIdDomainSettingsUnityOptionsDistributionFromJSON(json['Distribution']),
    };
}

export function FxIdDomainSettingsUnityOptionsToJSON(value?: FxIdDomainSettingsUnityOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Distribution': FxIdDomainSettingsUnityOptionsDistributionToJSON(value.Distribution),
    };
}

