/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData } from './FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData';
import {
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSON,
} from './FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest
     */
    Game?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest
     */
    VkSessionUser: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "VkSessionUser" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    return FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Game': !exists(json, 'Game') ? undefined : json['Game'],
        'VkSessionUser': FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSON(json['VkSessionUser']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Game': value.Game,
        'VkSessionUser': FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSON(value.VkSessionUser),
    };
}

