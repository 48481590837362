/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsAdvertisementYandexOptionsRewarded } from './FxIdDomainSettingsAdvertisementYandexOptionsRewarded';
import {
    FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSON,
    FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSONTyped,
    FxIdDomainSettingsAdvertisementYandexOptionsRewardedToJSON,
} from './FxIdDomainSettingsAdvertisementYandexOptionsRewarded';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsAdvertisementYandexOptions
 */
export interface FxIdDomainSettingsAdvertisementYandexOptions {
    /**
     * 
     * @type {FxIdDomainSettingsAdvertisementYandexOptionsRewarded}
     * @memberof FxIdDomainSettingsAdvertisementYandexOptions
     */
    Rewarded?: FxIdDomainSettingsAdvertisementYandexOptionsRewarded | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsAdvertisementYandexOptions interface.
 */
export function instanceOfFxIdDomainSettingsAdvertisementYandexOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsAdvertisementYandexOptionsFromJSON(json: any): FxIdDomainSettingsAdvertisementYandexOptions {
    return FxIdDomainSettingsAdvertisementYandexOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementYandexOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Rewarded': !exists(json, 'Rewarded') ? undefined : FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSON(json['Rewarded']),
    };
}

export function FxIdDomainSettingsAdvertisementYandexOptionsToJSON(value?: FxIdDomainSettingsAdvertisementYandexOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Rewarded': FxIdDomainSettingsAdvertisementYandexOptionsRewardedToJSON(value.Rewarded),
    };
}

