import create from "zustand";
import { immer } from "zustand/middleware/immer";

type Store = State & Actions;
type State = {
	displayName?: string;
	userId?: string;
};
type Actions = {
	updateDisplayName: (newName: string) => void;
	updateUserId: (newId: string) => void;
};

const useUserStore = create<Store>()(
	immer((set) => ({
		updateDisplayName: (newName) =>
			set((state) => {
				state.displayName = newName;
			}),
		updateUserId: (newId) =>
			set((state) => {
				state.userId = newId;
			})
	}))
);

export const useUserDisplayName = () => {
	return useUserStore((state) => state.displayName);
};

export const useUserIdFromSdk = () => {
	return useUserStore((state) => state.userId);
};

export const userStore = {
	getState: useUserStore.getState,
	setState: useUserStore.setState,
	subscribe: useUserStore.subscribe
};
