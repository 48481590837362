/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesInfoNewsNewsL10nMessage
 */
export interface FxIdWebFeaturesInfoNewsNewsL10nMessage {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNewsL10nMessage
     */
    Locale: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNewsL10nMessage
     */
    Header: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesInfoNewsNewsL10nMessage
     */
    Message: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesInfoNewsNewsL10nMessage interface.
 */
export function instanceOfFxIdWebFeaturesInfoNewsNewsL10nMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Locale" in value;
    isInstance = isInstance && "Header" in value;
    isInstance = isInstance && "Message" in value;

    return isInstance;
}

export function FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSON(json: any): FxIdWebFeaturesInfoNewsNewsL10nMessage {
    return FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSONTyped(json, false);
}

export function FxIdWebFeaturesInfoNewsNewsL10nMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesInfoNewsNewsL10nMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Locale': json['Locale'],
        'Header': json['Header'],
        'Message': json['Message'],
    };
}

export function FxIdWebFeaturesInfoNewsNewsL10nMessageToJSON(value?: FxIdWebFeaturesInfoNewsNewsL10nMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Locale': value.Locale,
        'Header': value.Header,
        'Message': value.Message,
    };
}

