/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo {
    /**
     * 
     * @type {any}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo
     */
    UserPayload?: any | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'UserPayload': !exists(json, 'UserPayload') ? undefined : json['UserPayload'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfoToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'UserPayload': value.UserPayload,
    };
}

