/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    AllowedAuthProviders: Array<string>;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Google?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Facebook?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    FacebookInstantGames?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    MyGames?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Twitch?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AllowedAuthProviders" in value;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AllowedAuthProviders': json['AllowedAuthProviders'],
        'Google': !exists(json, 'Google') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSON(json['Google']),
        'Facebook': !exists(json, 'Facebook') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON(json['Facebook']),
        'FacebookInstantGames': !exists(json, 'FacebookInstantGames') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON(json['FacebookInstantGames']),
        'MyGames': !exists(json, 'MyGames') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSON(json['MyGames']),
        'Twitch': !exists(json, 'Twitch') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSON(json['Twitch']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AllowedAuthProviders': value.AllowedAuthProviders,
        'Google': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSON(value.Google),
        'Facebook': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON(value.Facebook),
        'FacebookInstantGames': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON(value.FacebookInstantGames),
        'MyGames': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSON(value.MyGames),
        'Twitch': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSON(value.Twitch),
    };
}

