import React from "react";

export const FullscreenClose = React.forwardRef<SVGSVGElement, React.SVGAttributes<SVGElement>>(
	function FullscreenClose(props, ref) {
		return (
			<svg ref={ref} fill="currentColor" {...props}>
				<use xlinkHref="#icon-full_screen_close"></use>
			</svg>
		);
	}
);
