import { apiBasePath } from "@app/constants";
import { getAuthToken } from "./Auth";
import {
	Configuration,
	InviteApi,
	AuthApi,
	PlayApi,
	ProfileApi,
	StoreApi,
	YandexgamesApi,
	PublicGameConfigApi,
	FriendsApi,
	GoApi,
	Bank131Api,
	WizqApi,
	FtlIntegrationApi,
	GamesApi,
	InfoApi,
	SupportApi,
	FbinstantApi
} from "./gen";

export const openApiConfig: Configuration = new Configuration({
	basePath: apiBasePath,
	accessToken: () => getAuthToken() as string
});

const OpenApiClient = {
	Invite: new InviteApi(openApiConfig),
	Auth: new AuthApi(openApiConfig),
	Games: new GamesApi(openApiConfig),
	Play: new PlayApi(openApiConfig),
	Profile: new ProfileApi(openApiConfig),
	Store: new StoreApi(openApiConfig),
	Info: new InfoApi(openApiConfig),
	YandexGames: new YandexgamesApi(openApiConfig),
	FacebookInstantGames: new FbinstantApi(openApiConfig),
	PublicGameConfigApi: new PublicGameConfigApi(openApiConfig),
	Friends: new FriendsApi(openApiConfig),
	Go: new GoApi(openApiConfig),
	Bank131: new Bank131Api(openApiConfig),
	WizQ: new WizqApi(openApiConfig),
	FtlIntegration: new FtlIntegrationApi(openApiConfig),
	Support: new SupportApi(openApiConfig)
};

export default OpenApiClient;
