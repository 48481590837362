/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions,
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsBackground
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsBackground = FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsBackground {
    return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsBackground {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsBackground | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptionsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsBackgroundOptions);
    }

    return {};
}

