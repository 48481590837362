/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlay,
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlay,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlayFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlayFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlayToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlay';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay = FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlay;

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay {
    return FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlayFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlayToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsVkontaktePlay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlay(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlayToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontaktePlay);
    }

    return {};
}

