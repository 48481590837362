import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, MantineProvider, Stack, Textarea, TextInput } from "@mantine/core";
import { closeModal, ContextModalProps, openContextModal } from "@mantine/modals";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { getI18n, useTranslation } from "react-i18next";

type ReportIssueModalInnerProps = {
	gameData: string;
	userEmail?: string;
};

const schema = z.object({
	message: z.string(),
	email: z.string().email()
});

const useReportIssueForm = (props: ReportIssueModalInnerProps) => {
	const defaultValues: z.infer<typeof schema> = {
		message: "",
		email: props.userEmail ?? ""
	};
	return useForm({ defaultValues, resolver: zodResolver(schema) });
};

export const ReportIssueModal = (props: ContextModalProps<ReportIssueModalInnerProps>) => {
	const { t } = useTranslation();
	const { innerProps } = props;
	const form = useReportIssueForm(innerProps);
	const {
		control,
		handleSubmit,
		formState: { errors, isSubmitting }
	} = form;

	const { mutate: reportIssue } = useMutation({
		// TODO: Заменить на нормальную функцию, отправляющую запрос в суппорт сервис
		mutationFn: (_data: unknown) => Promise.resolve(undefined),
		onSettled: () => closeModal(props.id)
	});
	return (
		<MantineProvider>
			<Box
				component="form"
				onSubmit={handleSubmit((data) => {
					// TODO: Сформировать нормальное тело запроса с учетом данных из игры, лежщих в innerProps
					reportIssue(data);
				})}
			>
				<Stack spacing="md">
					<Controller
						control={control}
						name="message"
						render={({ field }) => (
							<Textarea
								{...field}
								label={t("report_issue_modal.message.label")}
								placeholder={t("report_issue_modal.message.placeholder")}
							/>
						)}
					/>
					<Controller
						control={control}
						name="email"
						render={({ field, fieldState }) => (
							<TextInput
								{...field}
								error={fieldState.error?.message}
								label={t("report_issue_modal.email.label")}
								withAsterisk
							/>
						)}
					/>
					<Button
						type="submit"
						disabled={isSubmitting || errors.email !== undefined || errors.message !== undefined}
					>
						{t("report_issue_modal.report_issue")}
					</Button>
				</Stack>
			</Box>
		</MantineProvider>
	);
};

export const openReportIssueModal = (innerProps: ReportIssueModalInnerProps) => {
	openContextModal({
		modal: "reportIssue",
		innerProps,
		title: String(getI18n().t("report_issue_modal.title")),
		styles: (theme) => ({
			modal: { color: theme.black, backgroundColor: theme.white },
			close: { color: theme.colors.gray[6], "&:hover": { backgroundColor: theme.colors.gray[0] } }
		})
	});
};
