/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptions,
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptions,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsWebOptions';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsWeb
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsWeb = FxIdWebFeaturesPlayPlatformDataOptionsWebOptions;

export function FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWeb {
    return FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsWeb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsWeb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptions(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsWebOptions);
    }

    return {};
}

