/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    ApiKey?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    AuthDomain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    DatabaseUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    ProjectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    StorageBucket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    MessagingSenderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    AppId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions
     */
    MeasurementId?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ApiKey': !exists(json, 'ApiKey') ? undefined : json['ApiKey'],
        'AuthDomain': !exists(json, 'AuthDomain') ? undefined : json['AuthDomain'],
        'DatabaseUrl': !exists(json, 'DatabaseUrl') ? undefined : json['DatabaseUrl'],
        'ProjectId': !exists(json, 'ProjectId') ? undefined : json['ProjectId'],
        'StorageBucket': !exists(json, 'StorageBucket') ? undefined : json['StorageBucket'],
        'MessagingSenderId': !exists(json, 'MessagingSenderId') ? undefined : json['MessagingSenderId'],
        'AppId': !exists(json, 'AppId') ? undefined : json['AppId'],
        'MeasurementId': !exists(json, 'MeasurementId') ? undefined : json['MeasurementId'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptionsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsFirebaseOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ApiKey': value.ApiKey,
        'AuthDomain': value.AuthDomain,
        'DatabaseUrl': value.DatabaseUrl,
        'ProjectId': value.ProjectId,
        'StorageBucket': value.StorageBucket,
        'MessagingSenderId': value.MessagingSenderId,
        'AppId': value.AppId,
        'MeasurementId': value.MeasurementId,
    };
}

