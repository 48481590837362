import React from "react";
import { Text } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { GlobalMessageProps, useGlobalMessagesQueue } from "../../../Stores";

export const InstallGame = (props: GlobalMessageProps) => {
	const { t } = useTranslation();
	const [, { dequeue }] = useGlobalMessagesQueue();
	const showPrompt =
		typeof props.meta === "object" &&
		props.meta !== null &&
		"showPrompt" in props.meta &&
		typeof props.meta.showPrompt === "function"
			? props.meta.showPrompt
			: undefined;
	return (
		<Text>
			<Trans
				t={t}
				i18nKey="global_messages.install_game"
				components={[
					<Text
						key="0"
						td="underline"
						sx={(theme) => ({
							display: "inline-block",
							cursor: "pointer",
							fontWeight: "bold",
							":active": theme.activeStyles
						})}
						onClick={() => {
							showPrompt?.();
							dequeue("install-game");
						}}
					/>
				]}
			/>
		</Text>
	);
};
