/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
  FxIdWebFeaturesPlayPublicDataBase,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
    FxIdWebFeaturesPlayPublicDataBaseFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseToJSON,
} from '../models';

export interface FxIdWebFeaturesPlayGamePublicConfigEndpointRequest {
    game: string;
    webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
}

/**
 * 
 */
export class PublicGameConfigApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesPlayGamePublicConfigEndpointRaw(requestParameters: FxIdWebFeaturesPlayGamePublicConfigEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesPlayPublicDataBase>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesPlayGamePublicConfigEndpoint.');
        }

        if (requestParameters.webPublishingPlatform === null || requestParameters.webPublishingPlatform === undefined) {
            throw new runtime.RequiredError('webPublishingPlatform','Required parameter requestParameters.webPublishingPlatform was null or undefined when calling fxIdWebFeaturesPlayGamePublicConfigEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.webPublishingPlatform !== undefined) {
            queryParameters['webPublishingPlatform'] = requestParameters.webPublishingPlatform;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/public_game_config/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesPlayPublicDataBaseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesPlayGamePublicConfigEndpoint(game: string, webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesPlayPublicDataBase> {
        const response = await this.fxIdWebFeaturesPlayGamePublicConfigEndpointRaw({ game: game, webPublishingPlatform: webPublishingPlatform }, initOverrides);
        return await response.value();
    }

}
