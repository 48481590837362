/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions,
    instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsFromJSON,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions';

/**
 * @type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin = FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions;

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginFromJSON(json: any): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin {
    return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginToJSON(value?: FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions(value)) {
        return FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptionsToJSON(value as FxIdApplicationHandlersAuthAuthenticateFromSocialAuthenticateFromSocialDtoVkontakteComLoginOptions);
    }

    return {};
}

