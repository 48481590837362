/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse
 */
export interface FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse
     */
    Success: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse interface.
 */
export function instanceOfFxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Success" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponseFromJSON(json: any): FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse {
    return FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Success': json['Success'],
    };
}

export function FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponseToJSON(value?: FxIdWebFeaturesStoreFacebookInstantGamesPurchaseResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Success': value.Success,
    };
}

