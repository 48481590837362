/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainStoreEnumsSupportedWebPublishingPlatform } from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';
import {
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
} from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreStoreDataRequest
 */
export interface FxIdWebFeaturesStoreStoreDataRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    Game: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    ProductsSku?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {FxIdDomainStoreEnumsSupportedWebPublishingPlatform}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    Currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    Country?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FxIdWebFeaturesStoreStoreDataRequest
     */
    StoreAdditionalData?: any | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreStoreDataRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreStoreDataRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Game" in value;
    isInstance = isInstance && "WebPublishingPlatform" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreStoreDataRequestFromJSON(json: any): FxIdWebFeaturesStoreStoreDataRequest {
    return FxIdWebFeaturesStoreStoreDataRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreStoreDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreStoreDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'ProductsSku': !exists(json, 'ProductsSku') ? undefined : json['ProductsSku'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
        'WebPublishingPlatform': FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON(json['WebPublishingPlatform']),
        'Currency': !exists(json, 'Currency') ? undefined : json['Currency'],
        'Country': !exists(json, 'Country') ? undefined : json['Country'],
        'StoreAdditionalData': !exists(json, 'StoreAdditionalData') ? undefined : json['StoreAdditionalData'],
    };
}

export function FxIdWebFeaturesStoreStoreDataRequestToJSON(value?: FxIdWebFeaturesStoreStoreDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Game': value.Game,
        'ProductsSku': value.ProductsSku,
        'Locale': value.Locale,
        'WebPublishingPlatform': FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON(value.WebPublishingPlatform),
        'Currency': value.Currency,
        'Country': value.Country,
        'StoreAdditionalData': value.StoreAdditionalData,
    };
}

