/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesGamesChooseGamePortalConfig } from './FxIdWebFeaturesGamesChooseGamePortalConfig';
import {
    FxIdWebFeaturesGamesChooseGamePortalConfigFromJSON,
    FxIdWebFeaturesGamesChooseGamePortalConfigFromJSONTyped,
    FxIdWebFeaturesGamesChooseGamePortalConfigToJSON,
} from './FxIdWebFeaturesGamesChooseGamePortalConfig';
import type { FxIdWebFeaturesGamesChooseGameResponsePublicGameData } from './FxIdWebFeaturesGamesChooseGameResponsePublicGameData';
import {
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSON,
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSONTyped,
    FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSON,
} from './FxIdWebFeaturesGamesChooseGameResponsePublicGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesChooseGameResponse
 */
export interface FxIdWebFeaturesGamesChooseGameResponse {
    /**
     * 
     * @type {FxIdWebFeaturesGamesChooseGamePortalConfig}
     * @memberof FxIdWebFeaturesGamesChooseGameResponse
     */
    PortalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig;
    /**
     * 
     * @type {FxIdWebFeaturesGamesChooseGameResponsePublicGameData}
     * @memberof FxIdWebFeaturesGamesChooseGameResponse
     */
    PublicGameData?: FxIdWebFeaturesGamesChooseGameResponsePublicGameData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesChooseGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesGamesChooseGameResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PortalConfig" in value;

    return isInstance;
}

export function FxIdWebFeaturesGamesChooseGameResponseFromJSON(json: any): FxIdWebFeaturesGamesChooseGameResponse {
    return FxIdWebFeaturesGamesChooseGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PortalConfig': FxIdWebFeaturesGamesChooseGamePortalConfigFromJSON(json['PortalConfig']),
        'PublicGameData': !exists(json, 'PublicGameData') ? undefined : FxIdWebFeaturesGamesChooseGameResponsePublicGameDataFromJSON(json['PublicGameData']),
    };
}

export function FxIdWebFeaturesGamesChooseGameResponseToJSON(value?: FxIdWebFeaturesGamesChooseGameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PortalConfig': FxIdWebFeaturesGamesChooseGamePortalConfigToJSON(value.PortalConfig),
        'PublicGameData': FxIdWebFeaturesGamesChooseGameResponsePublicGameDataToJSON(value.PublicGameData),
    };
}

