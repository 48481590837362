/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsTwitchAuthOptions,
    instanceOfFxIdDomainSettingsTwitchAuthOptions,
    FxIdDomainSettingsTwitchAuthOptionsFromJSON,
    FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped,
    FxIdDomainSettingsTwitchAuthOptionsToJSON,
} from './FxIdDomainSettingsTwitchAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch = FxIdDomainSettingsTwitchAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsTwitchAuthOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitchToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigTwitch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsTwitchAuthOptions(value)) {
        return FxIdDomainSettingsTwitchAuthOptionsToJSON(value as FxIdDomainSettingsTwitchAuthOptions);
    }

    return {};
}

