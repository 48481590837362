/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType } from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';
import {
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSONTyped,
    FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON,
} from './FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier {
    /**
     * 
     * @type {FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier
     */
    Type: FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier
     */
    Id: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Type" in value;
    isInstance = isInstance && "Id" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Type': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeFromJSON(json['Type']),
        'Id': json['Id'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Type': FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierTypeToJSON(value.Type),
        'Id': value.Id,
    };
}

