/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease,
    instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease';

/**
 * @type FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease
 * 
 * @export
 */
export type FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease = FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease;

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseFromJSONTyped(json, true) };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultReleaseToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestGetLauncherAppManifestResultRelease | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease(value)) {
        return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultReleaseToJSON(value as FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultRelease);
    }

    return {};
}

