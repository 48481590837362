/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdApplicationStoreCreatePaymentHandlerEmbeddingType {
    Redirect = 0,
    Embed = 1,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerEmbeddingType {
    return FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerEmbeddingType {
    return json as FxIdApplicationStoreCreatePaymentHandlerEmbeddingType;
}

export function FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType | null): any {
    return value as any;
}

