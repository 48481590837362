import React from "react";
import Image from "react-bootstrap/Image";

import imgMainHeader from "./main_header.png";

const Header = () => {
	return (
		<a href="/">
			<header>
				<Image src={imgMainHeader} alt="header" fluid />
			</header>
		</a>
	);
};

export default Header;
