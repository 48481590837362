/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
     */
    ReasonText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
     */
    ReasonTextFallback?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
     */
    DateStart?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
     */
    DateEnd?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
     */
    ForceStop?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance
     */
    EmailsExcluded?: Array<string> | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenanceFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenanceFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ReasonText': !exists(json, 'ReasonText') ? undefined : json['ReasonText'],
        'ReasonTextFallback': !exists(json, 'ReasonTextFallback') ? undefined : json['ReasonTextFallback'],
        'DateStart': !exists(json, 'DateStart') ? undefined : (json['DateStart'] === null ? null : new Date(json['DateStart'])),
        'DateEnd': !exists(json, 'DateEnd') ? undefined : (json['DateEnd'] === null ? null : new Date(json['DateEnd'])),
        'ForceStop': !exists(json, 'ForceStop') ? undefined : json['ForceStop'],
        'EmailsExcluded': !exists(json, 'EmailsExcluded') ? undefined : json['EmailsExcluded'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenanceToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherMaintenance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ReasonText': value.ReasonText,
        'ReasonTextFallback': value.ReasonTextFallback,
        'DateStart': value.DateStart === undefined ? undefined : (value.DateStart === null ? null : value.DateStart.toISOString()),
        'DateEnd': value.DateEnd === undefined ? undefined : (value.DateEnd === null ? null : value.DateEnd.toISOString()),
        'ForceStop': value.ForceStop,
        'EmailsExcluded': value.EmailsExcluded,
    };
}

