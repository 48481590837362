import React from "react";
import { GlobalMessageProps } from "../../../Stores";
import { Trans } from "react-i18next";
import { openLoginWithFirebaseModal } from "../../Modals";
import { Text } from "@mantine/core";
import {
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "../../../Api/gen";
import { IframeType } from "../../../Screens/WebPlayer/Hooks";

export const NoEmail = (props: GlobalMessageProps) => {
	const textColor = props.color.includes("yellow") ? "black" : undefined;
	const portalConfig = props.meta!.portalConfig as FxIdWebFeaturesGamesChooseGamePortalConfig;
	const gameConfig =
		props.meta?.gameConfig != null
			? (props.meta?.gameConfig as FxIdWebFeaturesGamesChooseGameResponsePublicGameData)
			: undefined;
	const adapter = (props.meta?.adapter != null ? (props.meta.adapter as IframeType) : undefined) ?? "fxid";

	return (
		<Text c={textColor}>
			<Trans
				i18nKey="global_messages.no_email"
				components={[
					<Text
						key="0"
						c={textColor}
						td="underline"
						sx={(theme) => ({
							display: "inline-block",
							cursor: "pointer",
							fontWeight: "bold",
							":active": theme.activeStyles
						})}
						onClick={() =>
							openLoginWithFirebaseModal({
								onAuthSuccessful: () => window.location.reload(),
								authProviders: {
									allowed:
										gameConfig?.PublicWebClientConfig.Auth?.AllowedAuthProviders ??
										portalConfig.PortalPublicWebConfig.CompanyBranding.Auth.AllowedAuthProviders
								},
								target: gameConfig ? "#web-player" : undefined,
								gameConfig: gameConfig,
								portalConfig: portalConfig,
								adapter: adapter
							})
						}
					/>
				]}
			/>
		</Text>
	);
};
