import { Helmet, HelmetProps } from "react-helmet";
import { FunctionComponent } from "react";

/**
 * Данный компонент является хаком Helmet для типов
 * сам Helmet по типам не используется в функциональных компонентах (библиотека типов устарела, ждем обновления)
 * Чтобы везде не использовать @ts-ignore и пр. делаем это в одном месте
 * Как только библиотека типов обновится, можно будет убрать
 */
export const HelmetWithChildren: FunctionComponent<HelmetProps> = ({ children, ...rest }) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line react/react-in-jsx-scope
	return <Helmet {...rest}>{children}</Helmet>;
};
