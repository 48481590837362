/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
     */
    Token: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest
     */
    MarketingEnabled?: boolean | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromFirebaseRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Token" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    return FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'GameSystemName': !exists(json, 'GameSystemName') ? undefined : json['GameSystemName'],
        'Token': json['Token'],
        'MarketingEnabled': !exists(json, 'MarketingEnabled') ? undefined : json['MarketingEnabled'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromFirebaseRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromFirebaseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'GameSystemName': value.GameSystemName,
        'Token': value.Token,
        'MarketingEnabled': value.MarketingEnabled,
    };
}

