/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsFxEventsClientPublicConfigConfigSection } from './FxIdDomainSettingsFxEventsClientPublicConfigConfigSection';
import {
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSON,
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSONTyped,
    FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSON,
} from './FxIdDomainSettingsFxEventsClientPublicConfigConfigSection';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsFxEventsClientPublicConfig
 */
export interface FxIdDomainSettingsFxEventsClientPublicConfig {
    /**
     * 
     * @type {{ [key: string]: FxIdDomainSettingsFxEventsClientPublicConfigConfigSection; }}
     * @memberof FxIdDomainSettingsFxEventsClientPublicConfig
     */
    Sections: { [key: string]: FxIdDomainSettingsFxEventsClientPublicConfigConfigSection; };
}

/**
 * Check if a given object implements the FxIdDomainSettingsFxEventsClientPublicConfig interface.
 */
export function instanceOfFxIdDomainSettingsFxEventsClientPublicConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Sections" in value;

    return isInstance;
}

export function FxIdDomainSettingsFxEventsClientPublicConfigFromJSON(json: any): FxIdDomainSettingsFxEventsClientPublicConfig {
    return FxIdDomainSettingsFxEventsClientPublicConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsFxEventsClientPublicConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsFxEventsClientPublicConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Sections': (mapValues(json['Sections'], FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionFromJSON)),
    };
}

export function FxIdDomainSettingsFxEventsClientPublicConfigToJSON(value?: FxIdDomainSettingsFxEventsClientPublicConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Sections': (mapValues(value.Sections, FxIdDomainSettingsFxEventsClientPublicConfigConfigSectionToJSON)),
    };
}

