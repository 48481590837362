export const routerBasePath =
	import.meta.env.VITE_APP_BUILD_MODE !== "standalone"
		? (import.meta.env.VITE_APP_ROUTER_BASEPATH ?? import.meta.env.BASE_URL)
		: undefined;

export const normalizedBaseUrl =
	import.meta.env.BASE_URL !== "/" && import.meta.env.BASE_URL !== "./" ? import.meta.env.BASE_URL : "";

// export const apiBasePath = import.meta.env.VITE_APP_API_BASE_PATH
// 	? import.meta.env.VITE_APP_API_BASE_PATH
// 	: `${window.location.origin}${normalizedBaseUrl}`;

export const apiBasePath =
	import.meta.env.VITE_APP_API_BASE_PATH != null
		? import.meta.env.VITE_APP_API_BASE_PATH
		: normalizedBaseUrl.startsWith("http")
			? normalizedBaseUrl
			: `${window.location.origin}${normalizedBaseUrl}`;
