/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainEntitiesClientErrorCode {
    Unspecified = 0,
    SecurityTokenNotValidated = 1,
    SettingsError = 2,
    MyGamesOauthNoCode = 10000,
    MyGamesOauthNoState = 10001,
    MyGamesApiError = 10002,
    ExternalIdTransferNotAllowed = 10009,
    ExternalIdTransferAwAccountAlreadyConnectedToAnotherSocial = 10010,
    ExternalIdTransferHelperSocialIdentifierAlreadyAttachedToAccount = 10013,
    ExternalIdTransferHelperAccountAlreadyHasSocialIdentifierOfThisType = 10014,
    ExternalIdTransferHelperFailedToTransfer = 10015,
    TwitchTokenValidationFailed = 10016,
    SocialIdentifierAlreadyTaken = 10017,
    TwitchAccountNotFound = 10018,
    TwitchDataWrong = 10019,
    TwitchCodeForTokenExchangeFailed = 10020,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdDomainEntitiesClientErrorCodeFromJSON(json: any): FxIdDomainEntitiesClientErrorCode {
    return FxIdDomainEntitiesClientErrorCodeFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesClientErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesClientErrorCode {
    return json as FxIdDomainEntitiesClientErrorCode;
}

export function FxIdDomainEntitiesClientErrorCodeToJSON(value?: FxIdDomainEntitiesClientErrorCode | null): any {
    return value as any;
}

