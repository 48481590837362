import { Loader, Stack } from "@mantine/core";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setAuthToken } from "../../Api/Auth";
import { useLoginWithTempTokenMutation } from "../../Api/Mutations";
import * as Analytics from "../../Utils/Analytics";

export const LoginWithTempToken = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { mutate } = useLoginWithTempTokenMutation({
		onSuccess: (data) => {
			const { Token, TempTokenData } = data;
			setAuthToken(Token);
			const redirectUri = TempTokenData.RedirectUri ?? "/";

			Analytics.site
				.login({ method: "game_temp_token" })
				.then(() => {
					navigate(redirectUri);
				})
				.catch((err) => {
					log.error(err);
					navigate(redirectUri);
				});
		},
		onError: () => {
			navigate("/error");
		}
	});

	useEffect(() => {
		const tokenParam = searchParams.get("token");
		if (!tokenParam) {
			log.error("No temp token in query");
			navigate("/error");
			return;
		}
		void mutate(tokenParam);
	}, [searchParams, navigate, mutate]);

	return (
		<Stack align="center" mt="xl">
			<Loader />;
		</Stack>
	);
};
