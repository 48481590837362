/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FxIdDomainSettingsUnityOptionsDistributionForceCompression {
    Unspecified = 0,
    Disabled = 1,
    Brotli = 2,
    Gzip = 3,
    NUMBER_unknown_default_open_api = 11184809
}


export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionFromJSON(json: any): FxIdDomainSettingsUnityOptionsDistributionForceCompression {
    return FxIdDomainSettingsUnityOptionsDistributionForceCompressionFromJSONTyped(json, false);
}

export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsUnityOptionsDistributionForceCompression {
    return json as FxIdDomainSettingsUnityOptionsDistributionForceCompression;
}

export function FxIdDomainSettingsUnityOptionsDistributionForceCompressionToJSON(value?: FxIdDomainSettingsUnityOptionsDistributionForceCompression | null): any {
    return value as any;
}

