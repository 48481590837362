/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier,
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier = FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifierToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdExternalSocialIdentifier);
    }

    return {};
}

