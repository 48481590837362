/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent,
    instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped,
    FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON,
} from './FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent';

/**
 * @type FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent
 * 
 * @export
 */
export type FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent = FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent;

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped(json, true) };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrentToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultLauncherConfigurationTorrent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent(value)) {
        return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON(value as FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent);
    }

    return {};
}

