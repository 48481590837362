/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreBank131PayWithSbpRequest
 */
export interface FxIdWebFeaturesStoreBank131PayWithSbpRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreBank131PayWithSbpRequest
     */
    PublicId: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreBank131PayWithSbpRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreBank131PayWithSbpRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PublicId" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreBank131PayWithSbpRequestFromJSON(json: any): FxIdWebFeaturesStoreBank131PayWithSbpRequest {
    return FxIdWebFeaturesStoreBank131PayWithSbpRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreBank131PayWithSbpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreBank131PayWithSbpRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PublicId': json['PublicId'],
    };
}

export function FxIdWebFeaturesStoreBank131PayWithSbpRequestToJSON(value?: FxIdWebFeaturesStoreBank131PayWithSbpRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PublicId': value.PublicId,
    };
}

