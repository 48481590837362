/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramMerchantData);
    }

    return {};
}

