/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom,
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom = FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom;

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom {
    return FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsVkontakteComToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsVkontakteCom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteComToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsVkontakteCom);
    }

    return {};
}

