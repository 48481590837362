/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQMerchantData);
    }

    return {};
}

