/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsWebRuntimeType } from './FxIdDomainSettingsWebRuntimeType';
import {
    FxIdDomainSettingsWebRuntimeTypeFromJSON,
    FxIdDomainSettingsWebRuntimeTypeFromJSONTyped,
    FxIdDomainSettingsWebRuntimeTypeToJSON,
} from './FxIdDomainSettingsWebRuntimeType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions {
    /**
     * 
     * @type {FxIdDomainSettingsWebRuntimeType}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions
     */
    Default: FxIdDomainSettingsWebRuntimeType;
    /**
     * 
     * @type {Array<FxIdDomainSettingsWebRuntimeType>}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions
     */
    Supported: Array<FxIdDomainSettingsWebRuntimeType>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Default" in value;
    isInstance = isInstance && "Supported" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Default': FxIdDomainSettingsWebRuntimeTypeFromJSON(json['Default']),
        'Supported': ((json['Supported'] as Array<any>).map(FxIdDomainSettingsWebRuntimeTypeFromJSON)),
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptionsToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsWebOptionsWebRuntimesOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Default': FxIdDomainSettingsWebRuntimeTypeToJSON(value.Default),
        'Supported': ((value.Supported as Array<any>).map(FxIdDomainSettingsWebRuntimeTypeToJSON)),
    };
}

