/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantData,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantDataFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayMerchantData);
    }

    return {};
}

