import React, { useEffect, useState } from "react";
import { Overlay, UnstyledButton } from "@mantine/core";
import { useAuthOptions } from "../../Stores";
import { FcGoogle } from "react-icons/fc";
import { FaApple, FaFacebookF, FaVk } from "react-icons/fa";
import { useVkApiScript } from "../../Utils/Hooks/useScript";
import { FxIdWebFeaturesPlayPublicDataBase } from "../../Api/gen";

export type SocialLoginButtonProps = {
	large?: boolean;
	gameConfig?: FxIdWebFeaturesPlayPublicDataBase;
	marketingEnabled?: boolean;
	disabled?: boolean;
	onAuthStarted?: () => void;
	onAuthSettled?: () => void;
	onAuthSuccessful?: () => void;
};

type SocialLoginButtonPropsInternal = SocialLoginButtonProps & {
	handleAuth?: () => void | Promise<void>;
	children: React.ReactNode;
	color?: string;
	hoverColor?: string;
};

export const SOCIAL_LOGIN_BUTTONS = new Map<string, (props: SocialLoginButtonProps) => JSX.Element | null>([
	["google", GoogleLoginButton],
	["apple", AppleLoginButton],
	["vk", VkLoginButton],
	["facebook", FacebookLoginButton]
]);

export type SocialLoginOptions = keyof typeof SOCIAL_LOGIN_BUTTONS;

function GoogleLoginButton(props: SocialLoginButtonProps) {
	const authOptions = useAuthOptions();
	return (
		<SocialLoginButton
			{...props}
			handleAuth={() =>
				authOptions.get("google")?.handleAuth({
					type: "Firebase",
					marketingEnabled: props.marketingEnabled,
					game: props.gameConfig?.GameSystemName || ""
				})
			}
		>
			<FcGoogle size={props.large ? 40 : 20} />
		</SocialLoginButton>
	);
}

function AppleLoginButton(props: SocialLoginButtonProps) {
	const authOptions = useAuthOptions();

	return (
		<SocialLoginButton
			{...props}
			color="#373a40"
			hoverColor="#000000"
			handleAuth={() =>
				authOptions.get("apple")?.handleAuth({
					type: "Firebase",
					marketingEnabled: props.marketingEnabled,
					game: props.gameConfig?.GameSystemName || ""
				})
			}
		>
			<FaApple size={props.large ? 40 : 20} color="white" />
		</SocialLoginButton>
	);
}

function VkLoginButton(props: SocialLoginButtonProps) {
	const [authState, setAuthState] = useState<boolean | undefined>(undefined);
	const authOptions = useAuthOptions();
	const status = useVkApiScript();

	const handleAuth = authOptions.get("vk")?.handleAuth;
	const createInitOptions = authOptions.get("vk")?.createInitOptions;

	useEffect(() => {
		if (document.querySelector("#vk_api_transport") !== null) return;
		const container = document.createElement("div");
		container.id = "vk_api_transport";
		container.style.display = "none";
		document.body.appendChild(container);
	});

	useEffect(() => {
		if (authState === true) {
			props.onAuthSuccessful?.();
		} else {
			log.info("Auth failed");
		}
	}, [authState]);

	if (!handleAuth || !createInitOptions) return null;

	// перенес эти две строки в клик по кнопке vk ниже, чтобы vk не инициализировался при рендере кнопки
	// const options = createInitOptions(props.publicConfig);
	// options.game = props.publicConfig?.Game || FxIdDomainEntitiesSupportedGame.Unspecified;

	return (
		<SocialLoginButton
			{...props}
			disabled={props.disabled || status !== "ready"}
			color="#0077ff"
			onAuthSuccessful={() => log.info("Skipping default auth callback")}
			handleAuth={() => {
				const options = createInitOptions(props.gameConfig);
				options.game = props.gameConfig?.GameSystemName || "";
				options.callback = (success) => {
					setAuthState(success);
				};

				handleAuth(options);
			}}
		>
			<FaVk size={props.large ? 40 : 20} color="white" />
		</SocialLoginButton>
	);
}

function FacebookLoginButton(props: SocialLoginButtonProps) {
	const authOptions = useAuthOptions();

	return (
		<SocialLoginButton
			{...props}
			color="#4267B2"
			handleAuth={() =>
				authOptions.get("facebook")?.handleAuth({
					type: "Firebase",
					marketingEnabled: props.marketingEnabled,
					game: props.gameConfig?.GameSystemName || ""
				})
			}
		>
			<FaFacebookF size={props.large ? 40 : 20} color="white" />
		</SocialLoginButton>
	);
}

function SocialLoginButton(props: SocialLoginButtonPropsInternal) {
	const {
		handleAuth,
		disabled,
		children,
		color,
		hoverColor,
		onAuthStarted,
		onAuthSettled: onAuthCompleted,
		onAuthSuccessful,
		large
	} = props;
	const handleClick = async () => {
		onAuthStarted?.();
		try {
			await handleAuth?.();
			onAuthSuccessful?.();
		} catch (error) {
			log.error("Error in authentication!");

			log.error(error);
		}
		onAuthCompleted?.();
	};
	return (
		<UnstyledButton
			onClick={handleClick}
			disabled={disabled || handleAuth === undefined}
			sx={(theme) => {
				const bgColor = color ?? theme.white;
				return {
					borderRadius: "50%",
					backgroundColor: bgColor,
					padding: large ? 16 : 8,
					boxShadow: "rgb(0 0 0 / 25%) 0px 2px 2px",
					transition: `${theme.transitionTimingFunction} 100ms box-shadow`,
					position: "relative",
					lineHeight: 0,
					"&:active": theme.activeStyles,
					"&:hover": { backgroundColor: hoverColor ?? theme.fn.darken(bgColor, 0.05) },
					"&:disabled": {
						cursor: "not-allowed",
						boxShadow: "none",
						"&:hover": { backgroundColor: bgColor }
					}
				};
			}}
		>
			{disabled && <Overlay radius="xl" />}
			{children}
		</UnstyledButton>
	);
}
