/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptions,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptions,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptionsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptionsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptionsToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptions';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptions;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptions(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptionsToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookInstantGamesOptions);
    }

    return {};
}

