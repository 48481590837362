/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptionsToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesOptions);
    }

    return {};
}

