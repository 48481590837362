/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainEntitiesClientErrorCode } from './FxIdDomainEntitiesClientErrorCode';
import {
    FxIdDomainEntitiesClientErrorCodeFromJSON,
    FxIdDomainEntitiesClientErrorCodeFromJSONTyped,
    FxIdDomainEntitiesClientErrorCodeToJSON,
} from './FxIdDomainEntitiesClientErrorCode';

/**
 * 
 * @export
 * @interface FxIdDomainEntitiesClientError
 */
export interface FxIdDomainEntitiesClientError {
    /**
     * 
     * @type {FxIdDomainEntitiesClientErrorCode}
     * @memberof FxIdDomainEntitiesClientError
     */
    ErrorCode: FxIdDomainEntitiesClientErrorCode;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesClientError
     */
    GenericMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesClientError
     */
    L10NKey?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FxIdDomainEntitiesClientError
     */
    L10NParam?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesClientError
     */
    RequestId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesClientError
     */
    AccountId?: number | null;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesClientError interface.
 */
export function instanceOfFxIdDomainEntitiesClientError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ErrorCode" in value;

    return isInstance;
}

export function FxIdDomainEntitiesClientErrorFromJSON(json: any): FxIdDomainEntitiesClientError {
    return FxIdDomainEntitiesClientErrorFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesClientErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesClientError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ErrorCode': FxIdDomainEntitiesClientErrorCodeFromJSON(json['ErrorCode']),
        'GenericMessage': !exists(json, 'GenericMessage') ? undefined : json['GenericMessage'],
        'L10NKey': !exists(json, 'L10NKey') ? undefined : json['L10NKey'],
        'L10NParam': !exists(json, 'L10NParam') ? undefined : json['L10NParam'],
        'RequestId': !exists(json, 'RequestId') ? undefined : json['RequestId'],
        'AccountId': !exists(json, 'AccountId') ? undefined : json['AccountId'],
    };
}

export function FxIdDomainEntitiesClientErrorToJSON(value?: FxIdDomainEntitiesClientError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ErrorCode': FxIdDomainEntitiesClientErrorCodeToJSON(value.ErrorCode),
        'GenericMessage': value.GenericMessage,
        'L10NKey': value.L10NKey,
        'L10NParam': value.L10NParam,
        'RequestId': value.RequestId,
        'AccountId': value.AccountId,
    };
}

