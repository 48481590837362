/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuMerchantData);
    }

    return {};
}

