/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentials,
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentials,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentialsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentialsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentialsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentials';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials = FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentials;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentialsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentials(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentialsToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdEmailCredentials);
    }

    return {};
}

