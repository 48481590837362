/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials,
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocialsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherSocials);
    }

    return {};
}

