/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
 */
export interface FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    FirstName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    LastName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    NickName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    PhotoUri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData
     */
    Email?: string | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "FirstName" in value;
    isInstance = isInstance && "LastName" in value;
    isInstance = isInstance && "NickName" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    return FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'FirstName': json['FirstName'],
        'LastName': json['LastName'],
        'NickName': json['NickName'],
        'PhotoUri': !exists(json, 'PhotoUri') ? undefined : json['PhotoUri'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserDataToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromVkontakteComOpenApiRequestVkSessionUserData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'FirstName': value.FirstName,
        'LastName': value.LastName,
        'NickName': value.NickName,
        'PhotoUri': value.PhotoUri,
        'Email': value.Email,
    };
}

