import { PaymentElement, LinkAuthenticationElement, Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, CopyButton, MantineProvider, Stack, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import { closeModal, ContextModalProps, openContextModal } from "@mantine/modals";
import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { getSiteDeploymentStatus } from "../../Utils/Functions";
import { useParams } from "react-router-dom";
import { useElementSize } from "@mantine/hooks";
import { Copy } from "../../Icons";
import { MdCheck } from "react-icons/md";
import { getI18n } from "react-i18next";

// ВНИМАНИЕ: мы не хотим грузить страйп пока не надо.
// https://github.com/stripe/stripe-js/issues/43
// Возможно если импортнуть только типы то будет все ок - но проверьте не появится ли загрузка страйпа на старте страницы
// import { Stripe, StripeError } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

type ModalInnerProps = {
	paymentSecret: string;
	publishableKey: string;
	returnUrl: string;
	email?: string | null | undefined;
	onCompleted: () => void;
	onError: (message: string) => void;
};

export const StripeCheckoutForm = (props: ContextModalProps<ModalInnerProps>) => {
	const [stripePromise, setStripePromise] = useState<Promise<any | null>>(Promise.resolve(null));

	useEffect(() => {
		log.info("Loading stripe");
		setStripePromise(loadStripe(props.innerProps.publishableKey));
	}, []);

	const [clientSecret, setClientSecret] = useState("");

	useEffect(() => {
		setClientSecret(props.innerProps.paymentSecret);
	}, []);

	return (
		<div style={{ backgroundColor: "white", padding: "1em" }}>
			<h1>Payment</h1>
			{clientSecret && stripePromise && (
				<Elements
					stripe={stripePromise}
					options={{
						clientSecret: clientSecret,
						appearance: {
							theme: "flat"
						},
						loader: "always"
					}}
				>
					<StripeCheckoutFormInner {...props.innerProps} />
				</Elements>
			)}
		</div>
	);
};

const StripeCheckoutFormInner = (props: ModalInnerProps) => {
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState<string | null | undefined>(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleError = (error: any) => {
		setIsLoading(false);
		setMessage(error.message);
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		// Trigger form validation and wallet collection
		const { error: submitError } = await elements.submit();
		if (submitError) {
			log.info("Submit error: %o", submitError);
			handleError(submitError);
			return;
		}

		const { paymentIntent, error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: props.returnUrl
			},
			redirect: "if_required"
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		// if (error != null) {
		// 	if (error.type === "card_error" || error.type === "validation_error") {
		// 		setMessage(error.message);
		// 	} else {
		// 		setMessage("An unexpected error occured. " + error);
		// 	}
		// }

		if (error != null) {
			handleError(error);
			return;
		}

		log.info("All done! Payment intent: %o", paymentIntent);

		props.onCompleted();
		closeModal("stripeCheckoutModal");

		setIsLoading(false);
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<LinkAuthenticationElement
				id="link-authentication-element"
				// Access the email value like so:
				// onChange={(event) => {
				//  setEmail(event.value.email);
				// }}
				//
				// Prefill the email field like so:
				options={{ defaultValues: { email: props.email ?? "" } }}
			/>
			<PaymentElement id="payment-element" />
			<button disabled={isLoading || !stripe || !elements} id="submit">
				<span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}</span>
			</button>
			{/* Show any error or success messages */}
			{message && (
				<div id="payment-message" style={{ color: "black" }}>
					{message}
				</div>
			)}
		</form>
	);
};

export const openStripeCheckoutModal = (params: ModalInnerProps & { onClose?: () => void }) => {
	const { onClose, ...innerProps } = params;

	openContextModal({
		modal: "stripeCheckoutModal",
		onClose,
		innerProps: innerProps
		// title: String(getI18n().t("stripe_checkout_modal.title"))
	});
};
