/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateWithExternalIdState } from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import {
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateWithExternalIdState';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens';
import type { FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions } from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions';
import {
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsFromJSON,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateWithExternalIdState}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    State: FxIdApplicationHandlersAuthAuthenticateWithExternalIdState;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    Tokens?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokens | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    Hint: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    Email?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse
     */
    TransferOptions?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptions | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "State" in value;
    isInstance = isInstance && "Hint" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateFromJSON(json['State']),
        'Tokens': !exists(json, 'Tokens') ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensFromJSON(json['Tokens']),
        'Hint': json['Hint'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
        'TransferOptions': !exists(json, 'TransferOptions') ? undefined : FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsFromJSON(json['TransferOptions']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseToJSON(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'State': FxIdApplicationHandlersAuthAuthenticateWithExternalIdStateToJSON(value.State),
        'Tokens': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTokensToJSON(value.Tokens),
        'Hint': value.Hint,
        'Email': value.Email,
        'TransferOptions': FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdResponseTransferOptionsToJSON(value.TransferOptions),
    };
}

