/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MassTransitCommonSupportServiceMessageData
 */
export interface MassTransitCommonSupportServiceMessageData {
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    DeviceUid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Body?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Attachment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MassTransitCommonSupportServiceMessageData
     */
    Email?: string | null;
}

/**
 * Check if a given object implements the MassTransitCommonSupportServiceMessageData interface.
 */
export function instanceOfMassTransitCommonSupportServiceMessageData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MassTransitCommonSupportServiceMessageDataFromJSON(json: any): MassTransitCommonSupportServiceMessageData {
    return MassTransitCommonSupportServiceMessageDataFromJSONTyped(json, false);
}

export function MassTransitCommonSupportServiceMessageDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MassTransitCommonSupportServiceMessageData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Name': !exists(json, 'Name') ? undefined : json['Name'],
        'DeviceUid': !exists(json, 'DeviceUid') ? undefined : json['DeviceUid'],
        'Body': !exists(json, 'Body') ? undefined : json['Body'],
        'Subject': !exists(json, 'Subject') ? undefined : json['Subject'],
        'Attachment': !exists(json, 'Attachment') ? undefined : json['Attachment'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
    };
}

export function MassTransitCommonSupportServiceMessageDataToJSON(value?: MassTransitCommonSupportServiceMessageData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Name': value.Name,
        'DeviceUid': value.DeviceUid,
        'Body': value.Body,
        'Subject': value.Subject,
        'Attachment': value.Attachment,
        'Email': value.Email,
    };
}

