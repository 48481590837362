/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsMyGamesPublicAuthOptions,
    instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions,
    FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSON,
    FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped,
    FxIdDomainSettingsMyGamesPublicAuthOptionsToJSON,
} from './FxIdDomainSettingsMyGamesPublicAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames = FxIdDomainSettingsMyGamesPublicAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsMyGamesPublicAuthOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGamesToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigMyGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsMyGamesPublicAuthOptions(value)) {
        return FxIdDomainSettingsMyGamesPublicAuthOptionsToJSON(value as FxIdDomainSettingsMyGamesPublicAuthOptions);
    }

    return {};
}

