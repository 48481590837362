import "./index.css";

import React, { PropsWithChildren, ReactPropTypes, Suspense } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";

const Layout = (props: PropsWithChildren<unknown>) => {
	return (
		<Container className="main-container">
			<Row className="justify-content-md-center">
				<Suspense fallback={<div>Loading...</div>}>
					<Container className="ManageConnection">
						<Row>
							<Col>
								<Header />
							</Col>
						</Row>
						<Row className="flex-grow-1">
							<Container>
								<Outlet />
							</Container>
						</Row>
						<Row>
							<Col>
								<Footer />
							</Col>
						</Row>
					</Container>
				</Suspense>
			</Row>
		</Container>
	);
};

export default Layout;
