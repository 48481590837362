/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo';
import type { FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier } from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier';
import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    RefreshToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    EmailCredentials?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentials | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    ExternalSocialIdentifier?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifier | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo}
     * @memberof FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest
     */
    ExternalInfo?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfo | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
        'RefreshToken': !exists(json, 'RefreshToken') ? undefined : json['RefreshToken'],
        'SessionId': !exists(json, 'SessionId') ? undefined : json['SessionId'],
        'EmailCredentials': !exists(json, 'EmailCredentials') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsFromJSON(json['EmailCredentials']),
        'ExternalSocialIdentifier': !exists(json, 'ExternalSocialIdentifier') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierFromJSON(json['ExternalSocialIdentifier']),
        'ExternalInfo': !exists(json, 'ExternalInfo') ? undefined : FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoFromJSON(json['ExternalInfo']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Locale': value.Locale,
        'RefreshToken': value.RefreshToken,
        'SessionId': value.SessionId,
        'EmailCredentials': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestEmailCredentialsToJSON(value.EmailCredentials),
        'ExternalSocialIdentifier': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalSocialIdentifierToJSON(value.ExternalSocialIdentifier),
        'ExternalInfo': FxIdWebFeaturesAuthAuthenticateWithExternalIdRequestExternalInfoToJSON(value.ExternalInfo),
    };
}

