import { appStore } from "../../Stores";

export function getSiteDeploymentStatus(routeParams?: Record<string, string | undefined>) {
	const isIframe = window.location !== window.parent.location;
	const socialAdapterParam = routeParams?.adapter;
	const gameParam = routeParams?.game;
	const socialAdapterInQueryString = new URLSearchParams(window.location.search).get("social");

	return { isIframe, social: socialAdapterParam ?? socialAdapterInQueryString ?? null, gameParam };
}
