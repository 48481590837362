/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData,
    instanceOfFxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSON,
} from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData';

/**
 * @type FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData
 * 
 * @export
 */
export type FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData = FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData;

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataFromJSON(json: any): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData {
    return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataFromJSONTyped(json, true) };
}

export function FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseDataToJSON(value?: FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData(value)) {
        return FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseDataToJSON(value as FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerResponseData);
    }

    return {};
}

