/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId,
    instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdToJSON,
} from './FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId';

/**
 * @type FxIdWebFeaturesPlayPlatformDataOptionsFxId
 * 
 * @export
 */
export type FxIdWebFeaturesPlayPlatformDataOptionsFxId = FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId;

export function FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsFxId {
    return FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsFxIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsFxId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsFxIdToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsFxId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId(value)) {
        return FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxIdToJSON(value as FxIdWebFeaturesPlayPlatformDataOptionsPlatformDataOptionsFxId);
    }

    return {};
}

