/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsUnityOptions,
    instanceOfFxIdDomainSettingsUnityOptions,
    FxIdDomainSettingsUnityOptionsFromJSON,
    FxIdDomainSettingsUnityOptionsFromJSONTyped,
    FxIdDomainSettingsUnityOptionsToJSON,
} from './FxIdDomainSettingsUnityOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsUnity
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsUnity = FxIdDomainSettingsUnityOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsUnity {
    return FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsUnityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsUnity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsUnityOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsUnityToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsUnity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsUnityOptions(value)) {
        return FxIdDomainSettingsUnityOptionsToJSON(value as FxIdDomainSettingsUnityOptions);
    }

    return {};
}

