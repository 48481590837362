/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationStoreProductDataDiscountData } from './FxIdApplicationStoreProductDataDiscountData';
import {
    FxIdApplicationStoreProductDataDiscountDataFromJSON,
    FxIdApplicationStoreProductDataDiscountDataFromJSONTyped,
    FxIdApplicationStoreProductDataDiscountDataToJSON,
} from './FxIdApplicationStoreProductDataDiscountData';
import type { FxIdApplicationStoreProductDataLabels } from './FxIdApplicationStoreProductDataLabels';
import {
    FxIdApplicationStoreProductDataLabelsFromJSON,
    FxIdApplicationStoreProductDataLabelsFromJSONTyped,
    FxIdApplicationStoreProductDataLabelsToJSON,
} from './FxIdApplicationStoreProductDataLabels';
import type { FxIdDomainStoreEnumsStoreProductType } from './FxIdDomainStoreEnumsStoreProductType';
import {
    FxIdDomainStoreEnumsStoreProductTypeFromJSON,
    FxIdDomainStoreEnumsStoreProductTypeFromJSONTyped,
    FxIdDomainStoreEnumsStoreProductTypeToJSON,
} from './FxIdDomainStoreEnumsStoreProductType';

/**
 * 
 * @export
 * @interface FxIdApplicationStoreOrderProductData
 */
export interface FxIdApplicationStoreOrderProductData {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Sku: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ProductName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ProductDescription: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    LocalizedProductName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    LocalizedProductDescription: string;
    /**
     * 
     * @type {FxIdDomainStoreEnumsStoreProductType}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ProductType: FxIdDomainStoreEnumsStoreProductType;
    /**
     * 
     * @type {FxIdApplicationStoreProductDataDiscountData}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    DiscountData?: FxIdApplicationStoreProductDataDiscountData | null;
    /**
     * 
     * @type {FxIdApplicationStoreProductDataLabels}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Labels?: FxIdApplicationStoreProductDataLabels | null;
    /**
     * 
     * @type {any}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Payload?: any | null;
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Quantity: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    IconUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    ShowInWebStore: boolean;
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Price?: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Currency?: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    Game?: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreOrderProductData
     */
    GameId?: string;
}

/**
 * Check if a given object implements the FxIdApplicationStoreOrderProductData interface.
 */
export function instanceOfFxIdApplicationStoreOrderProductData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Sku" in value;
    isInstance = isInstance && "ProductName" in value;
    isInstance = isInstance && "ProductDescription" in value;
    isInstance = isInstance && "LocalizedProductName" in value;
    isInstance = isInstance && "LocalizedProductDescription" in value;
    isInstance = isInstance && "ProductType" in value;
    isInstance = isInstance && "Quantity" in value;
    isInstance = isInstance && "ImageUrl" in value;
    isInstance = isInstance && "IconUrl" in value;
    isInstance = isInstance && "ShowInWebStore" in value;

    return isInstance;
}

export function FxIdApplicationStoreOrderProductDataFromJSON(json: any): FxIdApplicationStoreOrderProductData {
    return FxIdApplicationStoreOrderProductDataFromJSONTyped(json, false);
}

export function FxIdApplicationStoreOrderProductDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreOrderProductData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Sku': json['Sku'],
        'ProductName': json['ProductName'],
        'ProductDescription': json['ProductDescription'],
        'LocalizedProductName': json['LocalizedProductName'],
        'LocalizedProductDescription': json['LocalizedProductDescription'],
        'ProductType': FxIdDomainStoreEnumsStoreProductTypeFromJSON(json['ProductType']),
        'DiscountData': !exists(json, 'DiscountData') ? undefined : FxIdApplicationStoreProductDataDiscountDataFromJSON(json['DiscountData']),
        'Labels': !exists(json, 'Labels') ? undefined : FxIdApplicationStoreProductDataLabelsFromJSON(json['Labels']),
        'Payload': !exists(json, 'Payload') ? undefined : json['Payload'],
        'Quantity': json['Quantity'],
        'ImageUrl': json['ImageUrl'],
        'IconUrl': json['IconUrl'],
        'ShowInWebStore': json['ShowInWebStore'],
        'Price': !exists(json, 'Price') ? undefined : json['Price'],
        'Currency': !exists(json, 'Currency') ? undefined : json['Currency'],
        'Game': !exists(json, 'Game') ? undefined : json['Game'],
        'GameId': !exists(json, 'GameId') ? undefined : json['GameId'],
    };
}

export function FxIdApplicationStoreOrderProductDataToJSON(value?: FxIdApplicationStoreOrderProductData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Sku': value.Sku,
        'ProductName': value.ProductName,
        'ProductDescription': value.ProductDescription,
        'LocalizedProductName': value.LocalizedProductName,
        'LocalizedProductDescription': value.LocalizedProductDescription,
        'ProductType': FxIdDomainStoreEnumsStoreProductTypeToJSON(value.ProductType),
        'DiscountData': FxIdApplicationStoreProductDataDiscountDataToJSON(value.DiscountData),
        'Labels': FxIdApplicationStoreProductDataLabelsToJSON(value.Labels),
        'Payload': value.Payload,
        'Quantity': value.Quantity,
        'ImageUrl': value.ImageUrl,
        'IconUrl': value.IconUrl,
        'ShowInWebStore': value.ShowInWebStore,
        'Price': value.Price,
        'Currency': value.Currency,
        'Game': value.Game,
        'GameId': value.GameId,
    };
}

