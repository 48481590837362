// Взято из https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-os/use-os.ts

export type OS = "undetermined" | "macos" | "ios" | "windows" | "android" | "linux";

export function getOS(): OS {
	const { userAgent } = window.navigator;
	const macosPlatforms = /(Macintosh)|(MacIntel)|(MacPPC)|(Mac68K)/i;
	const windowsPlatforms = /(Win32)|(Win64)|(Windows)|(WinCE)/i;
	const iosPlatforms = /(iPhone)|(iPad)|(iPod)/i;

	if (macosPlatforms.test(userAgent)) {
		return "macos";
	}
	if (iosPlatforms.test(userAgent)) {
		return "ios";
	}
	if (windowsPlatforms.test(userAgent)) {
		return "windows";
	}
	if (/Android/i.test(userAgent)) {
		return "android";
	}
	if (/Linux/i.test(userAgent)) {
		return "linux";
	}

	return "undetermined";
}
