/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthTwitchConnectRequest
 */
export interface FxIdWebFeaturesAuthTwitchConnectRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthTwitchConnectRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthTwitchConnectRequest
     */
    Code: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthTwitchConnectRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthTwitchConnectRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Game" in value;
    isInstance = isInstance && "Code" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthTwitchConnectRequestFromJSON(json: any): FxIdWebFeaturesAuthTwitchConnectRequest {
    return FxIdWebFeaturesAuthTwitchConnectRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthTwitchConnectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthTwitchConnectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'Code': json['Code'],
    };
}

export function FxIdWebFeaturesAuthTwitchConnectRequestToJSON(value?: FxIdWebFeaturesAuthTwitchConnectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Game': value.Game,
        'Code': value.Code,
    };
}

