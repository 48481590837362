/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesPlayPublicDataBase } from './FxIdWebFeaturesPlayPublicDataBase';
import {
    FxIdWebFeaturesPlayPublicDataBaseFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseToJSON,
} from './FxIdWebFeaturesPlayPublicDataBase';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesListWithConfigsResponse
 */
export interface FxIdWebFeaturesGamesListWithConfigsResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesPlayPublicDataBase>}
     * @memberof FxIdWebFeaturesGamesListWithConfigsResponse
     */
    AvailableGames: Array<FxIdWebFeaturesPlayPublicDataBase>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesListWithConfigsResponse interface.
 */
export function instanceOfFxIdWebFeaturesGamesListWithConfigsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AvailableGames" in value;

    return isInstance;
}

export function FxIdWebFeaturesGamesListWithConfigsResponseFromJSON(json: any): FxIdWebFeaturesGamesListWithConfigsResponse {
    return FxIdWebFeaturesGamesListWithConfigsResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesListWithConfigsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesListWithConfigsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AvailableGames': ((json['AvailableGames'] as Array<any>).map(FxIdWebFeaturesPlayPublicDataBaseFromJSON)),
    };
}

export function FxIdWebFeaturesGamesListWithConfigsResponseToJSON(value?: FxIdWebFeaturesGamesListWithConfigsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AvailableGames': ((value.AvailableGames as Array<any>).map(FxIdWebFeaturesPlayPublicDataBaseToJSON)),
    };
}

