import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryClient } from "../../App";
import { authTokenExists } from "../Auth";
import { FxIdWebFeaturesProfileGetResponse } from "../gen";
import { getProfileData } from "../Profile/Profile";

export const useProfileDataQuery = (options?: Omit<UseQueryOptions<FxIdWebFeaturesProfileGetResponse>, "queryKey">) => {
	const queryOptions: Omit<UseQueryOptions<FxIdWebFeaturesProfileGetResponse>, "queryKey"> = {
		enabled: authTokenExists(),
		...options
	};
	return useQuery(["profileData"], getProfileData, queryOptions);
};

export const getQueriedProfileData = () => {
	return queryClient.ensureQueryData({ queryKey: ["profileData"], queryFn: getProfileData });
};

export const revalidateProfileDataQuery = () => queryClient.invalidateQueries({ queryKey: ["profileData"] });
