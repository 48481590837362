/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsFacebookPublicAuthOptions,
    instanceOfFxIdDomainSettingsFacebookPublicAuthOptions,
    FxIdDomainSettingsFacebookPublicAuthOptionsFromJSON,
    FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped,
    FxIdDomainSettingsFacebookPublicAuthOptionsToJSON,
} from './FxIdDomainSettingsFacebookPublicAuthOptions';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook = FxIdDomainSettingsFacebookPublicAuthOptions;

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsFacebookPublicAuthOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsFacebookPublicAuthOptions(value)) {
        return FxIdDomainSettingsFacebookPublicAuthOptionsToJSON(value as FxIdDomainSettingsFacebookPublicAuthOptions);
    }

    return {};
}

