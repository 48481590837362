/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetResponseConnectedGame
 */
export interface FxIdWebFeaturesProfileGetResponseConnectedGame {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    GameJwt: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    GameUid: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesProfileGetResponseConnectedGame
     */
    StoreEnabled: boolean;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetResponseConnectedGame interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetResponseConnectedGame(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Game" in value;
    isInstance = isInstance && "GameJwt" in value;
    isInstance = isInstance && "GameUid" in value;
    isInstance = isInstance && "StoreEnabled" in value;

    return isInstance;
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameFromJSON(json: any): FxIdWebFeaturesProfileGetResponseConnectedGame {
    return FxIdWebFeaturesProfileGetResponseConnectedGameFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetResponseConnectedGame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'GameJwt': json['GameJwt'],
        'GameUid': json['GameUid'],
        'StoreEnabled': json['StoreEnabled'],
    };
}

export function FxIdWebFeaturesProfileGetResponseConnectedGameToJSON(value?: FxIdWebFeaturesProfileGetResponseConnectedGame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Game': value.Game,
        'GameJwt': value.GameJwt,
        'GameUid': value.GameUid,
        'StoreEnabled': value.StoreEnabled,
    };
}

