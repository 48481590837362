import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { FxIdDomainModelsDBDBInviteInviteStatusErrorCode } from "../../Api/gen";
import { Accordion, Code, Container, Paper, Stack, Text } from "@mantine/core";

export const INVITE_ERROR_QUERY_PARAM = "inviteErrorCode";

type TErrorLocationState = {
	status: number;
	body: string;
};
const isErrorLocationState = (value: unknown): value is TErrorLocationState => {
	if (typeof value !== "object" || value === null || value === undefined) return false;
	if (!("status" in value) || !("body" in value)) return false;
	return (
		typeof (value as TErrorLocationState).status === "number" &&
		typeof (value as TErrorLocationState).body === "string"
	);
};

const formatErrorBody = (body: string) => {
	try {
		return JSON.stringify(JSON.parse(body), null, 4);
	} catch (_error) {
		return body;
	}
};

const getErrorTranslationKey = (inviteErrorKey: number | undefined) => {
	switch (inviteErrorKey) {
		case FxIdDomainModelsDBDBInviteInviteStatusErrorCode.FailedGameOfThisTypeAlreadyConnectedToYourAccount:
			return "errors.already_connected";

		case FxIdDomainModelsDBDBInviteInviteStatusErrorCode.FailedThisGameAccountAlreadyConnectedToAnotherAccount:
			return "errors.connected_to_another_account";

		case FxIdDomainModelsDBDBInviteInviteStatusErrorCode.FailedGameOfThisTypeNotConnectedToYourAccount:
			return "errors.game_not_connected";

		default:
			return "error";
	}
};

const Error = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const location = useLocation();

	const inviteErrorKeyParam = searchParams.get(INVITE_ERROR_QUERY_PARAM);
	const inviteErrorKey = inviteErrorKeyParam ? parseInt(inviteErrorKeyParam) : undefined;

	const errorTranslationKey = getErrorTranslationKey(inviteErrorKey);

	return (
		<Container size="sm">
			<Paper p="xl">
				<Stack spacing="xl">
					<Text fz="xl" dangerouslySetInnerHTML={{ __html: t(errorTranslationKey) }} />
					{isErrorLocationState(location.state) && (
						<Accordion variant="filled" radius="md">
							<Accordion.Item value="error" sx={(theme) => ({ backgroundColor: theme.colors.dark[6] })}>
								<Accordion.Control>
									{t("errorShowDetails", { status: location.state.status })}
								</Accordion.Control>
								<Accordion.Panel>
									<Code block>{formatErrorBody(location.state.body)}</Code>
								</Accordion.Panel>
							</Accordion.Item>
						</Accordion>
					)}
				</Stack>
			</Paper>
		</Container>
	);
};

export default Error;
