/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData } from './FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData';
import {
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataFromJSON,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataFromJSONTyped,
    FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataToJSON,
} from './FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData';
import type { FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError } from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError';
import {
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSON,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSONTyped,
    FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSON,
} from './FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError';

/**
 * 
 * @export
 * @interface FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
 */
export interface FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
     */
    Success: boolean;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError}
     * @memberof FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
     */
    Error?: FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseError | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData}
     * @memberof FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse
     */
    Data?: FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseData | null;
}

/**
 * Check if a given object implements the FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse interface.
 */
export function instanceOfFxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Success" in value;

    return isInstance;
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSON(json: any): FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    return FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSONTyped(json, false);
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Success': json['Success'],
        'Error': !exists(json, 'Error') ? undefined : FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorFromJSON(json['Error']),
        'Data': !exists(json, 'Data') ? undefined : FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataFromJSON(json['Data']),
    };
}

export function FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseToJSON(value?: FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Success': value.Success,
        'Error': FxIdApplicationHandlersAuthRefreshTwitchAccountTokenHandlerRefreshTwitchAccountTokenHandlerResponseErrorToJSON(value.Error),
        'Data': FxIdApplicationHandlersAuthConnectTwitchAccountHandlerConnectTwitchAccountHandlerResponseDataToJSON(value.Data),
    };
}

