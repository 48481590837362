/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesFriendsGetFriendsResponseFriendData } from './FxIdWebFeaturesFriendsGetFriendsResponseFriendData';
import {
    FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSON,
    FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSONTyped,
    FxIdWebFeaturesFriendsGetFriendsResponseFriendDataToJSON,
} from './FxIdWebFeaturesFriendsGetFriendsResponseFriendData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesFriendsGetFriendsResponse
 */
export interface FxIdWebFeaturesFriendsGetFriendsResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesFriendsGetFriendsResponseFriendData>}
     * @memberof FxIdWebFeaturesFriendsGetFriendsResponse
     */
    Friends: Array<FxIdWebFeaturesFriendsGetFriendsResponseFriendData>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFriendsGetFriendsResponse interface.
 */
export function instanceOfFxIdWebFeaturesFriendsGetFriendsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Friends" in value;

    return isInstance;
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFromJSON(json: any): FxIdWebFeaturesFriendsGetFriendsResponse {
    return FxIdWebFeaturesFriendsGetFriendsResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFriendsGetFriendsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFriendsGetFriendsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Friends': ((json['Friends'] as Array<any>).map(FxIdWebFeaturesFriendsGetFriendsResponseFriendDataFromJSON)),
    };
}

export function FxIdWebFeaturesFriendsGetFriendsResponseToJSON(value?: FxIdWebFeaturesFriendsGetFriendsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Friends': ((value.Friends as Array<any>).map(FxIdWebFeaturesFriendsGetFriendsResponseFriendDataToJSON)),
    };
}

