/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData } from './FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData';
import {
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSON,
} from './FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData';
import type { FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData } from './FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData';
import {
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSON,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSON,
} from './FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromGameResponse
 */
export interface FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameResponse
     */
    IsConnected: boolean;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameResponse
     */
    ConnectedData?: FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedData | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromGameResponse
     */
    NotConnectedData?: FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedData | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromGameResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromGameResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "IsConnected" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    return FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromGameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IsConnected': json['IsConnected'],
        'ConnectedData': !exists(json, 'ConnectedData') ? undefined : FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataFromJSON(json['ConnectedData']),
        'NotConnectedData': !exists(json, 'NotConnectedData') ? undefined : FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataFromJSON(json['NotConnectedData']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromGameResponseToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromGameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IsConnected': value.IsConnected,
        'ConnectedData': FxIdWebFeaturesAuthAuthenticateFromGameResponseConnectedDataToJSON(value.ConnectedData),
        'NotConnectedData': FxIdWebFeaturesAuthAuthenticateFromGameResponseNotConnectedDataToJSON(value.NotConnectedData),
    };
}

