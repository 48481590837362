/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthUserInfoResponse
 */
export interface FxIdWebFeaturesAuthOauthUserInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthUserInfoResponse
     */
    Sub: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthUserInfoResponse
     */
    Id: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthUserInfoResponse
     */
    Email: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthUserInfoResponse interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthUserInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Sub" in value;
    isInstance = isInstance && "Id" in value;
    isInstance = isInstance && "Email" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthOauthUserInfoResponseFromJSON(json: any): FxIdWebFeaturesAuthOauthUserInfoResponse {
    return FxIdWebFeaturesAuthOauthUserInfoResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthUserInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthUserInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Sub': json['sub'],
        'Id': json['id'],
        'Email': json['email'],
    };
}

export function FxIdWebFeaturesAuthOauthUserInfoResponseToJSON(value?: FxIdWebFeaturesAuthOauthUserInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sub': value.Sub,
        'id': value.Id,
        'email': value.Email,
    };
}

