/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainEntitiesAwGameDataViewModel
 */
export interface FxIdDomainEntitiesAwGameDataViewModel {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainEntitiesAwGameDataViewModel
     */
    PlayerName: string;
    /**
     * 
     * @type {Date}
     * @memberof FxIdDomainEntitiesAwGameDataViewModel
     */
    LastLogin: Date;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesAwGameDataViewModel
     */
    TanksCount: number;
    /**
     * 
     * @type {number}
     * @memberof FxIdDomainEntitiesAwGameDataViewModel
     */
    SocialAccounts: number;
}

/**
 * Check if a given object implements the FxIdDomainEntitiesAwGameDataViewModel interface.
 */
export function instanceOfFxIdDomainEntitiesAwGameDataViewModel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "PlayerName" in value;
    isInstance = isInstance && "LastLogin" in value;
    isInstance = isInstance && "TanksCount" in value;
    isInstance = isInstance && "SocialAccounts" in value;

    return isInstance;
}

export function FxIdDomainEntitiesAwGameDataViewModelFromJSON(json: any): FxIdDomainEntitiesAwGameDataViewModel {
    return FxIdDomainEntitiesAwGameDataViewModelFromJSONTyped(json, false);
}

export function FxIdDomainEntitiesAwGameDataViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainEntitiesAwGameDataViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'PlayerName': json['PlayerName'],
        'LastLogin': (new Date(json['LastLogin'])),
        'TanksCount': json['TanksCount'],
        'SocialAccounts': json['SocialAccounts'],
    };
}

export function FxIdDomainEntitiesAwGameDataViewModelToJSON(value?: FxIdDomainEntitiesAwGameDataViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'PlayerName': value.PlayerName,
        'LastLogin': (value.LastLogin.toISOString()),
        'TanksCount': value.TanksCount,
        'SocialAccounts': value.SocialAccounts,
    };
}

