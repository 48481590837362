/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsAdvertisementYandexRewardedOptions,
    instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions,
    FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSON,
    FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementYandexRewardedOptions';

/**
 * @type FxIdDomainSettingsAdvertisementYandexOptionsRewarded
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementYandexOptionsRewarded = FxIdDomainSettingsAdvertisementYandexRewardedOptions;

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSON(json: any): FxIdDomainSettingsAdvertisementYandexOptionsRewarded {
    return FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementYandexOptionsRewarded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsAdvertisementYandexRewardedOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsAdvertisementYandexOptionsRewardedToJSON(value?: FxIdDomainSettingsAdvertisementYandexOptionsRewarded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementYandexRewardedOptions(value)) {
        return FxIdDomainSettingsAdvertisementYandexRewardedOptionsToJSON(value as FxIdDomainSettingsAdvertisementYandexRewardedOptions);
    }

    return {};
}

