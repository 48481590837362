/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements,
    instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements = FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements;

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements(value)) {
        return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSON(value as FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements);
    }

    return {};
}

