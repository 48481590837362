/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens,
    instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdTokens,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSON,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSONTyped,
    FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSON,
} from './FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens';

/**
 * @type FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens
 * 
 * @export
 */
export type FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens = FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens;

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens {
    return FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokensToJSON(value?: FxIdWebFeaturesAuthAuthenticateWithExternalIdResponseTokens | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesAuthAuthenticateWithExternalIdTokens(value)) {
        return FxIdWebFeaturesAuthAuthenticateWithExternalIdTokensToJSON(value as FxIdWebFeaturesAuthAuthenticateWithExternalIdTokens);
    }

    return {};
}

