import { MantineThemeOverride } from "@mantine/core";

export const FxGamesTheme: MantineThemeOverride = {
	colorScheme: "dark",
	colors: {
		dark: [
			"#ffffff",
			"#C1C2C5",
			"#8A9198",
			"#A2BCDD",
			"#7EA1CE",
			"#315685",
			"#274369",
			"#1C314C",
			"#13243A",
			"#020C19"
		],
		blue: [
			"#F0F8FE",
			"#E1F1FC",
			"#B4DCF9",
			"#87C7F5",
			"#3CA4EE",
			"#0088E9",
			"#0060A4",
			"#00487B",
			"#003052",
			"#002037"
		]
	},
	primaryShade: 5
};
