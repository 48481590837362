/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    OperatingSystem?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    Processor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    Ram?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements
     */
    VideoCard?: string | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'OperatingSystem': !exists(json, 'OperatingSystem') ? undefined : json['OperatingSystem'],
        'Processor': !exists(json, 'Processor') ? undefined : json['Processor'],
        'Ram': !exists(json, 'Ram') ? undefined : json['Ram'],
        'VideoCard': !exists(json, 'VideoCard') ? undefined : json['VideoCard'],
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirementsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherGameRequirements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'OperatingSystem': value.OperatingSystem,
        'Processor': value.Processor,
        'Ram': value.Ram,
        'VideoCard': value.VideoCard,
    };
}

