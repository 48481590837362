/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayGameIframeGameConfigOptions,
    instanceOfFxIdWebFeaturesPlayGameIframeGameConfigOptions,
    FxIdWebFeaturesPlayGameIframeGameConfigOptionsFromJSON,
    FxIdWebFeaturesPlayGameIframeGameConfigOptionsFromJSONTyped,
    FxIdWebFeaturesPlayGameIframeGameConfigOptionsToJSON,
} from './FxIdWebFeaturesPlayGameIframeGameConfigOptions';

/**
 * @type FxIdWebFeaturesPlayGameResponseAllOfIframeGame
 * 
 * @export
 */
export type FxIdWebFeaturesPlayGameResponseAllOfIframeGame = FxIdWebFeaturesPlayGameIframeGameConfigOptions;

export function FxIdWebFeaturesPlayGameResponseAllOfIframeGameFromJSON(json: any): FxIdWebFeaturesPlayGameResponseAllOfIframeGame {
    return FxIdWebFeaturesPlayGameResponseAllOfIframeGameFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseAllOfIframeGameFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponseAllOfIframeGame {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayGameIframeGameConfigOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayGameResponseAllOfIframeGameToJSON(value?: FxIdWebFeaturesPlayGameResponseAllOfIframeGame | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayGameIframeGameConfigOptions(value)) {
        return FxIdWebFeaturesPlayGameIframeGameConfigOptionsToJSON(value as FxIdWebFeaturesPlayGameIframeGameConfigOptions);
    }

    return {};
}

