/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranch } from './FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranch';
import {
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranchFromJSON,
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranchFromJSONTyped,
    FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranchToJSON,
} from './FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranch';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse
 */
export interface FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse {
    /**
     * 
     * @type {Array<FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranch>}
     * @memberof FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse
     */
    Branches: Array<FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranch>;
}

/**
 * Check if a given object implements the FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse interface.
 */
export function instanceOfFxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Branches" in value;

    return isInstance;
}

export function FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSON(json: any): FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse {
    return FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Branches': ((json['Branches'] as Array<any>).map(FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranchFromJSON)),
    };
}

export function FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponseToJSON(value?: FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Branches': ((value.Branches as Array<any>).map(FxIdWebFeaturesFtlIntegrationLauncherAvailableBranchesBranchToJSON)),
    };
}

