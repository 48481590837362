/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions,
    instanceOfFxIdDomainSettingsAdvertisementFbInstantInterstitialOptions,
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsFromJSON,
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsFromJSONTyped,
    FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsToJSON,
} from './FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions';

/**
 * @type FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial
 * 
 * @export
 */
export type FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial = FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions;

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSON(json: any): FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial {
    return FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSONTyped(json, false);
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitialToJSON(value?: FxIdDomainSettingsAdvertisementFbInstantOptionsInterstitial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsAdvertisementFbInstantInterstitialOptions(value)) {
        return FxIdDomainSettingsAdvertisementFbInstantInterstitialOptionsToJSON(value as FxIdDomainSettingsAdvertisementFbInstantInterstitialOptions);
    }

    return {};
}

