/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate } from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Maintenance?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenance | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    MinimalRequirements?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    RecommendedRequirements?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirements | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Oauth?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauth | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    BugReport?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReport | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Update?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdate | null;
    /**
     * 
     * @type {Array<FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Servers?: Array<FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServer> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    DevelopmentMode?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    UrlScheme?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    LaunchParams?: string | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Links?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinks | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions
     */
    Socials?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocials | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    return FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Maintenance': !exists(json, 'Maintenance') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceFromJSON(json['Maintenance']),
        'MinimalRequirements': !exists(json, 'MinimalRequirements') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON(json['MinimalRequirements']),
        'RecommendedRequirements': !exists(json, 'RecommendedRequirements') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsFromJSON(json['RecommendedRequirements']),
        'Oauth': !exists(json, 'Oauth') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthFromJSON(json['Oauth']),
        'BugReport': !exists(json, 'BugReport') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportFromJSON(json['BugReport']),
        'Update': !exists(json, 'Update') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateFromJSON(json['Update']),
        'Servers': !exists(json, 'Servers') ? undefined : (json['Servers'] === null ? null : (json['Servers'] as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerFromJSON)),
        'DevelopmentMode': !exists(json, 'DevelopmentMode') ? undefined : json['DevelopmentMode'],
        'UrlScheme': !exists(json, 'UrlScheme') ? undefined : json['UrlScheme'],
        'LaunchParams': !exists(json, 'LaunchParams') ? undefined : json['LaunchParams'],
        'Links': !exists(json, 'Links') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksFromJSON(json['Links']),
        'Socials': !exists(json, 'Socials') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsFromJSON(json['Socials']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Maintenance': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMaintenanceToJSON(value.Maintenance),
        'MinimalRequirements': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON(value.MinimalRequirements),
        'RecommendedRequirements': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsMinimalRequirementsToJSON(value.RecommendedRequirements),
        'Oauth': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsOauthToJSON(value.Oauth),
        'BugReport': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsBugReportToJSON(value.BugReport),
        'Update': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsUpdateToJSON(value.Update),
        'Servers': value.Servers === undefined ? undefined : (value.Servers === null ? null : (value.Servers as Array<any>).map(FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLauncherServerToJSON)),
        'DevelopmentMode': value.DevelopmentMode,
        'UrlScheme': value.UrlScheme,
        'LaunchParams': value.LaunchParams,
        'Links': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsLinksToJSON(value.Links),
        'Socials': FxIdDomainSettingsPublicWebClientConfigOptionsLauncherOptionsSocialsToJSON(value.Socials),
    };
}

