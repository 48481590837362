/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantDataFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaMerchantData);
    }

    return {};
}

