/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdWebFeaturesPlayGameUnityGameConfigOptions,
    instanceOfFxIdWebFeaturesPlayGameUnityGameConfigOptions,
    FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSON,
    FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSONTyped,
    FxIdWebFeaturesPlayGameUnityGameConfigOptionsToJSON,
} from './FxIdWebFeaturesPlayGameUnityGameConfigOptions';

/**
 * @type FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig
 * 
 * @export
 */
export type FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig = FxIdWebFeaturesPlayGameUnityGameConfigOptions;

export function FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigFromJSON(json: any): FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig {
    return FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdWebFeaturesPlayGameUnityGameConfigOptionsFromJSONTyped(json, true) };
}

export function FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfigToJSON(value?: FxIdWebFeaturesPlayGameResponseAllOfUnityGameConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdWebFeaturesPlayGameUnityGameConfigOptions(value)) {
        return FxIdWebFeaturesPlayGameUnityGameConfigOptionsToJSON(value as FxIdWebFeaturesPlayGameUnityGameConfigOptions);
    }

    return {};
}

