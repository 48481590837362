/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest
     */
    InviteToken: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "InviteToken" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest {
    return FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'InviteToken': json['InviteToken'],
    };
}

export function FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateFromEmailInviteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'InviteToken': value.InviteToken,
    };
}

