/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesGamesChooseGameRequest
 */
export interface FxIdWebFeaturesGamesChooseGameRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesGamesChooseGameRequest
     */
    GameSystemName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesGamesChooseGameRequest
     */
    OriginUrl: string;
}

/**
 * Check if a given object implements the FxIdWebFeaturesGamesChooseGameRequest interface.
 */
export function instanceOfFxIdWebFeaturesGamesChooseGameRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "OriginUrl" in value;

    return isInstance;
}

export function FxIdWebFeaturesGamesChooseGameRequestFromJSON(json: any): FxIdWebFeaturesGamesChooseGameRequest {
    return FxIdWebFeaturesGamesChooseGameRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesGamesChooseGameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesGamesChooseGameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'GameSystemName': !exists(json, 'GameSystemName') ? undefined : json['GameSystemName'],
        'OriginUrl': json['OriginUrl'],
    };
}

export function FxIdWebFeaturesGamesChooseGameRequestToJSON(value?: FxIdWebFeaturesGamesChooseGameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'GameSystemName': value.GameSystemName,
        'OriginUrl': value.OriginUrl,
    };
}

