/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData,
    instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData';

/**
 * @type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames
 * 
 * @export
 */
export type FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames = FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData;

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataFromJSONTyped(json, true) };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData(value)) {
        return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantDataToJSON(value as FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesMerchantData);
    }

    return {};
}

