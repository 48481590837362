/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPresentationOptionsMinRatio } from './FxIdDomainSettingsPresentationOptionsMinRatio';
import {
    FxIdDomainSettingsPresentationOptionsMinRatioFromJSON,
    FxIdDomainSettingsPresentationOptionsMinRatioFromJSONTyped,
    FxIdDomainSettingsPresentationOptionsMinRatioToJSON,
} from './FxIdDomainSettingsPresentationOptionsMinRatio';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPresentationOptions
 */
export interface FxIdDomainSettingsPresentationOptions {
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMinRatio}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MinRatio?: FxIdDomainSettingsPresentationOptionsMinRatio | null;
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMinRatio}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MaxRatio?: FxIdDomainSettingsPresentationOptionsMinRatio | null;
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMinRatio}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MinSize?: FxIdDomainSettingsPresentationOptionsMinRatio | null;
    /**
     * 
     * @type {FxIdDomainSettingsPresentationOptionsMinRatio}
     * @memberof FxIdDomainSettingsPresentationOptions
     */
    MaxSize?: FxIdDomainSettingsPresentationOptionsMinRatio | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPresentationOptions interface.
 */
export function instanceOfFxIdDomainSettingsPresentationOptions(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdDomainSettingsPresentationOptionsFromJSON(json: any): FxIdDomainSettingsPresentationOptions {
    return FxIdDomainSettingsPresentationOptionsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPresentationOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPresentationOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'MinRatio': !exists(json, 'MinRatio') ? undefined : FxIdDomainSettingsPresentationOptionsMinRatioFromJSON(json['MinRatio']),
        'MaxRatio': !exists(json, 'MaxRatio') ? undefined : FxIdDomainSettingsPresentationOptionsMinRatioFromJSON(json['MaxRatio']),
        'MinSize': !exists(json, 'MinSize') ? undefined : FxIdDomainSettingsPresentationOptionsMinRatioFromJSON(json['MinSize']),
        'MaxSize': !exists(json, 'MaxSize') ? undefined : FxIdDomainSettingsPresentationOptionsMinRatioFromJSON(json['MaxSize']),
    };
}

export function FxIdDomainSettingsPresentationOptionsToJSON(value?: FxIdDomainSettingsPresentationOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'MinRatio': FxIdDomainSettingsPresentationOptionsMinRatioToJSON(value.MinRatio),
        'MaxRatio': FxIdDomainSettingsPresentationOptionsMinRatioToJSON(value.MaxRatio),
        'MinSize': FxIdDomainSettingsPresentationOptionsMinRatioToJSON(value.MinSize),
        'MaxSize': FxIdDomainSettingsPresentationOptionsMinRatioToJSON(value.MaxSize),
    };
}

