/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState } from './FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState';
import {
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSON,
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSONTyped,
    FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSON,
} from './FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState';
import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData';
import {
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataToJSON,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData';
import type { FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData } from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData';
import {
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSON,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSONTyped,
    FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSON,
} from './FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
 */
export interface FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    Token: string;
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    AccountId: number;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    Email?: string | null;
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    MyGamesTokenData: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenData;
    /**
     * 
     * @type {FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    GameData?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameData | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState}
     * @memberof FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData
     */
    TransferState: FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferState;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData interface.
 */
export function instanceOfFxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Token" in value;
    isInstance = isInstance && "AccountId" in value;
    isInstance = isInstance && "MyGamesTokenData" in value;
    isInstance = isInstance && "TransferState" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSON(json: any): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    return FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Token': json['Token'],
        'AccountId': json['AccountId'],
        'Email': !exists(json, 'Email') ? undefined : json['Email'],
        'MyGamesTokenData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataFromJSON(json['MyGamesTokenData']),
        'GameData': !exists(json, 'GameData') ? undefined : FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataFromJSON(json['GameData']),
        'TransferState': FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateFromJSON(json['TransferState']),
    };
}

export function FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataToJSON(value?: FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Token': value.Token,
        'AccountId': value.AccountId,
        'Email': value.Email,
        'MyGamesTokenData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthMyGamesTokenDataToJSON(value.MyGamesTokenData),
        'GameData': FxIdWebFeaturesAuthOauthInfoAuthenticateWithExternalIdMyGamesOauthDataGameDataToJSON(value.GameData),
        'TransferState': FxIdApplicationHandlersAuthInfoAuthenticateWithExternalIdMyGamesOauthTransferStateToJSON(value.TransferState),
    };
}

