/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    FxIdDomainSettingsFxEventsClientPublicConfig,
    instanceOfFxIdDomainSettingsFxEventsClientPublicConfig,
    FxIdDomainSettingsFxEventsClientPublicConfigFromJSON,
    FxIdDomainSettingsFxEventsClientPublicConfigFromJSONTyped,
    FxIdDomainSettingsFxEventsClientPublicConfigToJSON,
} from './FxIdDomainSettingsFxEventsClientPublicConfig';

/**
 * @type FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents
 * 
 * @export
 */
export type FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents = FxIdDomainSettingsFxEventsClientPublicConfig;

export function FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents {
    return FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FxIdDomainSettingsFxEventsClientPublicConfigFromJSONTyped(json, true) };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsFxEventsToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsFxEvents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFxIdDomainSettingsFxEventsClientPublicConfig(value)) {
        return FxIdDomainSettingsFxEventsClientPublicConfigToJSON(value as FxIdDomainSettingsFxEventsClientPublicConfig);
    }

    return {};
}

