/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions
 */
export interface FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    /**
     * 
     * @type {number}
     * @memberof FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions
     */
    SteamId: number;
}

/**
 * Check if a given object implements the FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions interface.
 */
export function instanceOfFxIdWebFeaturesPlayPlatformDataOptionsSteamOptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "SteamId" in value;

    return isInstance;
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsFromJSON(json: any): FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    return FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'SteamId': json['SteamId'],
    };
}

export function FxIdWebFeaturesPlayPlatformDataOptionsSteamOptionsToJSON(value?: FxIdWebFeaturesPlayPlatformDataOptionsSteamOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'SteamId': value.SteamId,
    };
}

