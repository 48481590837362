/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SystemNetMailMailAddress
 */
export interface SystemNetMailMailAddress {
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    DisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    User: string;
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    Host: string;
    /**
     * 
     * @type {string}
     * @memberof SystemNetMailMailAddress
     */
    Address: string;
}

/**
 * Check if a given object implements the SystemNetMailMailAddress interface.
 */
export function instanceOfSystemNetMailMailAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "DisplayName" in value;
    isInstance = isInstance && "User" in value;
    isInstance = isInstance && "Host" in value;
    isInstance = isInstance && "Address" in value;

    return isInstance;
}

export function SystemNetMailMailAddressFromJSON(json: any): SystemNetMailMailAddress {
    return SystemNetMailMailAddressFromJSONTyped(json, false);
}

export function SystemNetMailMailAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemNetMailMailAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'DisplayName': json['DisplayName'],
        'User': json['User'],
        'Host': json['Host'],
        'Address': json['Address'],
    };
}

export function SystemNetMailMailAddressToJSON(value?: SystemNetMailMailAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'DisplayName': value.DisplayName,
        'User': value.User,
        'Host': value.Host,
        'Address': value.Address,
    };
}

