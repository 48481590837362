/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdWebFeaturesProfileGetTwitchGameData } from './FxIdWebFeaturesProfileGetTwitchGameData';
import {
    FxIdWebFeaturesProfileGetTwitchGameDataFromJSON,
    FxIdWebFeaturesProfileGetTwitchGameDataFromJSONTyped,
    FxIdWebFeaturesProfileGetTwitchGameDataToJSON,
} from './FxIdWebFeaturesProfileGetTwitchGameData';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesProfileGetTwitchData
 */
export interface FxIdWebFeaturesProfileGetTwitchData {
    /**
     * 
     * @type {{ [key: string]: FxIdWebFeaturesProfileGetTwitchGameData; }}
     * @memberof FxIdWebFeaturesProfileGetTwitchData
     */
    TwitchGameData?: { [key: string]: FxIdWebFeaturesProfileGetTwitchGameData; } | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesProfileGetTwitchData interface.
 */
export function instanceOfFxIdWebFeaturesProfileGetTwitchData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FxIdWebFeaturesProfileGetTwitchDataFromJSON(json: any): FxIdWebFeaturesProfileGetTwitchData {
    return FxIdWebFeaturesProfileGetTwitchDataFromJSONTyped(json, false);
}

export function FxIdWebFeaturesProfileGetTwitchDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesProfileGetTwitchData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'TwitchGameData': !exists(json, 'TwitchGameData') ? undefined : (json['TwitchGameData'] === null ? null : mapValues(json['TwitchGameData'], FxIdWebFeaturesProfileGetTwitchGameDataFromJSON)),
    };
}

export function FxIdWebFeaturesProfileGetTwitchDataToJSON(value?: FxIdWebFeaturesProfileGetTwitchData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'TwitchGameData': value.TwitchGameData === undefined ? undefined : (value.TwitchGameData === null ? null : mapValues(value.TwitchGameData, FxIdWebFeaturesProfileGetTwitchGameDataToJSON)),
    };
}

