/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
  FxIdWebFeaturesGamesChooseGameRequest,
  FxIdWebFeaturesGamesChooseGameResponse,
  FxIdWebFeaturesPlayGameResponse,
  FxIdWebFeaturesPlayListResponse,
  FxIdWebFeaturesPlayOtaGameVersion,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
    FxIdWebFeaturesGamesChooseGameRequestFromJSON,
    FxIdWebFeaturesGamesChooseGameRequestToJSON,
    FxIdWebFeaturesGamesChooseGameResponseFromJSON,
    FxIdWebFeaturesGamesChooseGameResponseToJSON,
    FxIdWebFeaturesPlayGameResponseFromJSON,
    FxIdWebFeaturesPlayGameResponseToJSON,
    FxIdWebFeaturesPlayListResponseFromJSON,
    FxIdWebFeaturesPlayListResponseToJSON,
    FxIdWebFeaturesPlayOtaGameVersionFromJSON,
    FxIdWebFeaturesPlayOtaGameVersionToJSON,
} from '../models';

export interface FxIdWebFeaturesGamesChooseGameEndpointRequest {
    fxIdWebFeaturesGamesChooseGameRequest: FxIdWebFeaturesGamesChooseGameRequest;
}

export interface FxIdWebFeaturesPlayGameEndpointRequest {
    game: string;
    otaVersion: FxIdWebFeaturesPlayOtaGameVersion;
    buildNumber: number;
    webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
}

export interface FxIdWebFeaturesPlayListEndpointRequest {
    game: string;
    otaVersion: FxIdWebFeaturesPlayOtaGameVersion;
}

/**
 * 
 */
export class PlayApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesGamesChooseGameEndpointRaw(requestParameters: FxIdWebFeaturesGamesChooseGameEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesGamesChooseGameResponse>> {
        if (requestParameters.fxIdWebFeaturesGamesChooseGameRequest === null || requestParameters.fxIdWebFeaturesGamesChooseGameRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesGamesChooseGameRequest','Required parameter requestParameters.fxIdWebFeaturesGamesChooseGameRequest was null or undefined when calling fxIdWebFeaturesGamesChooseGameEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/play/choose_game`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesGamesChooseGameRequestToJSON(requestParameters.fxIdWebFeaturesGamesChooseGameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesGamesChooseGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesGamesChooseGameEndpoint(fxIdWebFeaturesGamesChooseGameRequest: FxIdWebFeaturesGamesChooseGameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesGamesChooseGameResponse> {
        const response = await this.fxIdWebFeaturesGamesChooseGameEndpointRaw({ fxIdWebFeaturesGamesChooseGameRequest: fxIdWebFeaturesGamesChooseGameRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesPlayGameEndpointRaw(requestParameters: FxIdWebFeaturesPlayGameEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesPlayGameResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesPlayGameEndpoint.');
        }

        if (requestParameters.otaVersion === null || requestParameters.otaVersion === undefined) {
            throw new runtime.RequiredError('otaVersion','Required parameter requestParameters.otaVersion was null or undefined when calling fxIdWebFeaturesPlayGameEndpoint.');
        }

        if (requestParameters.buildNumber === null || requestParameters.buildNumber === undefined) {
            throw new runtime.RequiredError('buildNumber','Required parameter requestParameters.buildNumber was null or undefined when calling fxIdWebFeaturesPlayGameEndpoint.');
        }

        if (requestParameters.webPublishingPlatform === null || requestParameters.webPublishingPlatform === undefined) {
            throw new runtime.RequiredError('webPublishingPlatform','Required parameter requestParameters.webPublishingPlatform was null or undefined when calling fxIdWebFeaturesPlayGameEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.otaVersion !== undefined) {
            queryParameters['otaVersion'] = requestParameters.otaVersion;
        }

        if (requestParameters.buildNumber !== undefined) {
            queryParameters['buildNumber'] = requestParameters.buildNumber;
        }

        if (requestParameters.webPublishingPlatform !== undefined) {
            queryParameters['webPublishingPlatform'] = requestParameters.webPublishingPlatform;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/play/{game}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesPlayGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesPlayGameEndpoint(game: string, otaVersion: FxIdWebFeaturesPlayOtaGameVersion, buildNumber: number, webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesPlayGameResponse> {
        const response = await this.fxIdWebFeaturesPlayGameEndpointRaw({ game: game, otaVersion: otaVersion, buildNumber: buildNumber, webPublishingPlatform: webPublishingPlatform }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesPlayListEndpointRaw(requestParameters: FxIdWebFeaturesPlayListEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesPlayListResponse>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesPlayListEndpoint.');
        }

        if (requestParameters.otaVersion === null || requestParameters.otaVersion === undefined) {
            throw new runtime.RequiredError('otaVersion','Required parameter requestParameters.otaVersion was null or undefined when calling fxIdWebFeaturesPlayListEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.otaVersion !== undefined) {
            queryParameters['otaVersion'] = requestParameters.otaVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/play/{game}/list`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesPlayListResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesPlayListEndpoint(game: string, otaVersion: FxIdWebFeaturesPlayOtaGameVersion, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesPlayListResponse> {
        const response = await this.fxIdWebFeaturesPlayListEndpointRaw({ game: game, otaVersion: otaVersion }, initOverrides);
        return await response.value();
    }

}
