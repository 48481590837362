import {
	FxIdDomainSettingsUrlOpenMethod,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesGamesChooseGameResponsePublicGameData
} from "@app/Api/gen";

export const DEFAULT_GAME_CONFIG: FxIdWebFeaturesGamesChooseGameResponsePublicGameData = {
	PlatformData: {},
	GameOrientation: 0,
	MobileGameOrientation: 1,
	SupportedLanguages: [],
	Store: {
		XsollaEnabled: false,
		YookassaEnabled: false,
		StripeEnabled: false
	},
	CompanyName: "BronepuhGames",
	TosUrl: "",
	TosUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod.Unspecified,
	PpUrl: "",
	PpUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod.Unspecified,
	PublicWebClientConfig: {
		CardImageUrl: "",
		SmallLogoUrl: "",
		IconUrl: "",
		PromoEnabled: false
	},
	GameSystemName: "",
	NamespacedProductsL10N: false
};
export const DEFAULT_PORTAL_CONFIG: FxIdWebFeaturesGamesChooseGamePortalConfig = {
	PortalPublicWebConfig: {
		CompanyBranding: {
			CompanyName: "",
			BrandName: "",
			Logos: {
				CompanyLogo: "",
				BrandLogo: "",
				WebPlayerLogo: ""
			},
			HeaderElementType: "",
			Auth: {
				AllowedAuthProviders: []
			}
		}
	}
};
