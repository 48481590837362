/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
 */
export interface FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
     */
    Url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
     */
    Sha256?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent
     */
    MagnetUrl?: string | null;
}

/**
 * Check if a given object implements the FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent interface.
 */
export function instanceOfFtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSON(json: any): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    return FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped(json, false);
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentFromJSONTyped(json: any, ignoreDiscriminator: boolean): FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Url': !exists(json, 'url') ? undefined : json['url'],
        'Sha256': !exists(json, 'sha256') ? undefined : json['sha256'],
        'MagnetUrl': !exists(json, 'magnetUrl') ? undefined : json['magnetUrl'],
    };
}

export function FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrentToJSON(value?: FtlSharedGeneratedApiFtlApplicationHandlersGetLauncherAppManifestResultTorrent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.Url,
        'sha256': value.Sha256,
        'magnetUrl': value.MagnetUrl,
    };
}

